import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  BeneficiaryApiService,
  CompanyData,
} from '../../../../core/http/beneficiary-api.service';
import { finalize, map, pluck } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-receipt-settings',
  templateUrl: './receipt-settings.component.html',
  styleUrls: ['./receipt-settings.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class ReceiptSettingsComponent implements OnInit {
  public switchValue = 'beneficiary';

  public beneficiaryAddressForm = new FormGroup({
    country: new FormControl('', Validators.required),
    district: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
  });

  public companyForm = new FormGroup({
    companyName: new FormControl('', Validators.required),
    uniqueRegistrationCode: new FormControl('', [Validators.required]),
    vatIdentificationNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    country: new FormControl('', Validators.required),
    district: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    streetNumber: new FormControl('', Validators.required),
  });

  private beneficiaryAddressId: string;

  constructor(
    private beneficiaryApiService: BeneficiaryApiService,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.companyForm.disable({ emitEvent: false });
    this.beneficiaryAddressForm.disable({ emitEvent: false });
    this.fetchBeneficiaryData();
    this.fetchBeneficiaryCompanyData();
  }

  // person
  private fetchBeneficiaryData() {
    this.beneficiaryApiService
      .getAddress()
      .pipe(map((response) => response.addresses[0]))
      .subscribe((response) => {
        this.beneficiaryAddressForm.patchValue(response, { emitEvent: false });

        this.beneficiaryAddressId = response.id;
        this.beneficiaryAddressForm.enable({ emitEvent: false });
      });
  }

  public handleBeneficiaryFormSubmit() {
    const payload = {
      ...this.beneficiaryAddressForm.value,
      id: this.beneficiaryAddressId || null,
    };

    this.beneficiaryAddressForm.disable({ emitEvent: false });

    this.beneficiaryApiService
      .addAddress(payload)
      .pipe(
        finalize(() => {
          this.beneficiaryAddressForm.enable({ emitEvent: false });
        })
      )
      .subscribe(
        () => this.presentToast('Modificarile au fost salvate.', 'success'),
        () =>
          this.presentToast('Modificarile nu au putut fi salvate.', 'warning')
      );
  }

  // company
  private fetchBeneficiaryCompanyData() {
    this.beneficiaryApiService
      .getFinancialDetails()
      // .pipe(pluck('financialDetails', 'address'))
      .pipe(
        map((response) => ({
          ...response.address,
          ...response.financialDetails,
        }))
      )
      .subscribe(
        (response) => {
          this.companyForm.patchValue(response);
          this.companyForm.enable({ emitEvent: false });
        },
        (err) => {
          this.companyForm.enable({ emitEvent: false });
        }
      );
  }

  public handleCompanyFormSubmit() {
    const reqBody = this.companyForm.getRawValue() as CompanyData;
    this.companyForm.disable({ emitEvent: false });

    this.beneficiaryApiService
      .updateFinancialDetails(reqBody)
      .pipe(
        finalize(() => {
          this.companyForm.enable({ emitEvent: false });
        })
      )
      .subscribe(
        () => this.presentToast('Modificarile au fost salvate.', 'success'),
        () =>
          this.presentToast('Modificarile nu au putut fi salvate.', 'warning')
      );
  }

  // utils
  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      color,
      message,
      position: 'bottom',
      duration: 1500,
    });
    await toast.present();
  }
}
