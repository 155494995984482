import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ListRatingsComponent } from './list-ratings.component';

@Injectable({
  providedIn: 'root',
})
export class ListRatingsService {

  private modal: HTMLIonModalElement;

  constructor(
      public modalController: ModalController,
  ) {}

  async presentModal(userId: string, element = null) {
    this.modal = await this.modalController.create({
      component: ListRatingsComponent,
      presentingElement: element ? element : null,
      componentProps: {
        userId,
      },
    });
    return await this.modal.present();
  }

  async closeModal() {
    if (this.modal) {
      this.modal.dismiss().then(() => { this.modal = null; });
    }
  }
}
