import { Action, createReducer, on } from '@ngrx/store';

import * as fromAuthActions from '../actions/auth.actions';

import { User } from '../../core/models/user';

export interface ErrorResponse {
  status: number;
  message: string;
}

export interface LoginState {
  email: string;
  userExists: boolean;
  error: ErrorResponse;
}

export interface RegisterState {
  userExists: boolean;
  success: boolean;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  error: ErrorResponse;
}

export interface VerifiedState {
  error: ErrorResponse;
}

export interface ResetPasswordState {
  error: ErrorResponse;
}

export interface AuthState {
  currentUser: User;
  token?: string;
  login: LoginState;
  register: RegisterState;
  verified: VerifiedState;
  loaded: boolean;
  loading: boolean;
  resetPassword: ResetPasswordState;
}

export const initialState: AuthState = {
  currentUser: {
    email: '',
    phoneNumber: '',
    accountType: null,
  },
  token: null,
  login: {
    email: null,
    userExists: null,
    error: {
      status: null,
      message: null,
    },
  },
  register: {
    success: false,
    userExists: false,
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    error: {
      status: null,
      message: null,
    },
  },
  verified: {
    error: {
      status: null,
      message: null,
    },
  },
  resetPassword: {
    error: {
      status: null,
      message: null,
    },
  },
  loaded: false,
  loading: false,
};

const authReducer = createReducer<AuthState>(
  initialState,
  on(fromAuthActions.loginUser, (state: AuthState, payload) => ({
    ...state,
    loading: true,
  })),
  on(fromAuthActions.loginSuccess, (state: AuthState, payload) => ({
    ...state,
    token: payload.authToken,
    currentUser: {
      ...state.currentUser,
      phoneNumber: payload.credentials.phoneNumber,
      accountType: payload.credentials.accountType,
    },
    loading: false,
  })),
  on(fromAuthActions.loginFailed, (state: AuthState, err: any) => ({
    ...state,
    login: {
      ...state.login,
      error: { status: err.status, message: err.error },
    },
    loading: false,
  })),
  on(fromAuthActions.registerUser, (state: AuthState, { user }) => ({
      ...state,
      register: {
        ...state.register,
        phoneNumber: user.phoneNumber,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
      currentUser: {
        ...state.currentUser,
        accountType: user.accountType,
      },
  })),
  on(fromAuthActions.registerUserSuccess, (state: AuthState) => ({
    ...state,
    register: {
      ...state.register,
      success: true,
    },
  })),
  on(fromAuthActions.registerUserFailed, (state: AuthState, err: any) => ({
      ...state,
      register: {
        ...state.register,
        error: { status: err.status, message: err.error },
      },
  })),
  on(fromAuthActions.verifyAccount, (state: AuthState, payload) => ({
    ...state,
    token: payload.authToken,
  })),
  on(fromAuthActions.verifyAccountFailed, (state: AuthState, err: any) => ({
    ...state,
    verified: {
      ...state.verified,
      error: { status: err.status, message: err.error.error },
    },
  })),
  on(fromAuthActions.getCurrentUserSuccess, (state: AuthState, payload) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      email: payload.user.email,
      firstName: payload.user.firstName,
      lastName: payload.user.lastName,
      phoneNumber: payload.user.phoneNumber,
    }
  })),
  on(fromAuthActions.logoutUserFailed, (state: AuthState, payload) => ({
    ...initialState
  })),
  on(fromAuthActions.logoutUserSuccess, (state: AuthState, payload) => {
    return {
      ...initialState
    };
  }),
);

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}
