import {Injectable} from '@angular/core';
import {
  MedicalStaffApiService,
  MedicalStaffDetails,
  MedicalStaffStatusDetails
} from '../../medical-staff-api/medical-staff-api.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicalStaffServiceFacade {

  private _status = new BehaviorSubject<MedicalStaffStatusDetails>(null);
  public status$: Observable<MedicalStaffStatusDetails> = this._status.asObservable();

  private _medicalStaffDetails = new BehaviorSubject<MedicalStaffDetails>(null);
  public medicalStaffDetails$: Observable<MedicalStaffDetails> = this._medicalStaffDetails.asObservable();

  constructor(
    private medicalStaffApiService: MedicalStaffApiService
  ) {
  }

  public dispatchLoadMedicalStaffStatus() {
    this.medicalStaffApiService.findStatus().subscribe(
      (status) => {
        this._status.next(status);
      }
    );
  }

  public dispatchLoadMedicalStaffDetails() {
    this.medicalStaffApiService.findDetails().subscribe(
      (details) => {
        this._medicalStaffDetails.next(details);
      }
    );
  }
}
