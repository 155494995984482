<div class="list-item"
     [class.list-item--primary]="config.variant === 'primary'"
     [class.list-item--secondary]="config.variant === 'secondary'"
     [class.list-item--tertiary]="config.variant === 'tertiary'"
     [class.list-item--success]="config.variant === 'success'"
     [class.list-item--warning]="config.variant === 'warning'"
     [class.list-item--danger]="config.variant === 'danger'"
>
  <div class="list-item-content">
    <div *ngIf="config.avatarName" class="list-item-avatar">
      <img [src]="avatarUrl" [alt]="config.avatarName" />
    </div>

    <div class="list-item-main">
      <ng-content></ng-content>
    </div>

    <div *ngIf="config.iconName" class="list-item-icon">
      <ion-icon [name]="config.iconName"></ion-icon>
    </div>
  </div>
</div>
