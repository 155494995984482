import {Action, createReducer, on} from '@ngrx/store';

import * as fromMedicalProfileActions from '../actions/medical-staff-profile.actions';
import {MedicalStaffDetails} from '../../services/medical-staff-api/medical-staff-api.service';

export interface MedicalStaffProfileState {
  profile: MedicalStaffDetails;
  fetching: boolean;
  updating: boolean;
  error: any;
}

export const initialState: MedicalStaffProfileState = {
  profile: null,
  fetching: false,
  updating: false,
  error: null
};

const patientsReducer = createReducer(
  initialState,

  // FETCH PROFILE
  on(fromMedicalProfileActions.loadMedicalStaffProfile, (state: MedicalStaffProfileState) => ({
    ...state,
    error: null,
    profile: null,
    fetching: true
  })),
  on(fromMedicalProfileActions.loadMedicalStaffProfileSuccess, (state: MedicalStaffProfileState, payload) => ({
    ...state,
    profile: payload.profile,
    fetching: false
  })),
  on(fromMedicalProfileActions.loadMedicalStaffProfileFailed, (state: MedicalStaffProfileState, payload) => ({
    ...state,
    error: payload.error,
    fetching: false
  })),

  // FETCH PROFILE
  on(fromMedicalProfileActions.updateMedicalStaffProfile, (state: MedicalStaffProfileState) => ({
    ...state,
    error: null,
    updating: true
  })),
  on(fromMedicalProfileActions.updateMedicalStaffProfileSuccess, (state: MedicalStaffProfileState, payload) => ({
    ...state,
    updating: false
  })),
  on(fromMedicalProfileActions.updateMedicalStaffProfileFailed, (state: MedicalStaffProfileState, payload) => ({
    ...state,
    error: payload.error,
    updating: false
  }))
);

export function reducer(state: MedicalStaffProfileState | undefined, action: Action): MedicalStaffProfileState {
  return patientsReducer(state, action);
}

