<div class="widget"
     [class.bg--primary]="widgetConfig.variant === 'primary'"
     [class.bg--secondary]="widgetConfig.variant === 'secondary'"
     [class.bg--tertiary]="widgetConfig.variant === 'tertiary'"
     [class.bg--warning]="widgetConfig.variant === 'warning'"
     [class.bg--danger]="widgetConfig.variant === 'danger'"
     [class.bg--success]="widgetConfig.variant === 'success'"
>
  <div class="widget-header">
    <div class="widget-title">{{widgetConfig.title}}</div>
    <a class="widget-link" [routerLink]="widgetConfig.viewAllLink">
      {{widgetConfig.viewAllLinkText}}
    </a>
  </div>

  <div class="widget-content" [class.widget-content-padding]="widgetConfig.contentHasPadding">
    <ng-content *ngIf="!loading"></ng-content>

    <ng-container *ngIf="error">
      {{error.message | json}}
    </ng-container>
  </div>

  <div *ngIf="loading" class="ion-padding ion-text-center">
    <ion-spinner></ion-spinner>
  </div>
</div>
