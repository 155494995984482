import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromReducers from '../reducers';

const getMedicalStaffProfileState = createFeatureSelector<fromReducers.MedicalStaffProfileState>('medicalStaffProfile');

export const selectMedicalStaffProfile = createSelector(
  getMedicalStaffProfileState,
  (state: fromReducers.MedicalStaffProfileState) => state.profile,
);

export const selectMedicalStaffProfileFetching = createSelector(
  getMedicalStaffProfileState,
  (state: fromReducers.MedicalStaffProfileState) => state.fetching,
);

export const selectMedicalStaffProfileUpdating = createSelector(
  getMedicalStaffProfileState,
  (state: fromReducers.MedicalStaffProfileState) => state.updating,
);

export const selectMedicalStaffProfileError = createSelector(
  getMedicalStaffProfileState,
  (state: fromReducers.MedicalStaffProfileState) => state.error,
);
