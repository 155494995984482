<ion-card class="popupCard">

  <div class="popup_messages_holder">
    <span class="popupCard_message">{{message}}</span>
  </div>
  <div>
    <ion-button class="btn popupCard_btn" (click)="closeModal()">Ok</ion-button>
  </div>
</ion-card>




