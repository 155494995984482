import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MedicalPersonelApiService } from '../http/medicalpersonel-api.service';

import { MedicalStaffServicesInterface } from '../models/medical-services.interface';
import { MedicalStaffFinancialDetailsInterface } from '../models/medical-staff-financial-details.interface';
import { MedicalStaffDocumentInterface } from '../models/medical-staff-document.interface';
import { MedicalStaffAddressInterface } from '../models/medical-address.interface';
import { MedicalStaffPersonalDetailsInterface } from '../models/medical-staff-personal-details.interface';
import {MedicalStaffService, MedicalStaffServiceGroup} from '../models/medical-staff-service';

@Injectable({ providedIn: 'root' })
export class MedicalPersonelService {
  constructor(private medicalPersonelApiService: MedicalPersonelApiService) {}

  /**
   * Add medical service for medical staff
   *
   * @param medicalStaffService Medical Staff Interface
   */
  addMedicalService(medicalStaffService: MedicalStaffServicesInterface): Observable<any> {
    return this.medicalPersonelApiService.addMedicalService(medicalStaffService);
  }

  /**
   * Remove Medical Services for Logged in Medical Staff
   *
   * @param medicalServiceId Medical service Id
   */
  deleteMedicalService(medicalServiceId: string): Observable<any> {
    return this.medicalPersonelApiService.deleteMedicalService(medicalServiceId);
  }

  /**
   * Add financial details for medical staff
   *
   * @param financialDetails Medical staff financial details interface
   */
  addFinancialDetails(financialDetails: MedicalStaffFinancialDetailsInterface)
  : Observable<MedicalStaffFinancialDetailsInterface> {
    return this.medicalPersonelApiService.addFinancialDetails(financialDetails).pipe(
      map(res => res.financialDetails),
      catchError(err => throwError(err)),
    );
  }

  /**
   * Get financial details for Medical Staff
   *
   */
  getFinancialDetails(): Observable<MedicalStaffFinancialDetailsInterface> {
    return this.medicalPersonelApiService.getFinancialDetails().pipe(
      map(res => res.financialDetails),
      catchError(err => throwError(err)),
    );
  }


  /**
   * Add Document for Medical Staff
   *
   * @param document Medical staff document
   */
  addDocument(document: MedicalStaffDocumentInterface): Observable<any> {
    return this.medicalPersonelApiService.addDocument(document);
  }

  /**
   * Get all documents for Medical Staff
   *
   */
  getAllDocuments(): Observable<any> {
    return this.medicalPersonelApiService.getAllDocuments();
  }

  /**
   * Get document for Medical Staff
   *
   */
  getDocument(uuid: string): Observable<any> {
    return this.medicalPersonelApiService.getDocument(uuid);
  }

  /**
   * Add a Medical Staff address
   *
   * @param address medical staff address
   */
  addAddress(address: MedicalStaffAddressInterface): Observable<any> {
    return this.medicalPersonelApiService.addAddress(address);
  }

  /**
   * Add / update medical staff personal details
   *
   * @param personalDetails medical staff personal details
   */
  addPersonalDetails(personalDetails: MedicalStaffPersonalDetailsInterface) {
    return this.medicalPersonelApiService.addPersonalDetails(personalDetails);
  }

  /**
   * Get medical staff personal details
   *
   */
  getPersonalDetails(): Observable<MedicalStaffService> {
    return this.medicalPersonelApiService.getPersonalDetails();
  }

  /**
   * Get Profile for Medical Staff
   *
   * @param profileID Profile ID
   */
  getProfile(profileID: string): Observable<any> {
    return this.medicalPersonelApiService.getProfile(profileID);
  }

  /**
   * Get Medical Services for Medical Staff
   *
   */
  getServices(): Observable<MedicalStaffService> {
    return this.medicalPersonelApiService.getServices();
  }

  /**
   * Get Medical Services for ANY Medical Staff
   */
  getAvailableServices(): Observable<MedicalStaffServiceGroup[]> {
    return this.medicalPersonelApiService.getAvailableServices();
  }

  getPatients(): Observable<MedicalStaffService> {
    return this.medicalPersonelApiService.getPatients();
  }

  getAppointments(): Observable<any> {
    return this.medicalPersonelApiService.getAppointments();
  }
}
