import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PopupMedicalReportComponent } from './popup-medical-report.component';

@Injectable({
  providedIn: 'root',
})
export class PopupMedicalReportService {
  private modal: HTMLIonModalElement;

  constructor(public modalController: ModalController) {}

  async presentModal(appointmentId, medicalServices: any[]) {
    this.modal = await this.modalController.create({
      component: PopupMedicalReportComponent,
      componentProps: {
        appointmentId,
        medicalServices,
      },
    });
    return await this.modal.present();
  }

  async closeModal() {
    if (this.modal) {
      this.modal.dismiss().then(() => {
        this.modal = null;
      });
    }
  }
}
