import {Injectable} from '@angular/core';

import {ofType, Actions, createEffect} from '@ngrx/effects';

import {of} from 'rxjs';
import {exhaustMap, map, mergeMap, catchError, tap, mapTo} from 'rxjs/operators';

// Services
import {PopupMessageService} from '../../_modal/popup-message/popup-message.service';
import {AuthService} from '../../core/services/auth.service';

// Actions
import * as authActions from '../actions/auth.actions';
import {Router} from '@angular/router';


@Injectable()
export class AuthEffects {
  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginUser),
      exhaustMap((data) =>
        this.authService.login(data.credentials).pipe(
          map(res => {
            return authActions.loginSuccess({authToken: res.access_token, credentials: data.credentials});
          }),
          catchError((error) => {
            this.popupMessageService.presentModal(error.error.message);
            return of(authActions.loginFailed(error));
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      tap(() => {
        this.router.navigate(['/home'], {replaceUrl: true});
      })
    ), {dispatch: false}
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.registerUser),
      exhaustMap((data) =>
        this.authService.register(data.user).pipe(
          map(() => authActions.registerUserSuccess()),
          catchError((error) => of(authActions.registerUserFailed(error)))
        )
      )
    )
  );

  verifyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyAccount),
      exhaustMap((data) =>
        this.authService
          .validateUser(
            data.phoneNumber,
            data.phonePrefix,
            data.accountType,
            data.code
          )
          .pipe(
            map(res => {
                return authActions.verifyAccount({
                  phoneNumber: data.phoneNumber,
                  phonePrefix: data.phonePrefix,
                  accountType: data.accountType,
                  code: res.code,
                  authToken: res.access_token,
                });
              }
            ),
            catchError((error) =>
              of(authActions.verifyAccountFailed(error))
            )
          )
      )
    ),
  );

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getCurrentUser),
      exhaustMap(() =>
        this.authService.userInfo().pipe(
          map(res => authActions.getCurrentUserSuccess({user: res})),
          catchError((error) => of(authActions.getCurrentUserError(error)))
        )
      )
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logoutUser),
      exhaustMap(() =>
        this.authService.logout().pipe(
          map(() => authActions.logoutUserSuccess()),
          catchError(error => of(authActions.logoutUserFailed(error)))
        )
      ),
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private popupMessageService: PopupMessageService,
    private router: Router
  ) {
  }
}
