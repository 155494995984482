<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>Trimite contract</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="formContentReady$ | async as data; else loading">
    <form [formGroup]="form" class="full-form" [@slideInOut]>
      <div class="full-form-data">
        <ion-list>
          <ion-item lines="full">
            <ion-label position="stacked">Alege pacient</ion-label>
            <ion-select formControlName="patientId" (ionChange)="onChangePatient($event)"
                        okText="Alege" cancelText="Închide">
              <ion-select-option
                *ngFor="let patient of data.patients"
                [value]="patient.id"
              >
                {{ patient.firstName }} {{ patient.lastName }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item lines="full">
            <ion-label position="stacked">Alege adresa pacientului</ion-label>
            <ion-select formControlName="addressId" placeholder="" okText="Alege" cancelText="Închide">
              <ion-select-option *ngFor="let address of (selectPatientAddresses$ | async)" [value]="address.id">
                {{ address.country | titlecase }},
                {{ address.district | titlecase }},
                {{ address.city | titlecase }} ,
                {{ address.streetNumber | titlecase }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item-group formArrayName="medicalStaffServices">
            <ng-container
              *ngFor="
                let item of form.get('medicalStaffServices')['controls'];
                let i = index
              "
            >
              <ng-container [formGroupName]="i">
                <ion-item lines="full">
                  <ion-label position="stacked">Serviciu medical </ion-label>
                  <ion-select
                    formControlName="medicalStaffServiceId"
                    placeholder=""
                  >
                    <ion-select-option
                      *ngFor="let medicalService of data.medicalServices"
                      [value]="medicalService.medicalStaffServiceId"
                    >
                      {{ medicalService.name | titlecase }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>

                <ion-item lines="full">
                  <ion-label position="stacked">Număr vizite</ion-label>
                  <ion-input
                    class="hct-ion-form-input"
                    formControlName="noOfRecurrences"
                    placeholder=""
                  >
                  </ion-input>
                </ion-item>
                <div
                  class="ion-margin ion-mb-0"
                  *ngIf="
                    form
                      .get('medicalStaffServices')
                      ['controls'][i].get('noOfRecurrences')
                      .hasError('bkErrorMessage')
                  "
                >
                  <small>
                    <ion-text color="danger">
                      {{
                        form
                          .get('medicalStaffServices')
                          ['controls'][i].get('noOfRecurrences')
                          .getError('bkErrorMessage')
                      }}
                    </ion-text>
                  </small>
                </div>
              </ng-container>
            </ng-container>
          </ion-item-group>
        </ion-list>

        <ion-button
          style="margin: 30px 20px 10px"
          color="tertiary"
          size="small"
          (click)="addItem()"
        >
          Adaugă alt serviciu medical
        </ion-button>

        <ion-item lines="none">
          <ion-checkbox
            slot="start"
            class="checkbox-check"
            formControlName="invoiceTypeOfPerson"
          ></ion-checkbox>
          <ion-label class="ion-text-wrap checkbox-text">
            Sunt persoană juridică
          </ion-label>
        </ion-item>

        <ion-item lines="none" style="margin-bottom: 20px">
          <ion-checkbox
            slot="start"
            class="checkbox-check"
            formControlName="terms"
          ></ion-checkbox>
          <ion-label class="ion-text-wrap checkbox-text"
            >Sunt de acord cu
            <a
              class="terms-link"
              routerLink="/static/beneficiary-contract-terms"
              >Termenii și Condițiile</a
            ></ion-label
          >
        </ion-item>
        <div *ngIf="form.hasError('bkError')" class="errors">
          <ng-container *ngFor="let errors of form.getError('bkError')">
            <p *ngFor="let err of errors" class="message-errors">
              {{ err }}
            </p>
          </ng-container>
        </div>

        <div *ngIf="generalError" class="errors">
          {{ generalError }}
        </div>
      </div>
      <div *ngIf="form" class="ion-margin">
        <ion-button
          class="submit-button"
          color="secondary"
          expand="block"
          [disabled]="form.invalid || form.disabled || !form.dirty"
          (click)="handleSubmit()"
        >
          <span *ngIf="!form.disabled"> Propune contract </span>
          <span *ngIf="form.disabled">
            <ion-spinner></ion-spinner>
          </span>
        </ion-button>
      </div>
    </form>
  </ng-container>
</ion-content>

<ng-template #loading>
  <div class="ion-padding">
    <ion-spinner class="center-spinner"></ion-spinner>
  </div>
</ng-template>
