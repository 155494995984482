import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AbstractApiService } from './abstract-api.service';
import { Patient } from '../models/patient';
import { PatientAddress } from '../models/patient-address';
import { AddressesApiService } from './addresses-api.service';
import { BeneficiaryAddress } from '../models/beneficiary-address';

export interface CompanyData {
  companyName: string;
  email: string;
  identifier: number;
  uniqueRegistrationCode: string;
  vatIdentificationNumber: string;
  address?: any;
}

export interface CompanyDetails {
  financialDetails: CompanyData;
  address: any;
}

@Injectable({ providedIn: 'root' })
export class BeneficiaryApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  addAddress(address: BeneficiaryAddress): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/beneficiary/address`, address);
  }

  getAddress(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/beneficiary/address`);
  }

  getFinancialDetails(): Observable<CompanyDetails> {
    return this.http.get<CompanyDetails>(
      `${this.getBaseUrl()}/beneficiary/financial-details`
    );
  }

  updateFinancialDetails(payload: CompanyData): Observable<CompanyDetails> {
    return this.http.post<CompanyDetails>(
      `${this.getBaseUrl()}/beneficiary/financial-details`,
      { ...payload }
    );
  }

  setInterviewWithMedicalStaff(
    authToken: string,
    medicalStaffID: string,
    data: any
  ): Observable<any> {
    return this.http
      .post(
        `${this.getBaseUrl()}/beneficiary/0/interviews/${medicalStaffID}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .pipe(
        map((result) => result),
        catchError((err) => throwError(err))
      );
  }

  // getInterviews(authToken: string): Observable<any>{
  //   return this.http.get(`${this.getBaseUrl()}/beneficiary/0/interviews`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   }).pipe(
  //     map(result => result),
  //     catchError(err => throwError(err)),
  //   );
  // }
}
