import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';

// Services
import {MedicalPersonelService} from '../../core/services/medicalpersonel.service';

// Actions
import * as medicalStaffActions from '../actions/medicalstaff.actions';
import {ToastService} from '../../core/services/toast.service';
import {PatientsService} from '../../core/services/patients.service';
import {PatientApiService} from '../../pages/patient/service/patient-api/patient-api.service';
import {AppointmentsService} from '../../pages/medical-staff/appointments/appointments.service';


@Injectable()
export class MedicalStaffEffects {
  getAllDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getAllMedicalStaffDocuments),
      exhaustMap(() => {
        return this.medicalStaffService.getAllDocuments().pipe(
          map((documents) => {
            return medicalStaffActions.getDocumentsSuccessful({documents});
          }),
          catchError((error) => {
            return of(medicalStaffActions.getDocumentsFailed(error));
          })
        );
      })
    )
  );

  getDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getDocument),
      exhaustMap(({uuid}) => {
        return this.medicalStaffService.getDocument(uuid).pipe(
          map(response => {
            console.log(response);
            return medicalStaffActions.getDocumentSuccessful({document: response});
          }),
          catchError((error) => {
            console.log(error);
            return of(medicalStaffActions.getDocumentFailed(error));
          })
        );
      })
    )
  );

  uploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.uploadMedicalStaffDocument),
      exhaustMap((data) => {
        return this.medicalStaffService.addDocument(data).pipe(
          map(response => {
            this.toastService.presentToast({color: 'success', message: 'Documentul a fost încărcat cu success.'});
            return medicalStaffActions.uploadMedicalStaffDocumentSuccess({file: response.documents[0], documentType: data.documentType});
          }),
          catchError((error) => {
            this.toastService.presentToast({color: 'success', message: 'Documentul nu a putut fi încărcat.'});
            return of(medicalStaffActions.uploadMedicalStaffDocumentError(error));
          })
        );
      })
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getProfile),
      exhaustMap((data: { authToken: string; profileID: string; }) => {
        return this.medicalStaffService.getProfile(data.profileID).pipe(
          map(response => {
            return medicalStaffActions.getProfileSuccessful({medicalStaffProfile: response});
          }),
          catchError((error) => {
            return of(medicalStaffActions.getProfileFailed(error));
          })
        );
      })
    )
  );


  getServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getServices),
      exhaustMap(() => this.medicalStaffService.getServices().pipe(
        map(medicalServices => medicalStaffActions.getServicesSuccess({medicalServices})),
        catchError(error => of(medicalStaffActions.getServicesFailed(error))),
        )
      ),
    )
  );

  getAvailableServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getAvailableServices),
      exhaustMap(() => this.medicalStaffService.getAvailableServices().pipe(
        map(medicalServices => medicalStaffActions.getAvailableServicesSuccess({medicalServices})),
        catchError(error => of(medicalStaffActions.getAvailableServicesFailed(error))),
        )
      ),
    )
  );


  getFinancialDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.getFinancialDetails),
      exhaustMap(() => this.medicalStaffService.getFinancialDetails().pipe(
        map(financialDetails => medicalStaffActions.getFinancialDetailsSuccess({financialDetails})),
        catchError(error => of(medicalStaffActions.getFinancialDetailsFailed(error))),
      ))
    )
  );

  addFinancialDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.addFinancialDetails),
      exhaustMap(action => this.medicalStaffService.addFinancialDetails(action.financialDetails).pipe(
        map(financialDetails => {
          this.toastService.presentToast({message: 'Datele au fost actualizate', color: 'success'});
          return medicalStaffActions.addFinancialDetailsSuccess({financialDetails});
        }),
        catchError(error => of(medicalStaffActions.addFinancialDetailsFailed(error))),
        )
      ),
    )
  );

  addService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.addService),
      exhaustMap(action => this.medicalStaffService.addMedicalService(action.service).pipe(
        map(medicalServices => {
          this.toastService.presentToast({message: 'Serviciul a fost adaugat.', color: 'success'});
          return medicalStaffActions.addServiceSuccess({medicalServices});
        }),
        catchError(error => {
          this.toastService.presentToast({message: 'Serviciul nu a putut fi adaugat.', color: 'warning'});
          return of(medicalStaffActions.addServiceFailed(error));
        })
        )
      ),
    )
  );

  deleteService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.deleteService),
      exhaustMap(action => this.medicalStaffService.deleteMedicalService(action.medicalServiceId).pipe(
        map(medicalServices => medicalStaffActions.deleteServiceSuccess({medicalServices})),
        tap(() => {
          this.toastService.presentToast({
            message: 'Serviciul a fost sters.',
            color: 'success'
          });
        }),
        catchError(error => {
          this.toastService.presentToast({message: 'Serviciul nu a putut fi sters.', color: 'warning'});
          return of(medicalStaffActions.deleteServiceFailed(error));
        }),
        )
      ),
    )
  );

  addPersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.addPersonalDetails),
      exhaustMap(action => this.medicalStaffService.addPersonalDetails(action.personalDetails).pipe(
        map(medicalStaff => medicalStaffActions.addPersonalDetailsSuccess({medicalStaff})),
        catchError(error => of(medicalStaffActions.addPersonalDetailsFailed(error))),
        )
      ),
    )
  );


  addAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.addAddress),
      exhaustMap(action => this.medicalStaffService.addAddress(action.servicesArea).pipe(
        map(servicesArea => {
          this.toastService.presentToast({message: 'Adresa a fost actualizata', color: 'success', position: 'top'});
          return medicalStaffActions.addAddressSuccess({servicesArea});
        }),
        catchError(error => of(medicalStaffActions.addAddressFailed(error))),
        )
      ),
    )
  );

  loadMedicalStaffPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.loadMedicalStaffPatients),
      exhaustMap(() => this.medicalStaffService.getPatients().pipe(
        map(medicalStaffPatients => medicalStaffActions.loadMedicalStaffPatientsSuccess({medicalStaffPatients})),
        catchError(error => of(medicalStaffActions.loadMedicalStaffPatientsFailed(error))),
        )
      ),
    )
  );

  loadMedicalStaffPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.loadMedicalStaffPatient),
      exhaustMap(({patientId}) => this.patientsService.getPatient(patientId).pipe(
        map(patient => medicalStaffActions.loadMedicalStaffPatientSuccess({patient})),
        catchError(error => of(medicalStaffActions.loadMedicalStaffPatientFailed(error))),
        )
      ),
    )
  );

  loadMedicalStaffPatientAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.loadMedicalStaffPatientAddresses),
      exhaustMap(({patientId}) => this.patientApiService.findPatientAddressesById(patientId).pipe(
        map(addresses => medicalStaffActions.loadMedicalStaffPatientAddressesSuccess({addresses})),
        catchError(error => of(medicalStaffActions.loadMedicalStaffPatientAddressesFailed(error))),
        )
      ),
    )
  );

  loadMedicalStaffAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffActions.loadMedicalStaffAppointments),
      exhaustMap(() => this.medicalStaffService.getAppointments().pipe(
        map(medicalStaffAppointments => {
          const data = this.appointmentService.normalize(medicalStaffAppointments);
          return medicalStaffActions.loadMedicalStaffAppointmentsSuccess({medicalStaffAppointments: data});
        }),
        catchError(error => of(medicalStaffActions.loadMedicalStaffAppointmentsFailed(error))),
        )
      ),
    )
  );

  constructor(
    private actions$: Actions,
    private medicalStaffService: MedicalPersonelService,
    private patientsService: PatientsService,
    private patientApiService: PatientApiService,
    private toastService: ToastService,
    private appointmentService: AppointmentsService,
  ) {
  }
}
