<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form class="full-form" [formGroup]="scheduleExceptionForm">
    <div class="full-form-data">
      <ion-list>
        <ion-item>
          <ion-label position="stacked">Data de început</ion-label>
          <ion-datetime
            formControlName="startDate"
            displayFormat="DD MMMM YYYY"
            pickerFormat="DD MMMM YYYY"
            [monthNames]="monthNames"
          >
          </ion-datetime>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Data de sfârșit</ion-label>
          <ion-datetime
            formControlName="endDate"
            displayFormat="DD MMMM YYYY"
            pickerFormat="DD MMMM YYYY"
            [monthNames]="monthNames"
          >
          </ion-datetime>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked" class="mb-1">Descriere</ion-label>
          <ion-textarea
            class="app-textarea"
            formControlName="description"
            type="textarea"
          ></ion-textarea>
        </ion-item>
      </ion-list>

      <div *ngIf="scheduleExceptionForm.hasError('bkError')" class="errors">
        <ng-container
          *ngFor="let errors of scheduleExceptionForm.getError('bkError')"
        >
          <p *ngFor="let err of errors">
            {{ err }}
          </p>
        </ng-container>
      </div>

      <div
        *ngIf="scheduleExceptionForm.hasError('bkErrorMessage')"
        class="errors"
      >
        {{ scheduleExceptionForm.getError('bkErrorMessage') }}
      </div>
    </div>

    <ion-button
      class="submit-button"
      expand="block"
      (click)="onSave()"
      color="secondary"
      [disabled]="
        scheduleExceptionForm.invalid || scheduleExceptionForm.disabled
      "
    >
      <ng-container *ngIf="!scheduleExceptionForm.disabled">
        {{ buttonLabel }}
      </ng-container>

      <ng-container *ngIf="scheduleExceptionForm.disabled">
        <ion-spinner></ion-spinner>
      </ng-container>
    </ion-button>
  </form>
</ion-content>
