import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import { beneficiaryInvoiceAdapter } from '../reducers/beneficiary-invoice.reducer';

const getBeneficiaryInvoiceFeatureState =
  createFeatureSelector<fromReducers.BeneficiaryInvoiceState>(
    'beneficiaryInvoice'
  );

export const { selectAll: selectAllBeneficiaryInvoices } =
  beneficiaryInvoiceAdapter.getSelectors(getBeneficiaryInvoiceFeatureState);

export const selectAllBeneficiaryInvoicesLoading = createSelector(
  getBeneficiaryInvoiceFeatureState,
  (state: fromReducers.BeneficiaryInvoiceState) => state.loading
);

export const selectAllBeneficiaryInvoicesError = createSelector(
  getBeneficiaryInvoiceFeatureState,
  (state: fromReducers.BeneficiaryInvoiceState) => state.error
);
