import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AbstractApiService} from './abstract-api.service';

import {MedicalStaffFinancialDetailsInterface} from '../models/medical-staff-financial-details.interface';

import {MedicalStaffServicesInterface} from '../models/medical-services.interface';
import {MedicalStaffDocumentInterface} from '../models/medical-staff-document.interface';
import {MedicalStaffAddressInterface} from '../models/medical-address.interface';
import {MedicalStaffPersonalDetailsInterface} from '../models/medical-staff-personal-details.interface';
import {MedicalStaffService, MedicalStaffServiceGroup} from '../models/medical-staff-service';
import {MedicalStaffFinancialDetailsService} from '../models/medical-staff-financial-details-service';

@Injectable({providedIn: 'root'})
export class MedicalPersonelApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Add medical service for medical staff
   *
   * @param medicalStaffService Medical Staff Interface
   */
  addMedicalService(medicalStaffService: MedicalStaffServicesInterface): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/medical-staff/medical-services`, medicalStaffService);
  }

  /**
   * Remove Medical Services for Logged in Medical Staff
   *
   * @param medicalServiceId Medical service id
   */
  deleteMedicalService(medicalServiceId: string): Observable<any> {
    return this.http.delete(`${this.getBaseUrl()}/medical-staff/medical-services`,
      {
        params: {
          medicalServiceId,
        },
      },
    );
  }

  /**
   * Add financial details for medical staff
   *
   * @param financialDetails Medical staff financial details interface
   */
  addFinancialDetails(financialDetails: MedicalStaffFinancialDetailsInterface): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/medical-staff/financial-details`, financialDetails);
  }

  /**
   * Get financial details
   *
   */
  getFinancialDetails(): Observable<MedicalStaffFinancialDetailsService> {
    return this.http.get<MedicalStaffFinancialDetailsService>(`${this.getBaseUrl()}/medical-staff/financial-details`);
  }

  /**
   * Add Document for Medical Staff
   *
   * @param document Medical staff document
   */
  addDocument(document: MedicalStaffDocumentInterface): Observable<any> {

    const formData = new FormData();
    formData.append('file', document.file);
    formData.append('documentType', document.documentType);

    return this.http.post(`${this.getBaseUrl()}/medical-staff/documents`, formData
    );
  }


  /**
   * Get all Documents for Medical Staff
   *
   */
  getAllDocuments(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/medical-staff/documents`);
  }

  /**
   * Get all Documents for Medical Staff
   *
   */
  getDocument(uuid: string): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/medical-staff/documents/${uuid}`, {responseType: 'blob'});
  }

  /**
   * Add a Medical Staff address
   *
   * @param address medical staff address
   */
  addAddress(address: MedicalStaffAddressInterface): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/medical-staff/address`,
      {
        ...address,
      });
  }

  getAddress(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/medical-staff/address`).pipe(
      map(res => res),
      catchError(err => err)
    );
  }

  /**
   * Add / update medical staff personal details
   *
   * @param personalDetails medical staff personal details
   */
  addPersonalDetails(personalDetails: MedicalStaffPersonalDetailsInterface) {
    return this.http.post(`${this.getBaseUrl()}/medical-staff/personal-details`,
      {
        ...personalDetails,
      });
  }

  /**
   * Get medical staff personal details
   *
   */
  getPersonalDetails(): Observable<MedicalStaffService> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/medical-staff/personal-details`);
  }


  /**
   * Get Medical Services for Medical Staff
   */
  getServices(): Observable<MedicalStaffService> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/medical-staff/medical-services`);
  }

  /**
   * Get Medical Services for Medical Staff
   *
   */
  getProfile(profileID: string): Observable<any> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/medical-staff/${profileID}/profile`);
  }

  /**
   * Get Medical Services for ANY Medical Staff
   */
  getAvailableServices(): Observable<MedicalStaffServiceGroup[]> {
    return this.http.get<MedicalStaffServiceGroup[]>(`${this.getBaseUrl()}/medical-services`);
  }

  getInterviews(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/medicalStaff/0/interviews`);
  }

  rateMedicalStaff(data: { toUserId: number, message: string, rating: number }): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/reviews`, data);
  }

  getRatings(userID: string): Observable<any> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/reviews/${userID}`);
  }

  getPatients(): Observable<any> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/medical-staff/contracts/patients`);
  }

  getAppointments(): Observable<any> {
    return this.http.get<MedicalStaffService>(`${this.getBaseUrl()}/appointments`);
  }

  addAppointment(
    data: { contractId: string, medicalStaffId: string, patientId: string, medicalServiceId: string, date: string, comments: string }
  ): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/appointments`, data);
  }
}
