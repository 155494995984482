import {Component, Input, OnInit} from '@angular/core';
import {ModalActionType, ScheduleFormValue} from '../../../core/models/medical-staff-schedule';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {
  ScheduleExceptionCreateErrorBody,
  ScheduleExceptionCreateReqBody
} from '../../../core/models/medical-staff-schedule-exception';
import {MedicalStaffScheduleExceptionFacadeService} from '../../../services/facade/medical-staff-schedule-exception-facade/medical-staff-schedule-exception-facade.service';
import {ToastService} from '../../../core/services/toast.service';

const MONTH_NAMES = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombrie',
  'Noiembrie',
  'Decembrie'
];

@Component({
  selector: 'app-modal-schedule-exception-edit',
  templateUrl: './modal-schedule-exception-edit.component.html',
  styleUrls: ['./modal-schedule-exception-edit.component.css']
})
export class ModalScheduleExceptionEditComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public buttonLabel: string;

  @Input()
  public initialFormValue: ScheduleFormValue;

  @Input()
  public scheduleExceptionId: number;

  @Input()
  public actionType: ModalActionType;

  public scheduleExceptionForm = new FormGroup({
    startDate: new FormControl('', []),
    endDate: new FormControl('', []),
    description: new FormControl('', [])
  });

  public monthNames = MONTH_NAMES;

  constructor(
    private modalController: ModalController,
    private scheduleExceptionFacade: MedicalStaffScheduleExceptionFacadeService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    if (this.initialFormValue) {
      this.scheduleExceptionForm.setValue(this.initialFormValue);
    }
  }

  public onSave() {
    const formValue = this.scheduleExceptionForm.getRawValue();

    const reqBody: ScheduleExceptionCreateReqBody = {
      isWorkingDay: false,
      description: formValue.description,
      endDate: formValue.endDate ? this.scheduleExceptionFacade.adaptDateToScheduleExceptionApiFormat(formValue.endDate, false) : null,
      startDate: formValue.startDate ? this.scheduleExceptionFacade.adaptDateToScheduleExceptionApiFormat(formValue.startDate) : null
    };

    this.scheduleExceptionForm.disable();

    if (this.actionType === ModalActionType.CREATE) {
      this.scheduleExceptionFacade.createScheduleException(reqBody)
        .subscribe(
          () => {
            this.toastService.presentToast({message: 'Concediul a fost adauga.', color: 'success'});
            this.modalController.dismiss(null, 'create');
          },
          err => {
            this.handleFormError(err.error);
          }
        );
    } else {
      this.scheduleExceptionFacade.updateScheduleExceptionById(this.scheduleExceptionId, reqBody)
        .subscribe(
          () => {
            this.modalController.dismiss(null, 'update');
            this.toastService.presentToast({message: 'Concediul a fost actualizat.', color: 'success'});
          },
          err => {
            this.handleFormError(err.error);
          }
        );
    }
  }

  private handleFormError(err: ScheduleExceptionCreateErrorBody | { message: string } | null) {
    this.scheduleExceptionForm.enable();

    if (err === null) {
      err = {message: 'GENERAL_ERRROR'};
    }

    if ('message' in err) {
      this.scheduleExceptionForm.setErrors({bkErrorMessage: err.message});
    } else {
      const errorKeys = Object.keys(err);
      const error = errorKeys.map(key => err[key]);
      this.scheduleExceptionForm.setErrors({bkError: error});
    }
  }

  public async close() {
    await this.modalController.dismiss(null, 'cancel');
  }
}
