import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Contract,
  ContractStatusTypes,
  StatusVariant,
} from '../../services/contracts/contracts-api.service';

@Component({
  selector: 'beneficiary-contract',
  templateUrl: './beneficiary-contract.component.html',
  styleUrls: ['./beneficiary-contract.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class BeneficiaryContractComponent implements OnInit {
  @Input()
  data: Contract[];

  @Output()
  viewContract = new EventEmitter<Contract>();

  @Output()
  rateMedicalStaff = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public onViewContract(contract: Contract) {
    this.viewContract.emit(contract);
  }

  public onRateMedicalStaff(medicalStaffId: string) {
    this.rateMedicalStaff.emit(medicalStaffId);
  }
}
