import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddCardComponent } from './add-card/add-card.component';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  public data = [
    {
      numarCard: '4481 **** **** 5028',
      dataExpirare: '07/22'
    },
    {
      numarCard: '4481 **** **** 5029',
      dataExpirare: '07/21'
    }
  ];

  constructor(
    public modalController: ModalController
  ) {}

  ngOnInit() {

  }

  removeCard(position: number) {
    this.data.splice(position, 1);
  }

  async addCard() {
    const modal = await this.modalController.create({
      component: AddCardComponent,
      cssClass: ['popup-low', 'popup-adaugaCard-height']
    });

    modal.onWillDismiss().then((item: any) => {
      if (item.data) {
        this.data.push(item.data);
      }
    });

    return await modal.present();
  }

}
