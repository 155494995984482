import { createAction, props } from '@ngrx/store';


const PATIENTS_TAG = '[ProfileStatus]';

export const loadProfileStatus = createAction(
  `${PATIENTS_TAG} Get user profile status`,
  props<{ authToken: any }>(),
);

export const loadProfileStatusComplete = createAction(
  `${PATIENTS_TAG} User profile status complete`,
  props<{ response: any }>(),
);
//  props<{ patients: Patient[] }>(),

export const loadProfileStatusIncomplete = createAction(
  `${PATIENTS_TAG} User profile status incomplete`,
  props<{ response: any }>(),
);

export const loadProfileStatusFailed = createAction(
    `${PATIENTS_TAG} Get user profile status failed`,
    props<{ error: any }>(),
  );

