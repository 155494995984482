import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../pages/contracts/services/contracts/contracts-api.service';
import {Observable} from 'rxjs';
import {WidgetConfig} from '../ui/widget/widget.component';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../store';
import {map} from 'rxjs/operators';

@Component({
  selector: 'widget-contracts',
  templateUrl: './widget-contracts.component.html',
  styleUrls: ['./widget-contracts.component.css']
})
export class WidgetContractsComponent implements OnInit {

  @Input()
  public type: string;

  public contracts$: Observable<Contract[]>;

  public widgetsConfig: WidgetConfig = {
    variant: 'tertiary',
    contentHasPadding: true,
    title: 'Contracte',
    viewAllLink: `/contracts/`,
    viewAllLinkText: 'Vezi toate'
  };

  public loading$: Observable<boolean>;

  public error$: Observable<any>;

  constructor(
    private store: Store<fromStore.State>,
  ) {
  }

  ngOnInit() {
    this.loading$ = this.store.pipe(
      select(fromStore.selectAllContractsLoading)
    );

    this.error$ = this.store.pipe(
      select(fromStore.selectAllContractsError)
    );

    this.contracts$ = this.store.pipe(
      select(fromStore.selectAllContracts),
      map(contracts => contracts ? contracts.slice(0, 3) : [])
    );

    this.store.dispatch(fromStore.loadContracts({isBeneficiary: this.type === 'beneficiary'}));

    this.widgetsConfig.viewAllLink += this.type;
  }
}
