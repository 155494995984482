<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>Back Button</ion-title>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- tabs -->
  <ion-toolbar color="light">
    <ion-tabs>
      <ion-tab-bar slot="top" color="light">
        <ion-tab-button>
          <ion-label>Schedule</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>Speakers</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>Map</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>About</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-toolbar>

  <ion-toolbar color="primary">
    <ion-tabs class="ion-tabs">
      <ion-tab-bar slot="top" color="primary">
        <ion-tab-button>
          <ion-label>Schedule</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>Speakers</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>Map</ion-label>
        </ion-tab-button>

        <ion-tab-button>
          <ion-label>About</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-toolbar>

  <!-- buttons -->
  <div class="ion-padding">
    <h4>Colors</h4>
    <div>
      <ion-button color="primary">Primary</ion-button>
      <ion-button color="secondary">Secondary</ion-button>
      <ion-button color="success">Success</ion-button>
    </div>
    <div>
      <ion-button mode="ios" color="primary">Primary</ion-button>
      <ion-button mode="ios" color="secondary">Secondary</ion-button>
      <ion-button mode="ios" color="success">Success</ion-button>
    </div>

    <div>
      <div class="bg--primary">
        <div>
          <ion-button color="light">Light</ion-button>
          <ion-button color="light" fill="outline">Light</ion-button>
        </div>
        <div>
          <ion-button mode="ios" color="light">Light</ion-button>
          <ion-button mode="ios" color="light" fill="outline">Light</ion-button>
        </div>
      </div>
    </div>

    <h4>Expand</h4>
    <div>
      <ion-button expand="block">Block Button</ion-button>
      <ion-button mode="ios" expand="block">Block Button</ion-button>
    </div>

    <h4>Fill</h4>
    <div>
      <ion-button expand="block" fill="outline">Outline + Block</ion-button>
      <ion-button mode="ios" expand="block" fill="outline">Outline + Block</ion-button>
    </div>
  </div>

  <!-- forms -->
  <form class="ion-padding">
    <h4>Forms</h4>
    <ion-list>
      <ion-item>
        <ion-label position="stacked">Text</ion-label>
        <ion-input placeholder="Placeholder"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Textarea</ion-label>
        <ion-textarea placeholder="Placeholder"></ion-textarea>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Date time</ion-label>
        <ion-datetime value="2019-10-01T15:43:40.394Z" display-timezone="utc"></ion-datetime>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Stacked Label</ion-label>
        <ion-select placeholder="Select One" okText="Alege" cancelText="Închide">
          <ion-select-option value="f">Female</ion-select-option>
          <ion-select-option value="m">Male</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </form>

  <form class="ion-padding bg--primary">
    <ion-list>
      <ion-item color="none">
        <ion-label position="stacked">Text</ion-label>
        <ion-input placeholder="Placeholder"></ion-input>
      </ion-item>

      <ion-item color="none">
        <ion-label position="stacked">Textarea</ion-label>
        <ion-textarea placeholder="Placeholder"></ion-textarea>
      </ion-item>

      <ion-item color="none">
        <ion-label position="stacked">Date time</ion-label>
        <ion-datetime value="2019-10-01T15:43:40.394Z" display-timezone="utc"></ion-datetime>
      </ion-item>

      <ion-item color="none">
        <ion-label position="stacked">Stacked Label</ion-label>
        <ion-select placeholder="Select One" okText="Alege" cancelText="Închide">
          <ion-select-option value="f">Female</ion-select-option>
          <ion-select-option value="m">Male</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer style="height: 56px;">
  <app-tabs style="z-index: 9999"></app-tabs>
</ion-footer>
