<div *ngIf="apiLoaded$ | async" class="map">
  <google-map width="100%" height="100%" [center]="center" [zoom]="zoom">
    <ng-container *ngIf="markerPosition">
      <map-marker [options]="markerOptions" [position]="markerPosition">
      </map-marker>

      <map-circle
        *ngIf="radius && markerPosition"
        [center]="markerPosition"
        [radius]="radius"
        [options]="{
          fillColor: '#0B1865',
          strokeColor: '#0B1865',
          fillOpacity: 0.2
        }"
      >
      </map-circle>
    </ng-container>
  </google-map>
</div>
