import { environment } from '../../../environments/environment';

export abstract class AbstractApiService {

  /**
   * Get the api base url from environment file
   */
  protected getBaseUrl(): string {
    return environment.apiUrl;
  }
}
