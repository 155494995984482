<app-widget [widgetConfig]="widgetsConfig" [loading]="loading$ | async" [error]="error$ | async">
  <ng-container *ngIf="(contracts$ | async) as contracts">
    <ion-list *ngIf="contracts.length" class="list-menu" lines="full">
      <ion-item *ngFor="let contract of contracts" button detail detailIcon="chevron-forward-outline"
                [routerLink]="widgetsConfig.viewAllLink">
        <ion-label>
          {{contract.patient.firstName}}
          {{contract.patient.lastName}}
        </ion-label>
      </ion-item>
    </ion-list>

    <ng-container *ngIf="!contracts.length">
      Nu am găsit contracte
    </ng-container>
  </ng-container>
</app-widget>
