<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă programare</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form
    [formGroup]="form"
    *ngIf="!loading && !appointmentAdded"
    [@slideInOut]
    class="full-form"
  >
    <div class="full-form-data">
      <ion-item>
        <ion-label position="stacked">Contract/Pacient</ion-label>
        <ion-select
          class="hct-ion-form-input"
          placeholder=""
          (ionChange)="onSelectContract($event)"
        >
          <ion-select-option
            *ngFor="let contract of contracts"
            [value]="contract"
          >
            {{ contract.patient.firstName }} {{ contract.patient.lastName }} -
            Ctr. {{ contract.contractId }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Serviciu</ion-label>
        <ion-select
          class="hct-ion-form-input"
          [disabled]="!selectedContract"
          placeholder=""
          multiple="true"
          (ionChange)="onSelectMedicalService($event)"
        >
          <ion-select-option
            *ngFor="let service of services"
            [value]="service.medicalServiceId"
          >
            {{ service.medicalServiceName }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Ziua</ion-label>
        <ion-select
          class="hct-ion-form-input"
          placeholder=""
          [disabled]="!availableDays"
          (ionChange)="onSelectDay($event)"
        >
          <ion-select-option
            *ngFor="let day of availableDays"
            [value]="day.raw"
          >
            {{ day.displayDate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Ora</ion-label>
        <ion-select
          class="hct-ion-form-input"
          placeholder=""
          [disabled]="!availableDays"
          (ionChange)="onSelectHour($event)"
        >
          <ion-select-option
            *ngFor="let hour of availableHours"
            [value]="hour.start"
          >
            {{ hour.start }} - {{ hour.end }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item lines="none">
        <ion-label position="stacked">Mențiuni</ion-label>
        <ion-textarea
          class="text-area-details app-textarea"
          rows="6"
          placeholder="Adaugă detalii..."
        >
        </ion-textarea>
      </ion-item>
      <ng-container *ngIf="form.hasError('bkError')">
        <div class="errors">
          <p *ngFor="let err of form.getError('bkError')">
            {{ err }}
          </p>
        </div>
      </ng-container>
    </div>
    <ion-button
      expand="block"
      color="secondary"
      class="submit-button"
      (click)="submitForm()"
      [disabled]="!form.valid"
    >
      <span>Adaugă</span>
    </ion-button>
  </form>

  <ion-spinner
    *ngIf="loading"
    class="center-spinner"
    [@slideInOut]
  ></ion-spinner>

  <div *ngIf="!loading && appointmentAdded" [@slideInOut]>
    <span class="success-message">
      Programarea pentru pacientul
      {{
        selectedContract.patient.firstName +
          ' ' +
          selectedContract.patient.lastName
      }}
      fost adaugata in {{ getDisplayDate() }} la ora {{ selectedHour }}</span
    >
    <ion-button expand="block" color="secondary" (click)="closeModal()">
      <span style="padding-top: 1px">Înapoi</span>
    </ion-button>
  </div>
</ion-content>
