import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-staff-schedule',
  templateUrl: './medical-staff-schedule.component.html',
  styleUrls: ['./medical-staff-schedule.component.css']
})
export class MedicalStaffScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
