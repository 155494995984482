<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>Vizite</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ng-container
    *ngIf="beneficiaryAppointments$ | async as beneficiaryAppointments"
  >
    <ion-list *ngIf="beneficiaryAppointments.length > 0">
      <ion-item
        *ngFor="let appointment of beneficiaryAppointments"
        [color]="appointment.active ? 'tertiary' : 'warning-light'"
        lines="none"
        class="ion-item"
      >
        <ion-avatar slot="start">
          <img
            src="https://eu.ui-avatars.com/api/?background=3dc2ff&font-size=0.55&bold=false&color=0B1865&format=svg&name={{
              appointment.patient.firstName + ' ' + appointment.patient.lastName
            }}"
          />
        </ion-avatar>

        <div class="item-content color--primary">
          <div class="font-20" [class.color--warning]="!appointment.active">
            {{
              isBeneficiary
                ? appointment.medicalStaff.firstName +
                  ' ' +
                  appointment.medicalStaff.lastName
                : appointment.patient.firstName +
                  ' ' +
                  appointment.patient.lastName
            }}
          </div>

          <div class="font-18 font-bold">
            {{ appointment.appointment.date }}
          </div>

          <div>
            {{ appointment.medicalServicesNames | uppercase }}
          </div>

          <div class="font-bold" [class.color--warning]="!appointment.active">
            {{ appointment.status + ' - ' }} Durată:
            {{ appointment.appointment.duration }}m
          </div>
        </div>

        <div slot="end">
          <div class="icon-slot">
            <ion-icon
              [class.bg--success]="appointment.active"
              [class.bg--warning]="!appointment.active"
              [name]="appointment.active ? 'checkmark' : 'time-outline'"
              color="light"
            ></ion-icon>
          </div>
        </div>
      </ion-item>
    </ion-list>

    <div *ngIf="!beneficiaryAppointments.length">
      Nu aveți nicio vizită stabilită
    </div>

    <ion-fab slot="fixed" vertical="bottom" horizontal="end">
      <ion-fab-button (click)="addAppointment()" color="secondary">
        <ion-icon name="add-outline" color="primary"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>

  <ng-container
    *ngIf="!(beneficiaryAppointments$ | async) && (loading$ | async)"
  >
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="error$ | async as error">
    <div class="ion-padding">
      {{ error.message }}
    </div>
  </ng-container>
</ion-content>
