import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-view-image-modal',
  templateUrl: './view-image-modal.component.html',
  styleUrls: ['./view-image-modal.component.css']
})
export class ViewImageModalComponent implements OnInit {

  public image: string | ArrayBuffer;

  public document: Blob;

  @Input()
  public file: Blob;

  constructor(private modalController: ModalController) {
  }


  ngOnInit(): void {
    if (this.file.type === 'application/pdf') {
      this.document = this.file;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onloadend = () => {
        this.image = reader.result;
      };
    }
  }

  public async dismiss() {
    await this.modalController.dismiss();
  }
}
