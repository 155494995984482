<app-widget
  [widgetConfig]="widgetConfig"
  [loading]="loading$ | async"
  [error]="error$ | async"
>
  <ng-container *ngIf="interviews$ | async as interviews">
    <ion-list *ngIf="interviews.length">
      <ion-item
        *ngFor="let interview of interviews"
        [color]="interview.callState === 'closed' ? 'warning-light' : 'light'"
        lines="none"
        class="ion-item"
      >
        <div class="item-content color--primary">
          <div
            class="font-20"
            [class.color--warning]="interview.callState === 'closed'"
          >
            {{
              (isBeneficiary$ | async)
                ? interview.beneficiaryName
                : interview.medicalStaffName
            }}
          </div>
          <div class="font-bold">{{ interview.date }}</div>
        </div>

        <div slot="end">
          <div class="icon-slot">
            <ion-icon
              [class.bg--success]="interview.callState !== 'closed'"
              [class.bg--warning]="interview.callState === 'closed'"
              [name]="
                interview.callState === 'closed'
                  ? 'time-outline'
                  : 'checkmark-outline'
              "
              color="light"
            >
            </ion-icon>
          </div>
        </div>
      </ion-item>

      <!-- <ion-item [color]="'warning-light'" lines="none" class="ion-item">
        <div class="item-content color--primary">
          <div class="small-content">
            <div class="font-bold font-day">{{ fomatedDate.dayDate }}</div>
            <div class="font-bold font-date">{{ fomatedDate.dayOfWeek }}</div>
          </div>
          <div class="large-content">
            <div class="font-20 font-lighter" [class.color--warning]="true">
              Irimia Doru Ionut
            </div>
            <div class="font-bold font-hour">Ora {{ fomatedDate.hour }}</div>
          </div>
        </div>

        <div slot="end">
          <ion-icon
            [class.bg--success]="false"
            [class.bg--warning]="true"
            [name]="'time-outline'"
            class="icon-card"
          >
          </ion-icon>
        </div>
      </ion-item> -->
    </ion-list>

    <ng-container *ngIf="!interviews.length">
      Nu am găsit interviuri
    </ng-container>
  </ng-container>
</app-widget>
