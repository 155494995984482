import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {TokenInterceptor} from './token.interceptor';
import {AuthorizeInterceptor} from './authorize.interceptor';

export const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizeInterceptor,
    multi: true
  }
];
