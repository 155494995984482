import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

export interface ToastOptions {
  message: string;
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
  position?: 'bottom' | 'middle' | 'top';
  duration?: number;
  buttons?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) {
  }

  public async presentToast(config: ToastOptions) {
    const toast = await this.toastController.create({
      duration: 1500,
      position: 'bottom',
      color: 'tertiary',
      ...config
    });
    await toast.present();
  }
}
