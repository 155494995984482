import { Component, OnDestroy } from '@angular/core';
import { ProposeInterviewComponent } from './propose-interview/propose-interview.component';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../../store';
import { ActivatedRoute } from '@angular/router';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { ListRatingsService } from './list-ratings/list-rantings.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class ViewProfileComponent implements OnDestroy {
  private data = [];

  private medicalStaffProfile$: Subscription;
  private medicalStaffProfileError$: Subscription;
  public medicalStaffResponse: any = null;
  public medicalStaffError: any = null;

  public profileID: string;

  public loading = true;

  public reloadProfile = new Subject<any>();

  private tokenSubscription$: Subscription;
  private tokenReloadSubscription$: Subscription;

  constructor(
    public modalController: ModalController,
    private store: Store<fromStore.State>,
    private route: ActivatedRoute,
    private listRatingsService: ListRatingsService,
    private routerOutlet: IonRouterOutlet
  ) {
    // read route params
    this.route.params.subscribe((params) => {
      this.profileID = params.profileID;
      // trigger get medical-staff profile
      this.tokenSubscription$ = this.store
        .pipe(select(fromStore.selectToken))
        .subscribe((authToken) => {
          if (!authToken) {
            return;
          }
          this.store.dispatch(
            fromStore.getProfile({ authToken, profileID: this.profileID })
          );
        });
    });

    // when medical staff profile is received, get notified
    this.medicalStaffProfile$ = this.store
      .pipe(select(fromStore.selectMedicalStaffProfile))
      .subscribe((content) => {
        console.log('selectMedicalStaffProfile', content);
        if (content === null) {
          return;
        }

        this.medicalStaffResponse = content;
        this.loadInteviewDates(this.medicalStaffResponse.interviewIntervals);
        this.loading = false;
      });

    // when medical staff profile receives error, get notified
    this.medicalStaffProfileError$ = this.store
      .pipe(select(fromStore.selectMedicalStaffProfileError))
      .subscribe((content) => {
        if (content === null) {
          return;
        }
        this.medicalStaffError = content;
        this.loading = false;
      });

    this.reloadProfile.subscribe(() => {
      this.loading = true;
      this.medicalStaffResponse = null;
      this.tokenReloadSubscription$ = this.store
        .pipe(select(fromStore.selectToken))
        .subscribe((authToken) => {
          if (!authToken) {
            return;
          }
          this.store.dispatch(
            fromStore.getProfile({ authToken, profileID: this.profileID })
          );
          if (this.tokenReloadSubscription$) {
            this.tokenReloadSubscription$.unsubscribe();
          }
        });
    });
  }

  ngOnDestroy() {
    if (this.medicalStaffProfileError$) {
      this.medicalStaffProfileError$.unsubscribe();
    }
    if (this.medicalStaffProfile$) {
      this.medicalStaffProfile$.unsubscribe();
    }
    if (this.tokenSubscription$) {
      this.tokenSubscription$.unsubscribe();
    }
    if (this.tokenReloadSubscription$) {
      this.tokenReloadSubscription$.unsubscribe();
    }

    // reset downloaded document
    this.store.dispatch(fromStore.resetGetProfile());
  }

  async proposeInterview() {
    const modal = await this.modalController.create({
      component: ProposeInterviewComponent,
      componentProps: {
        data: this.data ? this.data : [],
        medicalStaffID: this.profileID,
        reloadProfile: this.reloadProfile,
      },
    });
    console.log({
      data:
        this.medicalStaffResponse.interviewIntervals.length > 0
          ? this.medicalStaffResponse.interviewIntervals
          : [],
      medicalStaffID: this.profileID,
      reloadProfile: this.reloadProfile,
    });
    return await modal.present();
  }

  loadInteviewDates(interviewIntervals) {
    this.data = [];
    Object.keys(interviewIntervals).forEach((key) => {
      const interviewInterval = interviewIntervals[key];
      const date = format(new Date(key), 'PPPP', { locale: ro });

      const data = {
        date: this.toCamelCase(date.toString()),
        raw: key,
        hours: interviewInterval,
      };
      this.data.push(data);
    });
  }

  toCamelCase(sentenceCase: string) {
    let out = '';
    sentenceCase.split(' ').forEach((el: string, idx: number) => {
      const add = el.toLowerCase();
      out += (idx === 0 ? '' : ' ') + add[0].toUpperCase() + add.slice(1);
    });
    return out;
  }

  listRatings() {
    this.listRatingsService.presentModal(
      this.profileID,
      this.routerOutlet.nativeEl
    );
  }
}
