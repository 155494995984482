import { createAction, props } from '@ngrx/store';


const WIDGETS_TAG = '[WidgetsStatus]';

export const loadInterviews = createAction(
  `${WIDGETS_TAG} Get user interviews`
);

export const loadInterviewsStatusComplete = createAction(
  `${WIDGETS_TAG} User interviews status complete`,
  props<{ response: any[] }>(),
);

export const loadInterviewsStatusFailed = createAction(
    `${WIDGETS_TAG} User interviews status failed`,
    props<{ error: any }>(),
  );

