<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Localizare</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="full-form" *ngIf="!loading && response">
    <div class="full-form-data">
      <ion-text
        class="text-error"
        [color]="responseError ? 'danger' : 'primary'"
      >
        <p>{{ response }}</p>
      </ion-text>
    </div>
    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      (click)="doFinish()"
    >
      <span>Înapoi</span>
    </ion-button>
  </div>

  <div class="full-form" *ngIf="!loading && !response">
    <div class="full-form-data">
      <img class="visual-search" src="../../../assets/find-location.svg" />

      <ion-text class="text-error" *ngIf="locationError">
        <p>
          Vă rugăm să permiteți accesul la datele locației pentru a putea
          continua.
        </p>
      </ion-text>

      <ion-text class="text-error" *ngIf="!locationError">
        <p>
          Vă rugăm așteptați. Asigurați-vă că ați pornit locația GPS al
          dispozitivului.
        </p>
      </ion-text>
    </div>

    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      *ngIf="locationError && !location"
      (click)="findLocation()"
    >
      <span>Reîncearcă</span>
    </ion-button>

    <!-- <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      *ngIf="!locationError && location"
      (click)="doCheckin()"
    >
      <span>Continuă</span>
    </ion-button> -->
  </div>

  <ion-spinner *ngIf="loading" class="center-spinner"></ion-spinner>
</ion-content>
