<ng-container *ngIf="currentUser$ | async as currentUser">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title
        >{{ currentUser.firstName }} {{ currentUser.lastName }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon slot="icon-only" name="person-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding">
    <ion-list class="list-menu" lines="full">
      <ion-item
        detail
        routerLink="/beneficiary/personal-data"
        detailIcon="chevron-forward-outline"
      >
        <ion-label>Date Personale</ion-label>
      </ion-item>
      <ion-item
        detail
        routerLink="/beneficiary/payments"
        detailIcon="chevron-forward-outline"
      >
        <ion-label>Facturi și Plăți</ion-label>
      </ion-item>
      <ion-item detail detailIcon="chevron-forward-outline">
        <ion-label>Mesagerie</ion-label>
      </ion-item>
      <ion-item detail detailIcon="chevron-forward-outline">
        <ion-label>Despre HomeCare</ion-label>
      </ion-item>
      <ion-item routerLink="/auth/logout" class="logout-item">
        <ion-label>Închide aplicația</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>

  <ion-footer style="height: 56px">
    <app-tabs></app-tabs>
  </ion-footer>
</ng-container>
