<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Contracte</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher
    slot="fixed"
    (ionRefresh)="doRefresh($event)"
    pullFactor="0.5"
    pullMin="100"
    pullMax="200"
  >
    <ion-refresher-content
      refreshingSpinner=""
      pullingIcon="arrow-down-outline"
    ></ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="!(contracts$ | async) && (loading$ | async)">
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="error$ | async as error">
    <div class="ion-padding">
      {{ error.message }}
    </div>
  </ng-container>

  <ng-container *ngIf="contracts$ | async as contracts">
    <ng-container *ngIf="contracts.length; else zeroRecords">
      <beneficiary-contract
        (rateMedicalStaff)="handleReviewMedicalStaff($event)"
        (viewContract)="handleViewContract($event)"
        [data]="contracts"
      >
      </beneficiary-contract>
    </ng-container>
  </ng-container>
</ion-content>

<ng-template #zeroRecords>
  <div class="ion-padding">Nu am găsit contracte</div>
</ng-template>

<ion-footer style="height: 56px">
  <app-tabs></app-tabs>
</ion-footer>
