<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă un serviciu nou</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="addMedicalServiceForm" class="full-form">
    <div class="full-form-data">
      <ion-list>
        <ion-item>
          <ion-label position="stacked">Serviciu</ion-label>
          <ion-select
            [disabled]="isFetching$ | async"
            okText="Alege"
            cancelText="Închide"
            formControlName="medicalService"
            (ionChange)="onMedicalServiceChange($event)"
          >
            <ng-container
              *ngIf="
                availableMedicalServices$ | async as availableMedicalServices
              "
            >
              <ion-select-option
                *ngFor="let service of availableMedicalServices"
                [value]="service"
              >
                {{ service.name }}
              </ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>

        <ion-item
          [disabled]="!addMedicalServiceForm.controls.medicalService.value"
        >
          <ion-label position="stacked">Preț (RON)</ion-label>
          <ion-input formControlName="rate" color="dark"></ion-input>
        </ion-item>
      </ion-list>

      <div *ngIf="selectedServiceMinRate" class="ion-padding">
        Prețul minim pentru acest servicu este de
        <span class="font-bold">{{ selectedServiceMinRate }}</span>
      </div>

      <div class="errors" *ngIf="errors$ | async as errors">
        {{ errors }}
      </div>
    </div>

    <ion-button
      [disabled]="
        addMedicalServiceForm.invalid ||
        (isUpdating$ | async) ||
        (isFetching$ | async)
      "
      (click)="submit()"
      expand="block"
      color="secondary"
      class="submit-button"
    >
      <ng-container *ngIf="isUpdating$ | async">
        <ion-spinner></ion-spinner>
      </ng-container>

      <ng-container *ngIf="!(isUpdating$ | async)">
        ADAUGĂ SERVICIU
      </ng-container>
    </ion-button>
  </form>
</ion-content>
