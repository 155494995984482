import { Injectable } from '@angular/core';

import { ofType, Actions, createEffect } from '@ngrx/effects';

import { of } from 'rxjs';
import { exhaustMap, map, mergeMap, catchError, tap } from 'rxjs/operators';

// Services
import { PatientsService } from '../../core/services/patients.service';

// Actions
import * as patientsActions from '../actions/patients.actions';

@Injectable()
export class PatientsEffects {
  loadPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.loadPatients),
      exhaustMap(data =>
        this.patientsService.getAllPatients().pipe(
          map(patients => patientsActions.loadPatientsSuccess({ patients })),
          catchError((error) => of(patientsActions.loadPatientsFailed(error)))
        )
      )
    )
  );

  loadBeneficiaryAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.loadBeneficiaryAppointments),
      exhaustMap(() => this.patientsService.getAppointments().pipe(
          map(beneficiaryAppointments => patientsActions.loadBeneficiaryAppointmentsSuccess({ beneficiaryAppointments })),
          catchError(error => of(patientsActions.loadBeneficiaryAppointmentsFailed(error))),
        )
      ),
    )
  );

  //
  // loadPatient$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(patientsActions.loadPatient),
  //     exhaustMap(data =>
  //       this.patientsService.getPatient(data.patientId).pipe(
  //         map(patient => patientsActions.loadPatientSuccess({ patient })),
  //         catchError(error => of(patientsActions.loadPatientFailed(error)))
  //       )
  //     )
  //   )
  // );
  //
  // addPatient$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(patientsActions.addPatient),
  //     exhaustMap(data =>
  //       this.patientsService.addPatient(data.patient).pipe(
  //         map(patient => patientsActions.addPatientSuccess({ patient })),
  //         catchError(error => of(patientsActions.addPatientFailed(error)))
  //       )
  //     )
  //   )
  // );
  //
  // addPatientAddress$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(patientsActions.addPatientAddress),
  //     exhaustMap(data =>
  //       this.patientsService.addPatientAddress(data.address).pipe(
  //         map(res => patientsActions.addPatientAddressSuccess()),
  //         catchError(error => of(patientsActions.addPatientAddressFailed(error)))
  //       )
  //     )
  //   )
  // );


  constructor(private actions$: Actions, private patientsService: PatientsService) {}
}
