<ion-content fullscreen="true" >

    <ion-list class='list-white'>

        <ion-item lines="full" *ngFor="let item of data; let i = index">
            <ion-label slot="start">
                <h2>{{item.numarCard}}</h2>
                <p>{{item.dataExpirare}}</p>
            </ion-label>
            <ion-label slot="end" class="label-right">
                <ion-button slot="icon-only" size="large" (click)="removeCard(i)">
                    <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
            </ion-label>
        </ion-item>

    </ion-list>

    <ion-fab slot="fixed" vertical="bottom" horizontal="end" >
        <ion-fab-button class="btn-fab" (click)="addCard()">
            <ion-icon name="add-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>

  </ion-content>
