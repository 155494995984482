import {Injectable} from '@angular/core';
import {AbstractApiService} from '../../core/http/abstract-api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  ScheduleDayCreateReqBody,
  ScheduleDaysResponse,
  ScheduleDayUpdateReqBody
} from '../../core/models/medical-staff-schedule';


@Injectable({
  providedIn: 'root'
})
export class MedicalStaffScheduleApiService extends AbstractApiService {

  constructor(private http: HttpClient) {
    super();
  }

  public findAll(): Observable<ScheduleDaysResponse[]> {
    return this.http.get<ScheduleDaysResponse[]>(`${this.getBaseUrl()}/schedule`);
  }

  public create(reqBody: ScheduleDayCreateReqBody): Observable<void> {
    return this.http.post<void>(`${this.getBaseUrl()}/schedule`, reqBody);
  }

  public update(id: number, reqBody: ScheduleDayUpdateReqBody): Observable<void> {
    return this.http.post<void>(`${this.getBaseUrl()}/schedule/update-interval/${id}`, reqBody);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.getBaseUrl()}/schedule/${id}`);
  }
}
