import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {GeoCodingService} from '../../services/geocoding.service';
import {GoogleMap} from '@angular/google-maps';
import {map} from 'rxjs/operators';
import {PatientAddress} from '../../../pages/patient/model/patient.model';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild(GoogleMap) googleMap: GoogleMap;

  @Input()
  address: PatientAddress;

  @Output()
  public coordinates = new EventEmitter<object>();

  @Input()
  public radius: number;

  @Input()
  public zoom = 15;

  public apiLoaded$: Observable<boolean>;

  public markerPosition: google.maps.LatLng;

  public center: google.maps.LatLngLiteral = {lat: 45, lng: 26};

  public markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/map/beneficiary_pin.svg',
      scaledSize: {
        width: 40,
        height: 40
      } as google.maps.Size
    }
  };

  constructor(private geoCodingService: GeoCodingService) {
    this.apiLoaded$ = this.geoCodingService.loadMap$();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.address && !!changes.address.currentValue) {
      const addressFields = {...changes.address.currentValue};
      const address = `${addressFields.street} ${addressFields.streetNumber}, ${addressFields.city}, ${addressFields.country}`;

      this.geoCodingService
        .getCoordinates(address)
        .pipe(
          map(value => value[0].geometry.location)
        )
        .toPromise()
        .then((response) => {
          this.coordinates.emit({
              latitude: response.lat(),
              longitude: response.lng()
            }
          );
          this.markerPosition = response;
          this.googleMap.panTo(this.markerPosition);
        });
    }
  }
}
