import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-sesizari',
  templateUrl: './sesizari.component.html',
  styleUrls: ['./sesizari.component.scss'],
})
export class SesizariWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
