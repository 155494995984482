import { createAction, props } from '@ngrx/store';

import { Patient } from '../../core/models/patient';
import { PatientAddress } from '../../core/models/patient-address';

const PATIENTS_TAG = '[Patients]';

export const loadPatients = createAction(
  `${PATIENTS_TAG} Get all patients`,
);

export const loadPatientsSuccess = createAction(
  `${PATIENTS_TAG} Loaded all patients successfully`,
  props<{ patients: Patient[] }>(),
);

export const loadPatientsFailed = createAction(
  `${PATIENTS_TAG} Loading all patients failed`,
  props<{ error: any }>(),
);

export const addPatient = createAction(
  `${PATIENTS_TAG} Adding a patient`,
  props<{ patient: Patient; authToken: string }>(),
);

export const addPatientSuccess = createAction(
  `${PATIENTS_TAG} Added a patient successfully`,
  props<{ patient: Patient }>(),
);


export const addPatientFailed = createAction(
  `${PATIENTS_TAG} Failed adding a patient`,
  props<{ error: any }>(),
);

export const loadPatient = createAction(
  `${PATIENTS_TAG} Load a patient`,
  props<{ patientId: string; authToken: string }>(),
);

export const loadPatientSuccess = createAction(
  `${PATIENTS_TAG} Loaded a patient successfully`,
  props<{ patient: Patient }>(),
);

export const loadPatientFailed = createAction(
  `${PATIENTS_TAG} Failed loading a patient`,
  props<{ error: any }>(),
);

export const addPatientAddress = createAction(
  `${PATIENTS_TAG} Add a patient address`,
  props<{ address: PatientAddress, authToken: string }>(),
);

export const addPatientAddressSuccess = createAction(
  `${PATIENTS_TAG} Added a patient address successfully`,
);

export const addPatientAddressFailed = createAction(
  `${PATIENTS_TAG} Failed adding a patient address`,
  props<{ error: any }>(),
);

export const getPatientAddress = createAction(
  `${PATIENTS_TAG} Get patient address`,
  props<{ address: PatientAddress }>(),
);

export const getPatientAddressSuccess = createAction(
  `${PATIENTS_TAG} Get patient address successfully`,
);

export const getPatientAddressFailed = createAction(
  `${PATIENTS_TAG} Failed getting a patient address`,
  props<{ error: any }>(),
);


export const loadBeneficiaryAppointments = createAction(
  `${PATIENTS_TAG} Loading Beneficiary Appointments`
);
export const loadBeneficiaryAppointmentsSuccess = createAction(
  `${PATIENTS_TAG} Loading Beneficiary Appointments Success`,
  props<{ beneficiaryAppointments: any }>(),
);
export const loadBeneficiaryAppointmentsFailed = createAction(
  `${PATIENTS_TAG} Loading Beneficiary Appointments Failed`,
  props<{ error: any }>(),
);

