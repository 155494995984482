import {Component, OnDestroy, OnInit} from '@angular/core';
import * as fromStore from '../../../store';
import {
  selectAllMedicalStaffDocuments,
  selectAllMedicalStaffDocumentsFetchError,
  selectAllMedicalStaffDocumentsLoading, selectDownloadedDocument
} from '../../../store';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {DocumentsInterface} from 'src/app/core/models/documents.interface';
import {ViewImageModalComponent} from '../../patient/modal/view-image-modal/view-image-modal.component';
import {ModalController} from '@ionic/angular';
import {filter} from 'rxjs/operators';

export interface MedicalStaffDocumentsCategory {
  title: string;
  name: 'identityCard' | 'record' | 'other';
  loading?: boolean;
}

export interface MedicalStaffDocumentsRouteData {
  categories: MedicalStaffDocumentsCategory[];
  files: DocumentsInterface[];
}


@Component({
  selector: 'app-medical-staff-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class MedicalStaffDocumentsComponent implements OnInit, OnDestroy {

  public routeData$: Observable<MedicalStaffDocumentsRouteData> = this.store.select(selectAllMedicalStaffDocuments);

  public loading$: Observable<boolean> = this.store.select(selectAllMedicalStaffDocumentsLoading);

  public fetchError$: Observable<any> = this.store.select(selectAllMedicalStaffDocumentsFetchError);

  private currentOpenedFile: Observable<any> = this.store.select(selectDownloadedDocument);

  private buttons = [];

  constructor(private store: Store<fromStore.State>, private modalController: ModalController) {
  }

  ngOnInit() {
    this.store.dispatch(fromStore.getAllMedicalStaffDocuments());

    this.currentOpenedFile.pipe(filter(data => !!data)).subscribe((data) => {
      this.openModalViewImage(data).then();
    });
  }

  ngOnDestroy(): void {
  }

  public uploadFile(event: any, documentType: string) {
    const [file] = event.target.files;
    this.store.dispatch(fromStore.uploadMedicalStaffDocument({file, documentType}));
  }

  public openFile(ev: Event, uuid: string) {
    ev.preventDefault();
    ev.stopPropagation();

    this.store.dispatch(fromStore.getDocument({uuid}));
  }

  private async openModalViewImage(file: File) {
    const modal = await this.modalController.create({
      componentProps: {file},
      component: ViewImageModalComponent,
      cssClass: [],
    });

    await modal.present();
  }

  public populateInputFile(fileRef: HTMLInputElement) {
    if (Object.values(this.buttons).find((val) => val === true)) {
      return;
    }
    fileRef.click();
  }
}
