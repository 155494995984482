import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MedicalPersonelApiService } from 'src/app/core/http/medicalpersonel-api.service';
import { PopupMessageService } from '../popup-message/popup-message.service';

@Component({
  selector: 'app-popup-rate-user',
  templateUrl: './popup-rate-user.component.html',
  styleUrls: ['./popup-rate-user.component.css'],
})
export class PopupRateUserComponent implements OnInit {
  @Input() public userId: string;

  public currentRating = 0;
  public loading = false;
  public submited = false;

  public formRateUser = new FormGroup({
    toUserId: new FormControl(null, [Validators.required]),
    rating: new FormControl(null, [Validators.required]),
    message: new FormControl(''),
  });

  constructor(
    private medicalPersonelApiService: MedicalPersonelApiService,
    private popupMessageService: PopupMessageService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    this.formRateUser.patchValue({ toUserId: this.userId });
  }

  submitForm() {
    console.log(this.formRateUser.value);
    this.loading = true;
    this.medicalPersonelApiService
      .rateMedicalStaff(this.formRateUser.value)
      .subscribe(
        (response) => {
          console.log('response', response);
          this.loading = false;
          this.popupMessageService.presentModal([
            'Recenzia a fost inregistrat. Părerea ta va fi observată și de alți utilizatori și îi va ajuta să ia o decizie informată.',
          ]);
          this.dismissModal();
        },
        (error) => {
          console.log('PopupRateUserComponent error', error);
          this.popupMessageService.presentModal([
            'Recenzia nu a putut fi inregistrată. Vă rugăm reîncercați mai târziu.',
          ]);
          this.dismissModal();
        }
      );
  }

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
