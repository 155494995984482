<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă recenzie</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="formRateUser">
  <div class="full-form">
    <div class="full-form-data">
      <ion-label class="popup-title">
        Scrie o recenzie despre acest cadru medical
      </ion-label>

      <div *ngIf="!submited && !loading" style="padding: 10px">
        <ion-textarea
          placeholder="Adaugă comentariu..."
          class="app-textarea"
          rows="5"
          formControlName="message"
        ></ion-textarea>

        <div class="rating">
          <span
            class="star"
            (click)="formRateUser.patchValue({ rating: 50 })"
            [ngClass]="{ 'star-active': formRateUser.value.rating > 40 }"
            >☆</span
          >
          <span
            class="star"
            (click)="formRateUser.patchValue({ rating: 40 })"
            [ngClass]="{ 'star-active': formRateUser.value.rating > 30 }"
            >☆</span
          >
          <span
            class="star"
            (click)="formRateUser.patchValue({ rating: 30 })"
            [ngClass]="{ 'star-active': formRateUser.value.rating > 20 }"
            >☆</span
          >
          <span
            class="star"
            (click)="formRateUser.patchValue({ rating: 20 })"
            [ngClass]="{ 'star-active': formRateUser.value.rating > 10 }"
            >☆</span
          >
          <span
            class="star"
            (click)="formRateUser.patchValue({ rating: 10 })"
            [ngClass]="{ 'star-active': formRateUser.value.rating > 0 }"
            >☆</span
          >
        </div>
      </div>
    </div>
    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      (click)="submitForm()"
      [disabled]="!formRateUser.valid"
    >
      <span>Salvează</span>
    </ion-button>
  </div>
  <ion-spinner
    *ngIf="loading && !submited"
    class="center-spinner"
  ></ion-spinner>
</ion-content>
