import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../../store';
import {IonRouterOutlet} from '@ionic/angular';
import {AddVisitService} from './add-visit/add-visit.service';
import {Observable, Subscription} from 'rxjs';
import {VisitsService} from './visits.service';
import {filter, map} from 'rxjs/operators';
import {AppointmentsService} from '../../medical-staff/appointments/appointments.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  public beneficiaryAppointments$: Observable<any>;

  public loading$: Observable<boolean>;

  public error$: Observable<any>;

  public isBeneficiary = false;

  constructor(
    private store: Store<fromStore.State>,
    private addVisitService: AddVisitService,
    private appointmentService: AppointmentsService,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(fromStore.loadBeneficiaryAppointments());

    this.loading$ = this.store.pipe(
      select(fromStore.selectBeneficiaryAppointmentsLoading)
    );

    this.error$ = this.store.pipe(
      select(fromStore.selectBeneficiaryAppointmentsError)
    );

    this.beneficiaryAppointments$ = this.store.pipe(
      select(fromStore.selectBeneficiaryAppointments),
      filter((data) => !!data),
      map(visits => this.appointmentService.normalize(visits))
    );
  }

  addAppointment() {
    this.addVisitService.presentModal();
  }
}
