<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Document</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content slot="fixed">
  <img *ngIf="image" [src]="image" alt="doc"/>

  <ngx-extended-pdf-viewer *ngIf="document" [src]="document" useBrowserLocale="true" height="100%">
  </ngx-extended-pdf-viewer>
</ion-content>
