<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Programări</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" style="--padding-bottom: 70px">
  <ion-spinner *ngIf="loading$ | async" class="center-spinner"></ion-spinner>

  <ng-container *ngIf="appointments$ | async as appointments">
    <ion-list *ngIf="appointments.length">
      <ion-item
        *ngFor="let appointment of appointments"
        [color]="appointment.active ? 'tertiary' : 'warning-light'"
        lines="none"
        class="ion-item"
      >
        <ion-avatar slot="start">
          <img
            src="https://eu.ui-avatars.com/api/?background=3dc2ff&font-size=0.55&bold=false&color=0B1865&format=svg&name={{
              appointment.patient.firstName + ' ' + appointment.patient.lastName
            }}"
          />
        </ion-avatar>

        <div class="item-content color--primary" style="width: 100%">
          <div class="font-20" [class.color--warning]="!appointment.active">
            {{
              (isBeneficiary$ | async)
                ? appointment.medicalStaff.firstName +
                  ' ' +
                  appointment.medicalStaff.lastName
                : appointment.patient.firstName +
                  ' ' +
                  appointment.patient.lastName
            }}
          </div>

          <div class="font-18 font-bold">
            {{ appointment.appointment.date }}
          </div>

          <div>
            {{ appointment.medicalServicesNames | uppercase }}
          </div>

          <div class="font-bold" [class.color--warning]="!appointment.active">
            {{ appointment.status + ' - ' }} Durată:
            {{ appointment.appointment.duration }}m
          </div>

          <div
            class="holder-checkinout"
            *ngIf="
              !(isBeneficiary$ | async) &&
              (appointment.appointment.status === 'programmed' ||
                appointment.appointment.status === 'on going')
            "
          >
            <ion-button
              expand="block"
              color="primary"
              size="small"
              style="padding-right: 5px"
              [disabled]="appointment.appointment.status === 'on going'"
              (click)="doCheckin(appointment.appointment.id)"
            >
              <ion-icon slot="start" name="location-outline"></ion-icon>
              Checkin
            </ion-button>

            <ion-button
              expand="block"
              size="small"
              style="padding-left: 5px"
              [disabled]="appointment.appointment.status === 'programmed'"
              (click)="doCheckout(appointment.appointment.id)"
            >
              <ion-icon slot="start" name="location-outline"></ion-icon>
              Checkout
            </ion-button>
          </div>
          <div
            class="holder-checkinout"
            *ngIf="
              appointment.appointment.status === 'finalized, but not approved'
            "
          >
            <ion-button
              expand="block"
              size="small"
              *ngIf="appointment.appointment.medicalReport === null"
              (click)="
                addReport(
                  appointment.appointment.id,
                  appointment.medicalServices
                )
              "
            >
              <ion-icon slot="start" name="add-circle-outline"></ion-icon>
              Adaugă raport
            </ion-button>
          </div>
        </div>

        <!-- <div slot="end">
          <div class="icon-slot">
            <ion-icon
              [class.bg--success]="appointment.active"
              [class.bg--warning]="!appointment.active"
              [name]="appointment.active ? 'checkmark' : 'time-outline'"
              color="light"
            ></ion-icon>
          </div>
        </div> -->
      </ion-item>
    </ion-list>

    <div *ngIf="!appointments.length" class="center-float-message ion-padding">
      Nu aveți nicio programare stabilită
    </div>

    <ion-fab
      [vertical]="appointments.length ? 'bottom' : 'center'"
      [horizontal]="appointments.length ? 'end' : 'center'"
      slot="fixed"
      horizontal="end"
    >
      <ion-fab-button (click)="addAppointment()" color="secondary">
        <ion-icon name="add-outline" color="primary"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>

  <div *ngIf="error$ | async as error">
    {{ error | json }}
  </div>
</ion-content>

<ion-footer style="height: 56px">
  <app-tabs></app-tabs>
</ion-footer>
