import { Component, OnInit } from '@angular/core';
import {
  Contract,
  ContractsApiService,
} from '../../services/contracts/contracts-api.service';
import { ContractDetailsModalComponent } from '../../modals/contract-details-modal/contract-details-modal.component';
import {
  IonRouterOutlet,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../../../store';

@Component({
  selector: 'app-medical-staff-contracts',
  templateUrl: './medical-staff-contracts.component.html',
  styleUrls: ['./medical-staff-contracts.component.css'],
})
export class MedicalStaffContractsComponent implements OnInit {
  public contracts$: Observable<Contract[]>;
  public loading$: Observable<boolean>;
  public error$: Observable<any>;

  constructor(
    private contractsApiService: ContractsApiService,
    private modalController: ModalController,
    private toastController: ToastController,
    private routerOutlet: IonRouterOutlet,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.error$ = this.store.pipe(select(fromStore.selectAllContractsError));

    this.loading$ = this.store.pipe(
      select(fromStore.selectAllContractsLoading)
    );

    this.contracts$ = this.store.pipe(select(fromStore.selectAllContracts));

    this.store.dispatch(fromStore.loadContracts({ isBeneficiary: false }));
  }

  public async handleViewContract(contract: Contract) {
    const { role } = await this.openModal(contract);

    if (role === 'submit') {
      this.contractsApiService
        .signContractByMedicalStaff(contract.contractId.toString())
        .subscribe(
          async (response) => {
            const toast = await this.toastController.create({
              message: 'Contractul a fost semnat.',
              position: 'bottom',
              duration: 1500,
              color: 'success',
            });
            await toast.present();

            this.contracts$ =
              this.contractsApiService.findAllMedicalStaffContracts();
          },
          async (error) => {
            const toast = await this.toastController.create({
              message: 'A aparut o eroare, te rugam sa incerci mai tarziu.',
              position: 'bottom',
              duration: 3500,
              color: 'warning',
            });
            await toast.present();
          }
        );
    }
  }

  private async openModal(contract: Contract) {
    const modal = await this.modalController.create({
      component: ContractDetailsModalComponent,
      componentProps: {
        contract,
        type: 'medical-staff',
      },
    });

    await modal.present();

    return modal.onDidDismiss();
  }
}
