import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import {
  AuthEffects,
  MedicalStaffEffects,
  PatientsEffects,
  ProfileStatusEffects,
  WidgetsEffects,
  ContractsEffects,
  MedicalStaffProfileEffects,
  MedicalReportEffects,
} from './store';

import { getInitialState, metaReducers, reducers } from './store';

import { HttpClientModule } from '@angular/common/http';
import { PopupMessageComponent } from './_modal/popup-message/popup-message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HomeComponent } from './pages/home/home.component';
import { InterviewsWidgetComponent } from './components/interviews/interviews-widget.component';
import { ContracteWidgetComponent } from './components/contracte/contracte.component';
import { PatientsWidgetComponent } from './components/pacienti/pacienti.component';
import { SesizariWidgetComponent } from './components/sesizari/sesizari.component';
import { VisitsWidgetComponent } from './components/vizite/vizite.component';
import { BeneficiaryProfileComponent } from './pages/beneficiary/profile/profile.component';
import { BeneficiaryReceiptPaymentComponent } from './pages/beneficiary/receipt-payment/receipt-payment.component';
import { ReceiptHistoryComponent } from './pages/beneficiary/receipt-payment/receipt-history/receipt-history.component';
import { ReceiptSettingsComponent } from './pages/beneficiary/receipt-payment/receipt-settings/receipt-settings.component';
import { CardsComponent } from './pages/beneficiary/receipt-payment/cards/cards.component';
import { AddCardComponent } from './pages/beneficiary/receipt-payment/cards/add-card/add-card.component';
import { BeneficiaryPersonalDataComponent } from './pages/beneficiary/personal-data/personal-data.component';
import { MedicalStaffListingComponent } from './pages/medical-staff/medical-staff-listing/medical-staff-listing.component';
import { MedicalStaffComponent } from './pages/medical-staff/profile/medical-staff.component';
import { MedicalStaffDocumentsComponent } from './pages/medical-staff/documents/documents.component';
import { AddListingComponent } from './pages/medical-staff/medical-staff-listing/add-listing/add-listing.component';
import { MedicalStaffViewDocumentComponent } from './pages/medical-staff/view-document/view-document.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ListInterviewsComponent } from './pages/list-interviews/list-interviews.component';
import { MapPageComponent } from './pages/map/map.component';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { MedicalStaffScheduleEditRouteComponent } from './pages/medical-staff/medical-staff-schedule-edit-route/medical-staff-schedule-edit-route.component';
import { ModalScheduleEditComponent } from './components/modals/modal-schedule-edit/modal-schedule-edit.component';
import { BeneficiaryInvoiceComponent } from './components/beneficiary-invoice/beneficiary-invoice.component';

// Modules
import { MapModule } from './map/map.module';
import { ViewProfileComponent } from './pages/medical-staff/view-profile/view-profile.component';
import { ProposeInterviewComponent } from './pages/medical-staff/view-profile/propose-interview/propose-interview.component';
import { ProposeInterviewPipe } from './pages/medical-staff/view-profile/propose-interview/propose-interview.pipe';
import { PersonalDetailsComponent } from './pages/medical-staff/personal-details/personal-details.component';
import { InterviewComponent } from './pages/interview/interview.component';
import { FinancialDetailsComponent } from './pages/medical-staff/financial-details/financial-details.component';
import { ServicesSupplyComponent } from './pages/medical-staff/services-supply/services-supply.component';

// Interceptors
import * as fromInterceptors from './core/interceptors';

import { PopupCheckinoutComponent } from './_modal/popup-checkinout/popup-checkinout.component';
import { PopupMedicalReportComponent } from './_modal/popup-medical-report/popup-medical-report.component';
import { MedicalStaffScheduleExceptionEditRouteComponent } from './pages/medical-staff/medical-staff-schedule-exception-edit-route/medical-staff-schedule-exception-edit-route.component';
import { ModalScheduleExceptionEditComponent } from './components/modals/modal-schedule-exception-edit/modal-schedule-exception-edit.component';
import { MedicalStaffScheduleComponent } from './pages/medical-staff/medical-staff-schedule/medical-staff-schedule.component';
import { AppTabsModule } from './components/app-tabs/app-tabs.module';
import { PopupRateUserComponent } from './_modal/popup-rate-user/popup-rate-user.component';
import { ListRatingsComponent } from './pages/medical-staff/view-profile/list-ratings/list-ratings.component';
import { ContractsModule } from './pages/contracts/contracts.module';
import { ListPatientsComponent } from './pages/medical-staff/view-patient-profile/list-patients/list-patients.component';
import { AppointmentsComponent } from './pages/medical-staff/appointments/appointments.component';
import { AddAppointmentComponent } from './pages/medical-staff/appointments/add-appointment/add-appointment.component';
import { VisitsComponent } from './pages/beneficiary/visits/visits.component';
import { AddVisitComponent } from './pages/beneficiary/visits/add-visit/add-visit.component';
import { AppThemeComponentsComponent } from './pages/app-theme-components/app-theme-components.component';
import { WidgetComponent } from './components/ui/widget/widget.component';
import { ListItemComponent } from './components/ui/list-item/list-item.component';
import { ListAvatarComponent } from './components/ui/list-avatar/list-avatar.component';
import { WidgetContractsComponent } from './components/widget-contracts/widget-contracts.component';
import { MedicalReportModalComponent } from './components/modals/medical-report-modal/medical-report-modal.component';
import { BeneficiaryInvoiceEffects } from './store/effects/beneficiary-invoice.effects';

@NgModule({
  declarations: [
    AppComponent,
    AppThemeComponentsComponent,
    ContracteWidgetComponent,
    HomeComponent,
    InterviewsWidgetComponent,
    PopupMessageComponent,
    PatientsWidgetComponent,
    SesizariWidgetComponent,
    VisitsWidgetComponent,

    BeneficiaryProfileComponent,
    BeneficiaryReceiptPaymentComponent,
    ReceiptHistoryComponent,
    ReceiptSettingsComponent,
    CardsComponent,
    AddCardComponent,
    BeneficiaryPersonalDataComponent,
    MedicalReportModalComponent,
    MedicalStaffComponent,
    MedicalStaffListingComponent,
    MapPageComponent,
    AddListingComponent,
    MedicalStaffDocumentsComponent,
    MedicalStaffViewDocumentComponent,
    ListInterviewsComponent,
    ViewProfileComponent,
    ProposeInterviewComponent,
    ProposeInterviewPipe,
    PersonalDetailsComponent,
    InterviewComponent,
    FinancialDetailsComponent,
    ServicesSupplyComponent,
    MedicalStaffScheduleEditRouteComponent,
    ModalScheduleEditComponent,
    MedicalStaffScheduleExceptionEditRouteComponent,
    ModalScheduleExceptionEditComponent,
    MedicalStaffScheduleComponent,
    PopupRateUserComponent,
    PopupCheckinoutComponent,
    PopupMedicalReportComponent,
    ListRatingsComponent,
    ListPatientsComponent,
    AppointmentsComponent,
    AddAppointmentComponent,
    VisitsComponent,
    AddVisitComponent,

    WidgetContractsComponent,

    WidgetComponent,
    ListItemComponent,
    ListAvatarComponent,
    BeneficiaryInvoiceComponent,
  ],
  entryComponents: [PopupMessageComponent, AddCardComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      // mode: 'ios',
    }),
    AppTabsModule,
    AppRoutingModule,
    HttpClientModule,
    MapModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: getInitialState,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      AppEffects,
      AuthEffects,
      PatientsEffects,
      ProfileStatusEffects,
      WidgetsEffects,
      MedicalStaffEffects,
      ContractsEffects,
      MedicalStaffProfileEffects,
      MedicalReportEffects,
      BeneficiaryInvoiceEffects,
    ]),
    FormsModule,
    ReactiveFormsModule,
    // PacientiModule,
    NgxExtendedPdfViewerModule,
    ContractsModule,
  ],
  providers: [
    ...fromInterceptors.interceptors,
    StatusBar,
    SplashScreen,
    [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      // date Pipe doesn't work with LOCALE_ID ro
      // I tried to find the correct value (ro_RO also not valid) but I didn't find it
      // { provide: LOCALE_ID, useValue: 'ro' },
    ],
    AndroidPermissions,
    Geolocation,
  ],
  bootstrap: [AppComponent],
  exports: [WidgetComponent],
})
export class AppModule {}
