import {Component, OnInit} from '@angular/core';
import {MedicalStaffServiceFacade} from '../../../services/facade/medical-staff/medical-staff-service-facade.service';
import {Observable} from 'rxjs';
import {MedicalStaffStatus} from '../../../services/medical-staff-api/medical-staff-api.service';
import {filter, map, pluck} from 'rxjs/operators';

export interface NavItem {
  title: string;
  url: string;
  alert: boolean;
}

@Component({
  selector: 'app-medical-staff',
  templateUrl: './medical-staff.component.html',
  styleUrls: ['./medical-staff.component.scss']
})
export class MedicalStaffComponent implements OnInit {
  public navItems: NavItem[] = [
    {
      title: 'Detalii personale',
      url: '/profile/medical-staff/personal-details',
      alert: false,
    },
    {
      title: 'Servicii medicale',
      url: '/profile/medical-staff/list',
      alert: false,
    },
    {
      title: 'Program orar',
      url: '/profile/medical-staff/schedule',
      alert: false
    },
    {
      title: 'Zona Prestare Servicii',
      url: '/profile/medical-staff/services',
      alert: true,
    },
    {
      title: 'Documente',
      url: '/medical-staff/documents',
      alert: true,
    },
    {
      title: 'Detalii Financiare',
      url: '/profile/medical-staff/financial-details',
      alert: false,
    },
    {
      title: 'Închide aplicația',
      url: '/auth/logout',
      alert: false,
    },
  ];

  public statusDisplay$: Observable<{ variant: 'success' | 'warning', text: string }>;

  constructor(
    private medicalStaffServiceFacade: MedicalStaffServiceFacade
  ) {
  }

  ngOnInit() {
    this.statusDisplay$ = this.medicalStaffServiceFacade.status$.pipe(
      filter(status => !!status),
      pluck('status', 'status'),
      map(status => ({
        variant: status === 'approved' ? 'success' : 'warning',
        text: status === 'approved' ? 'Aprobat' : 'Neaprobat'
      }))
    );

    this.medicalStaffServiceFacade.dispatchLoadMedicalStaffStatus();
  }
}
