import {createAction, props} from '@ngrx/store';
import {Contract} from '../../pages/contracts/services/contracts/contracts-api.service';

const CONTRACTS_TAG = '[Contracts]';

export const loadContracts = createAction(
  `${CONTRACTS_TAG} Fetch contracts`,
  props<{ isBeneficiary: boolean }>(),
);

export const loadContractsSuccess = createAction(
  `${CONTRACTS_TAG} Fetch contracts successfully`,
  props<{ contracts: Contract[] }>(),
);

export const loadContractsFailed = createAction(
  `${CONTRACTS_TAG} Fetch contracts failed`,
  props<{ error: any }>(),
);
