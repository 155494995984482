import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { Observable } from 'rxjs';
import { AddAppointmentService } from './add-appointment/add-appointment.service';
import { map } from 'rxjs/operators';
import { PopupCheckinoutService } from 'src/app/_modal/popup-checkinout/popup-checkinout.service';
import { PopupMedicalReportService } from 'src/app/_modal/popup-medical-report/popup-medical-report.service';
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css'],
})
export class AppointmentsComponent implements OnInit {
  public appointments$: Observable<any> = this.store.pipe(
    select(fromStore.selectMedicalStaffAppointments),
    map((data) => {
      console.log(data);
      return data;
    })
  );

  public loading$: Observable<boolean> = this.store.pipe(
    select(fromStore.selectMedicalStaffAppointmentsLoading)
  );

  public error$: Observable<boolean> = this.store.pipe(
    select(fromStore.selectMedicalStaffAppointmentsError)
  );

  public isBeneficiary$ = this.store.pipe(
    select(fromStore.selectCurrentUser),
    map((user) => {
      return +user.accountType === 1;
    })
  );

  constructor(
    private store: Store<fromStore.State>,
    private addAppointmentService: AddAppointmentService,
    private popupCheckinoutService: PopupCheckinoutService,
    private popupMedicalReportService: PopupMedicalReportService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fromStore.loadMedicalStaffAppointments());
  }

  addAppointment() {
    this.addAppointmentService.presentModal();
  }

  public doCheckin(appointmentId: string) {
    this.popupCheckinoutService.presentModal(appointmentId);
  }
  public doCheckout(appointmentId: string) {
    this.popupCheckinoutService.presentModal(appointmentId, true);
  }
  public addReport(appointmentId: string, medicalServices: []) {
    console.log(medicalServices);
    const medicalIds = medicalServices.map((service: any) => {
      return service.identifier;
    });

    this.popupMedicalReportService.presentModal(appointmentId, medicalIds);
  }
}
