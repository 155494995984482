<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="medical-staff/documents"></ion-back-button>
    </ion-buttons>
    <ion-title>Documente C.D.</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ngx-extended-pdf-viewer [src]="document" useBrowserLocale="true" height="100%"
                           *ngIf="document!== null"></ngx-extended-pdf-viewer>
  <img class="image-view" [src]="image" *ngIf="image!== null" alt=""/>

  <ion-spinner class="center-spinner" *ngIf="document === null && image === null"></ion-spinner>
</ion-content>
