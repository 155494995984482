import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Contract,
  ContractsApiService,
} from '../../services/contracts/contracts-api.service';
import { ModalController } from '@ionic/angular';
import { ContractDetailsModalComponent } from '../../modals/contract-details-modal/contract-details-modal.component';
import { PopupRateUserService } from '../../../../_modal/popup-rate-user/popup-rate-user.service';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../../../store';

@Component({
  selector: 'app-beneficiary-contracts',
  templateUrl: './beneficiary-contracts.component.html',
  styleUrls: ['./beneficiary-contracts.component.css'],
})
export class BeneficiaryContractsComponent implements OnInit {
  public contracts$: Observable<Contract[]>;
  public loading$: Observable<boolean>;
  public error$: Observable<any>;

  public error: any;

  constructor(
    private contractsApiService: ContractsApiService,
    private modalController: ModalController,
    private popupRateUserService: PopupRateUserService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.error$ = this.store.pipe(select(fromStore.selectAllContractsError));

    this.loading$ = this.store.pipe(
      select(fromStore.selectAllContractsLoading)
    );

    this.contracts$ = this.store.pipe(select(fromStore.selectAllContracts));

    this.store.dispatch(fromStore.loadContracts({ isBeneficiary: true }));
  }

  public async handleViewContract(contract: Contract) {
    await this.openModal(contract);
  }

  private async openModal(contract: Contract) {
    const modal = await this.modalController.create({
      component: ContractDetailsModalComponent,
      componentProps: {
        contract,
        type: 'beneficiary',
      },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  public async handleReviewMedicalStaff(medicalStaffId: string) {
    await this.popupRateUserService.presentModal(+medicalStaffId);
  }

  public doRefresh(event) {
    event.target.complete();
    this.store.dispatch(fromStore.loadContracts({ isBeneficiary: true }));
  }
}
