<ion-header class="ion-no-border">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="profile-header">
    <div>
      <img
        height="150px"
        width="150px"
        [src]="
          medicalStaffResponse
            ? 'https://eu.ui-avatars.com/api/?size=500&background=63bbff&font-size=0.55&bold=false&color=0B1865&format=svg&name=' +
              medicalStaffResponse.medicalStaff.firstName +
              ' ' +
              medicalStaffResponse.medicalStaff.lastName
            : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
        "
        class="avatar-img"
      />
      <h1 class="avatar-name">
        {{
          !medicalStaffResponse
            ? '&nbsp;'
            : medicalStaffResponse?.medicalStaff.firstName +
              ' ' +
              medicalStaffResponse?.medicalStaff.lastName
        }}
      </h1>
      <h2 class="avatar-profesion">
        {{ !medicalStaffResponse ? '&nbsp;' : '-- Kineto Terapeut --' }}
      </h2>

      <div class="rating" [ngClass]="{ op0: !medicalStaffResponse }">
        <span
          class="star"
          [ngClass]="{
            'star-active': medicalStaffResponse?.rating.average > 4.5
          }"
          >☆</span
        >
        <span
          class="star"
          [ngClass]="{
            'star-active': medicalStaffResponse?.rating.average > 3.5
          }"
          >☆</span
        >
        <span
          class="star"
          [ngClass]="{
            'star-active': medicalStaffResponse?.rating.average > 2.5
          }"
          >☆</span
        >
        <span
          class="star"
          [ngClass]="{
            'star-active': medicalStaffResponse?.rating.average > 1.5
          }"
          >☆</span
        >
        <span
          class="star"
          [ngClass]="{
            'star-active': medicalStaffResponse?.rating.average > 0
          }"
          >☆</span
        >
      </div>
    </div>

    <ion-spinner
      *ngIf="loading"
      class="center-spinner"
      style="top: 60px"
    ></ion-spinner>
  </div>

  <div class="profile-content" *ngIf="medicalStaffResponse" [@slideInOut]>
    <ion-card class="profesional-info-card">
      <div class="profesional-info-segment">
        <span class="profesional-info-title">Clienți</span>
        <span class="profesional-info-value">{{
          medicalStaffResponse.customerCount
        }}</span>
      </div>
      <div class="profesional-info-segment">
        <ion-icon
          name="reader-outline"
          class="profesional-info-icon"
        ></ion-icon>
        <span class="profesional-info-link" (click)="listRatings()"
          >Vezi recenzii</span
        >
      </div>
      <div class="profesional-info-segment">
        <span class="profesional-info-title">Recenzii</span>
        <span class="profesional-info-value">{{
          medicalStaffResponse.rating.count
        }}</span>
      </div>
    </ion-card>

    <ion-card class="profesional-fields-card">
      <span class="profesional-fields-title">Specializări</span>
      <ion-list lines="full" class="list-menu">
        <div
          *ngFor="
            let group of medicalStaffResponse.medicalServices;
            let i = index
          "
        >
          <ion-item-divider *ngIf="group.services.length > 0">
            <span>{{ group.name }} - {{ group.description }}</span>
          </ion-item-divider>

          <ion-item *ngFor="let service of group.services">
            <ion-label slot="start">
              {{
                service.description
                  ? service.name + ' - ' + service.description
                  : service.name
              }}
            </ion-label>
            <ion-label slot="end" class="price-label">
              <h2>{{ service.currentRate }}</h2>
            </ion-label>
          </ion-item>
        </div>
      </ion-list>
    </ion-card>

    <ion-button
      expand="block"
      color="secondary"
      class="submit-button"
      (click)="proposeInterview()"
      >Propune interviu
    </ion-button>
  </div>
</ion-content>
