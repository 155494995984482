import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contract, ContractStatusTypes, StatusVariant} from '../../services/contracts/contracts-api.service';


@Component({
  selector: 'medical-staff-contract',
  templateUrl: './medical-staff-contract.component.html',
  styleUrls: ['./medical-staff-contract.component.css']
})
export class MedicalStaffContractComponent implements OnInit {

  @Input()
  data: Contract[];

  @Output()
  viewContract = new EventEmitter<Contract>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public onViewContract(contract: Contract) {
    this.viewContract.emit(contract);
  }
}
