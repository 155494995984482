import { Injectable } from '@angular/core';
import {AbstractApiService} from '../../core/http/abstract-api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ScheduleException,
  ScheduleExceptionCreateReqBody,
  ScheduleExceptionUpdateReqBody
} from '../../core/models/medical-staff-schedule-exception';

@Injectable({
  providedIn: 'root'
})
export class MedicalStaffScheduleExceptionApiService extends AbstractApiService {

  constructor(private http: HttpClient) {
    super();
  }

  public findAll(): Observable<ScheduleException[]> {
    return this.http.get<ScheduleException[]>(`${this.getBaseUrl()}/schedule/exception`);
  }

  public create(body: ScheduleExceptionCreateReqBody): Observable<void> {
    return this.http.post<void>(`${this.getBaseUrl()}/schedule/exception`, body);
  }

  public update(scheduleExceptionId: number, body: ScheduleExceptionUpdateReqBody): Observable<void> {
    return this.http.post<void>(`${this.getBaseUrl()}/schedule/exception/update/${scheduleExceptionId}`, body);
  }

  public delete(scheduleExceptionId: number): Observable<void> {
    return this.http.delete<void>(`${this.getBaseUrl()}/schedule/exception/${scheduleExceptionId}`);
  }
}
