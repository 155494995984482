import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromStore from '../../store';

import { BaseComponentDirective } from 'src/app/components/base.components';

@Injectable()
export class TokenInterceptor extends BaseComponentDirective implements HttpInterceptor {
  constructor(private store: Store<fromStore.State>) {
    super();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.pipe(
      select(fromStore.selectToken),
      takeUntil(this.ngDestroyed$)
    ).subscribe(token => {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          }
        });
      }
    });

    return next.handle(request);
  }
}
