import { Injectable } from '@angular/core';
import { format, formatDistanceToNow } from 'date-fns';
import { ro } from 'date-fns/locale';


@Injectable({
  providedIn: 'root'
})
export class InterviewData {

  constructor(
  ) {

  }

  normalize(data: any[]) {
    const result = [];
    if (data.length > 0) {
      data.forEach(interview => {

        // TODO: DO NOT EVER DO THIS!!!!! FIX IN API DATE STRING FORMAT SHOULD BE 2021-02-08T23:18:20
        const interviewDate = new Date( interview.date.replace(' ', 'T'));

        const processed = {...interview};

        processed.dateDisplay = format(new Date(interviewDate), 'PPPp', {locale: ro});
        processed.distanceDisplay = formatDistanceToNow(new Date(interviewDate), {locale: ro});
        processed.medicalStaffName = processed.medicalStaff.firstName + ' ' + processed.medicalStaff.lastName;
        processed.beneficiaryName = processed.beneficiary.firstName + ' ' + processed.beneficiary.lastName;

        if (interviewDate.getTime() + (processed.duration * 60 * 1000)  < new Date().getTime()) {
          processed.callState = 'closed';
          processed.callStatus = 'Încheiat';
        } else {
          if (interviewDate.getTime() < new Date().getTime()) {
            processed.callState = 'open';
            processed.callStatus = processed.distanceDisplay;
          } else {
            processed.callState = 'waiting';
            processed.callStatus = 'În aşteptare';
          }
        }
        result.push(processed);
      });
    }
    return result;
  }
}







