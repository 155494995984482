<ion-content>
  <div class="ion-padding" style="padding-bottom: 0">
    <ion-segment [(ngModel)]="switchValue" color="primary" mode="ios">
      <ion-segment-button value="beneficiary">
        <ion-label>Persoană fizică</ion-label>
      </ion-segment-button>
      <ion-segment-button value="company">
        <ion-label>Persoană Juridică</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div>
    <div *ngIf="switchValue === 'beneficiary'" class="holder-data">
      <form [formGroup]="beneficiaryAddressForm" [@slideInOut]>
        <div class="full-form">
          <ion-list class="full-form-data">
            <ion-item lines="full">
              <ion-label position="stacked">Țara</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="country"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Județ</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="district"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Localitate</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="city"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Strada</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="street"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Număr</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="streetNumber"
              ></ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="ion-margin">
          <ion-button
            expand="block"
            class="submit-button"
            color="secondary"
            [disabled]="
              !beneficiaryAddressForm.dirty ||
              beneficiaryAddressForm.invalid ||
              beneficiaryAddressForm.disabled
            "
            (click)="handleBeneficiaryFormSubmit()"
          >
            Salvează
          </ion-button>
        </div>
      </form>
    </div>

    <div *ngIf="switchValue === 'company'" class="holder-data">
      <form [formGroup]="companyForm" class="full-form" [@slideInOut]>
        <div class="full-form-data">
          <ion-list>
            <ion-item lines="full">
              <ion-label position="stacked">Nume firmă</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="companyName"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">CUI firmă</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="uniqueRegistrationCode"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">CIF</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="vatIdentificationNumber"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Email</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="email"
                type="email"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Țara</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="country"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Județ</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="district"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Localitate</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="city"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Strada</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="street"
              ></ion-input>
            </ion-item>

            <ion-item lines="full">
              <ion-label position="stacked">Număr</ion-label>
              <ion-input
                class="hct-ion-form-input"
                formControlName="streetNumber"
              ></ion-input>
            </ion-item>
          </ion-list>

          <div *ngIf="companyForm.errors" class="errors">
            <p *ngForOf="let error of errors">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="ion-margin">
          <ion-button
            expand="block"
            color="secondary"
            class="submit-button"
            [disabled]="
              !companyForm.dirty || companyForm.invalid || companyForm.disabled
            "
            (click)="handleCompanyFormSubmit()"
          >
            Salvează
          </ion-button>
        </div>
      </form>
    </div>
  </div>
</ion-content>
