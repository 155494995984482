import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MedicalStaffApiService } from 'src/app/services/medical-staff-api/medical-staff-api.service';
import * as fromStore from '../../store';
import { ToastMessageService } from '../toast-message/toast-message.service';

@Component({
  selector: 'app-popup-medical-report',
  templateUrl: './popup-medical-report.component.html',
  styleUrls: ['./popup-medical-report.component.scss'],
})
export class PopupMedicalReportComponent implements OnInit, OnDestroy {
  @Input() public appointmentId: string;
  @Input() public medicalServices: [];

  public medicalReportForm = new FormGroup({
    appointmentId: new FormControl(null, [Validators.required]),
    medicalServices: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [
      Validators.required,
      Validators.minLength(50),
    ]),
  });

  private addMedicalReportSubscription: Subscription = null;

  public errors = [];

  constructor(
    private store: Store<fromStore.State>,
    private modalCtrl: ModalController,
    private medicalStaffApiService: MedicalStaffApiService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnDestroy(): void {
    if (this.addMedicalReportSubscription) {
      this.addMedicalReportSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.medicalReportForm.patchValue({
      appointmentId: this.appointmentId,
      medicalServices: this.medicalServices,
    });

    console.log(this.medicalReportForm.value);
  }

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  doSubmit() {
    this.medicalReportForm.disable();
    this.addMedicalReportSubscription = this.medicalStaffApiService
      .addMedicalReport(this.medicalReportForm.value, this.appointmentId)
      .subscribe(
        (response) => {
          console.log(response);
          this.toastMessageService.presentToast(
            'Raportul medical a fost salvat cu succes.'
          );
          this.doFinish();
        },
        (error) => {
          this.medicalReportForm.enable();
          if (error.hasOwnProperty('error')) {
            this.errors = [];
            const errorKeys = Object.keys(error.error);
            errorKeys.map((key) =>
              error.error[key].forEach((element) => {
                this.errors.push(element);
              })
            );
          } else {
            this.errors = [
              'A fost înregistrată o eroare de server. Răspuns ' + error.status,
            ];
          }
        }
      );
  }

  doFinish() {
    this.store.dispatch(fromStore.loadMedicalStaffAppointments());
    this.dismissModal();
  }
}
