import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { Observable, Subscription } from 'rxjs';
import { MedicalPersonelApiService } from 'src/app/core/http/medicalpersonel-api.service';
import {
  Contract,
  ContractsApiService,
} from 'src/app/pages/contracts/services/contracts/contracts-api.service';
import * as fromStore from '../../../../store';

@Component({
  selector: 'app-add-visit',
  templateUrl: './add-visit.component.html',
  styleUrls: ['./add-visit.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '400ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class AddVisitComponent implements OnInit, OnDestroy {
  public contracts$: Observable<Contract[]>;
  public availableHours$: Subscription;

  public medicalStaffId: any = null;
  public contracts: any[] = null;
  public services: any[] = null;
  public availableHours: any[] = null;
  public hours: any[] = null;
  public availableDays: any[] = null;
  public selectedDay: string = null;
  public selectedContract: any = null;
  public selectedHour: any = null;

  public loading = true;
  public appointmentAdded = false;

  public form = new FormGroup({
    contractId: new FormControl(null, [Validators.required]),
    medicalStaffId: new FormControl(null, [Validators.required]),
    patientId: new FormControl(null, [Validators.required]),
    medicalServiceIds: new FormControl([], [Validators.required]),
    date: new FormControl(null, [Validators.required]),
    comments: new FormControl(''),
  });

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromStore.State>,
    private contractsApiService: ContractsApiService,
    private medicalPersonelApiService: MedicalPersonelApiService
  ) {}

  ngOnDestroy(): void {
    if (this.availableHours$) {
      this.availableHours$.unsubscribe();
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  ngOnInit(): void {
    this.contracts$ = this.contractsApiService.findAllBeneficiaryContractsOngoing();
    this.contracts$.subscribe((resp) => {
      console.log('beneficiary contracts', resp);
      this.contracts = resp;
      this.loading = false;
    });
  }

  onSelectContract(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      contractId: event.srcElement.value.contractId,
      medicalStaffId: event.srcElement.value.medicalStaff.identifier,
      patientId: event.srcElement.value.patient.id,
      date: null,
    });
    this.selectedContract = event.srcElement.value;

    this.services = event.srcElement.value.services;
    this.medicalStaffId = event.srcElement.value.medicalStaff.identifier;
  }

  onSelectMedicalService(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      medicalServiceIds: event.srcElement.value,
      date: null,
    });

    console.log('before call', this.medicalStaffId, event.srcElement.value);
    this.availableHours$ = this.contractsApiService
      .getMedicalStaffAvailableHours(
        this.medicalStaffId,
        event.srcElement.value
      )
      .subscribe(
        (hours) => {
          this.hours = hours;
          this.availableDays = [];
          for (const day of Object.keys(hours)) {
            this.availableDays.push({
              raw: day,
              displayDate: format(new Date(day), 'PPP', { locale: ro }),
            });
          }
        },
        (err) => {
          if (err.errors) {
            const bkErrors = Object.values(err.errors);
            this.form.enable();
            this.form.setErrors({ bkError: bkErrors });
          } else {
            this.form.setErrors({
              bkError: [
                'Serviciul nu este disponibil. Vă rugăm contactați administratorul.',
              ],
            });
          }
        }
      );
  }

  onSelectDay(event) {
    if (event.srcElement.value === undefined) {
      return;
    }
    this.selectedDay = event.srcElement.value;
    this.availableHours = this.hours[event.srcElement.value];
  }

  onSelectHour(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      date: this.selectedDay + ' ' + event.srcElement.value,
    });
    this.selectedHour = event.srcElement.value;
  }

  now() {
    return new Date().toString();
  }

  submitForm() {
    this.loading = true;
    this.medicalPersonelApiService
      .addAppointment(this.form.value)
      .subscribe((response) => {
        this.appointmentAdded = true;
        this.loading = false;
        this.store.dispatch(fromStore.loadBeneficiaryAppointments());
      });
  }

  getDisplayDate() {
    if (this.selectedDay) {
      return format(new Date(this.selectedDay), 'PPP', { locale: ro });
    }
  }
}
