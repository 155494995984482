<ion-header>
  <ion-toolbar color="primary">
    <ion-title> Cadru Medical </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list class="list-menu" lines="full">
    <ion-item style="--inner-padding-end: 0">
      <div
        class="
          d-flex d-flex-row
          ion-justify-content-between ion-align-items-center
          w-full
        "
      >
        <div>STATUS</div>

        <div class="w-50 ion-text-center">
          <ion-badge
            *ngIf="statusDisplay$ | async as statusDisplay"
            [color]="statusDisplay.variant"
            class="w-full"
          >
            {{ statusDisplay.text }}
          </ion-badge>

          <ion-spinner *ngIf="!(statusDisplay$ | async)"></ion-spinner>
        </div>
      </div>
    </ion-item>

    <ion-item
      [detail]="!lastItem"
      button
      *ngFor="let navItem of navItems; let lastItem = last"
      [routerLink]="navItem.url"
      [class.logout-item]="lastItem"
      detailIcon="chevron-forward-outline"
    >
      <ion-label>
        {{ navItem.title }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer style="height: 56px">
  <app-tabs></app-tabs>
</ion-footer>
