<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>Interviuri</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ng-container *ngIf="interviews$ | async as interviews">
    <ion-list *ngIf="interviews.length">
      <ion-item
        *ngFor="let interview of interviews"
        [color]="
          interview.callState === 'closed' ? 'warning-light' : 'tertiary'
        "
        lines="none"
        class="ion-item"
      >
        <ion-avatar slot="start">
          <img
            src="https://eu.ui-avatars.com/api/?background=3dc2ff&font-size=0.55&bold=false&color=0B1865&format=svg&name={{
              interview.avatarName
            }}"
          />
        </ion-avatar>

        <div class="item-content color--primary">
          <div
            class="font-20"
            [class.color--warning]="interview.callState === 'closed'"
          >
            {{
              isBeneficiary
                ? interview.beneficiaryName
                : interview.medicalStaffName
            }}
          </div>

          <div class="font-bold">
            {{ interview.date }}
          </div>

          <div [class.color--warning]="interview.callState === 'closed'">
            <span class="font-14 font-bold">{{ interview.callStatus }}</span>
            <span *ngIf="interview.interviewStatus" class="font-14 font-bold">{{
              interview.interviewStatus
            }}</span>
          </div>

          <div>
            <ion-button
              expand="block"
              color="primary"
              size="small"
              [routerLink]="
                '/interview/' +
                interview.interviewIdentifier +
                '/' +
                (isBeneficiary
                  ? interview.beneficiaryName
                  : interview.medicalStaffName)
              "
              class="interview-list-button"
            >
              <ion-icon slot="start" name="videocam-outline"></ion-icon>
              Participă
            </ion-button>

            <ion-button
              expand="block"
              size="small"
              [routerLink]="
                '/contracts/interview/' +
                interview.interviewIdentifier +
                '/beneficiary/establish'
              "
              [queryParams]="{
                medicalStaffId: interview.medicalStaff.identifier
              }"
              *ngIf="interview.interviewCompleted === true && isBeneficiary"
            >
              <ion-icon slot="start" name="add-outline"></ion-icon>
              Contract
            </ion-button>
          </div>
        </div>

        <div slot="end">
          <div class="icon-slot">
            <ion-icon
              [class.bg--success]="interview.callState !== 'closed'"
              [class.bg--warning]="interview.callState === 'closed'"
              [name]="
                interview.callState === 'closed'
                  ? 'time-outline'
                  : 'checkmark-outline'
              "
              color="light"
            >
            </ion-icon>
          </div>
        </div>
      </ion-item>
    </ion-list>

    <ng-container *ngIf="!interviews.length">
      Nu am găsit interviuri
    </ng-container>
  </ng-container>

  <ng-container *ngIf="loading$ | async">
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="error$ | async as error">
    <div class="ion-padding">
      {{ error.message | json }}
    </div>
  </ng-container>

  <!--  <ion-list *ngIf="data?.length > 0">-->
  <!--    <ion-item lines="none"-->
  <!--              class="interview-holder"-->
  <!--              [ngClass]="{-->
  <!--                'gray-background' : interview.callStatus === 'Incheiat',-->
  <!--                'atention-interview-holder' : interview.callStatus === 'In asteptare' || interview.callState === 'open'}"-->
  <!--              *ngFor="let interview of data">-->

  <!--      <div class="item-holder">-->
  <!--        <div class="item-description">-->
  <!--          <ion-avatar>-->
  <!--            <img-->
  <!--              src="https://eu.ui-avatars.com/api/?size=500&background=3A8588&font-size=0.55&bold=true&color=ffffff&format=svg&name={{isBeneficiary ? interview.beneficiaryName : interview.medicalStaffName}}">-->
  <!--          </ion-avatar>-->

  <!--          <div>-->
  <!--            <ion-label-->
  <!--              class="widget-interviu-text-left">{{isBeneficiary ? interview.beneficiaryName : interview.medicalStaffName}} </ion-label>-->
  <!--            <ion-label class="widget-interviu-textextra-left">{{interview.date}}</ion-label>-->
  <!--            <ion-label class="widget-interviu-textextra-left">{{interview.negociationStatus}}</ion-label>-->
  <!--          </div>-->

  <!--          <div>-->
  <!--            <div class="status-holder">-->
  <!--              <ion-label class="widget-interviu-text-right">{{interview.callStatus}}</ion-label>-->
  <!--              <ion-icon name="time-outline" class="widget-interviu-icon-right"-->
  <!--                        *ngIf="interview.callStatus === 'In asteptare' || interview.callState === 'open'"></ion-icon>-->
  <!--              <ion-icon name="checkmark" class="widget-interviu-icon-right gray-color"-->
  <!--                        *ngIf="interview.callStatus === 'Incheiat'"></ion-icon>-->
  <!--            </div>-->
  <!--            <ion-label class="interview-status"-->
  <!--                       *ngIf="interview.interviewStatus">{{interview.interviewStatus}}</ion-label>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="item-options">-->
  <!--          <ion-button expand="block" class="btn-adauga" shape="round" *ngIf="interview.callState === 'open'"-->
  <!--                      [routerLink]="'/interview/' + interview.interviewIdentifier + '/' + (isBeneficiary ? interview.beneficiaryName : interview.medicalStaffName)">-->
  <!--            <ion-icon slot="start" name="videocam-outline"></ion-icon>-->
  <!--            <span>PARTICIPA</span>-->
  <!--          </ion-button>-->
  <!--          <ion-button expand="block" class="btn-adauga" shape="round"-->
  <!--                      [routerLink]="'/contracts/interview/' + interview.interviewIdentifier + '/beneficiary/establish'"-->
  <!--                      [queryParams]="{medicalStaffId: interview.medicalStaff.identifier}"-->
  <!--                      *ngIf="interview.interviewCompleted === true && isBeneficiary">-->
  <!--            <ion-icon slot="start" name="add-outline"></ion-icon>-->
  <!--            <span>CONTRACT</span>-->
  <!--          </ion-button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </ion-item>-->
  <!--  </ion-list>-->
</ion-content>
