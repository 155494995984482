import {Action, createReducer, on} from '@ngrx/store';

import * as fromContractsActions from '../actions/contracts.actions';
import {Contract} from '../../pages/contracts/services/contracts/contracts-api.service';

export interface ContractsState {
  contracts: Contract[];
  loading: boolean;
  error: any;
}

export const initialState: ContractsState = {
  contracts: null,
  loading: false,
  error: null
};

const patientsReducer = createReducer(
  initialState,

  on(fromContractsActions.loadContracts, (state: ContractsState) => ({
    ...state,
    error: null,
    contracts: null,
    loading: true
  })),
  on(fromContractsActions.loadContractsSuccess, (state: ContractsState, payload) => ({
    ...state,
    contracts: payload.contracts,
    loading: false
  })),
  on(fromContractsActions.loadContractsFailed, (state: ContractsState, payload) => ({
    ...state,
    error: payload.error,
    loading: false
  }))
);

export function reducer(state: ContractsState | undefined, action: Action): ContractsState {
  return patientsReducer(state, action);
}

