import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {AbstractApiService} from './abstract-api.service';

import {User, LoginCredentials, AccountType} from '../models';
import {ResetPassword} from '../models/reset-password.interface';

@Injectable({providedIn: 'root'})
export class AuthApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Login an existing user passing credentials
   */
  login(credentials: LoginCredentials): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/login`, credentials).pipe(
      map((result) => result),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Register a new account
   */
  register(user: User): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/signup`, user).pipe(
      map((result) => {
      }),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Reset password
   */
  resetPassword(resetPassword: ResetPassword): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/reset-password`, resetPassword).pipe(
      map((result) => {
      }),
      catchError((err) => throwError(err))
    );
  }

  confirmResetPassword(code): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/reset-password/${code}`).pipe(
      map((result) => {
      }),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Send a new account validation request
   * to the API
   *
   * @param phoneNumber string
   * @param phonePrefix  string
   * @param accounType  AccountType
   * @param code string
   */
  verifyAccount(
    phoneNumber: string,
    phonePrefix: string,
    accountType: AccountType,
    code: string
  ): Observable<any> {
    return this.http
      .post(
        `${this.getBaseUrl()}/verify-account`,
        {
          phoneNumber,
          phonePrefix,
          accountType,
          code,
        }
      )
      .pipe(
        map((result) => result),
        catchError((err) => throwError(err))
      );
  }

  /**
   * Get current logged in user info
   */
  userInfo(): Observable<any> {
    return this.http
      .get(`${this.getBaseUrl()}/@me`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => throwError(error))
      );
  }

  /**
   * Logout current user
   *
   * @param authToken Authorization token
   */
  logout(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/logout`).pipe(
      map(result => result),
      catchError(error => throwError(error))
    );
  }
}
