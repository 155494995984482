<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Rating-uri cadru medical</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-spinner *ngIf="loading" class="center-spinner"></ion-spinner>

  <ion-list *ngIf="!loading && data.length > 0" class="list-menu ion-padding">
    <ion-item
      *ngFor="let rating of data"
      lines="full"
      class="ion-no-padding"
      style="--padding-top: 10px; --padding-bottom: 10px"
    >
      <div>
        <div class="rating">
          <span
            class="star"
            [ngClass]="{
              'star-active': rating.rating > 40
            }"
            >☆</span
          >
          <span
            class="star"
            [ngClass]="{
              'star-active': rating.rating > 30
            }"
            >☆</span
          >
          <span
            class="star"
            [ngClass]="{
              'star-active': rating.rating > 20
            }"
            >☆</span
          >
          <span
            class="star"
            [ngClass]="{
              'star-active': rating.rating > 10
            }"
            >☆</span
          >
          <span
            class="star"
            [ngClass]="{
              'star-active-first': rating.rating > 0
            }"
            style="padding-left: 0"
            >☆</span
          >
        </div>
        <ion-text>
          <p class="rating-message">{{ rating.message }}</p>
        </ion-text>
      </div>
    </ion-item>
  </ion-list>

  <ion-text *ngIf="!loading && data.length === 0">
    <p class="rating-message-none">Acest cadru medical nu are niciun rating.</p>
  </ion-text>
</ion-content>
