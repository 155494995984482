export const WeekDaysMapping = {
  Monday: 'Luni',
  Tuesday: 'Marti',
  Wednesday: 'Miercuri',
  Thursday: 'Joi',
  Friday: 'Vineri',
  Saturday: 'Sambata',
  Sunday: 'Duminica',
  Luni: 'Monday',
  Marti: 'Tuesday',
  Miercuri: 'Wednesday',
  Joi: 'Thursday',
  Vineri: 'Friday',
  Sambata: 'Saturday',
  Duminica: 'Sunday',
};

export interface ScheduleTimeFrame {
  start: string;
  end: string;
  scheduleId?: number;
  duration?: number;
}

export interface ScheduleDay {
  day: string;
  availableTimeFrame: ScheduleTimeFrame[];
}

export interface ScheduleDaysResponse {
  medicalStaffId?: number;
  days: ScheduleDay[];
}

export interface ScheduleDayUpdateReqBody {
  days: ScheduleDay[];
}

export interface ScheduleDayCreateReqBody {
  days: ScheduleDay[];
}

export enum ModalActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL'
}

export interface MedicalStaffScheduleModalConfig {
  timeFrameId: number;
  formValue: ScheduleFormValue;
  action: ModalActionType;
  i18n: {
    title: string;
    button: string;
  };
}

export enum WeekDaysEnum {
  Luni,
  Marti,
  Miercuri,
  Joi,
  Vineri,
  Sambata,
  Duminica
}

export interface ScheduleFormValue {
  day: string;
  availableTimeFrame: {
    start: string;
    end: string;
  };
}
