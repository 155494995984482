import {Injectable} from '@angular/core';
import {AbstractApiService} from '../../../../core/http/abstract-api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Patient} from '../../../patient/model/patient.model';
import {map} from 'rxjs/operators';


export enum ContractStatusTypes {
  'Draft' = 0,
  'Semnaturi in asteptare' = 1,
  'Plata in astetptare' = 2,
  'In derulare' = 3,
  'Inchis'
}

export enum StatusVariant {
  'light',
  'warning',
  'secondary',
  'success',
  'medium'
}

export interface User {
  accountType: 1 | 2;
  email: string;
  firstName: string;
  lastName: string;
  identifier: string;
  phoneNumber: string;
  phonePrefix: string;
}

export interface ContractService {
  medicalServiceId: string;
  medicalServiceName: string;
  occurrences: number;
  rate: string;
}

export interface Contract {
  beneficiary: User;
  patient: Patient;
  medicalStaff: User;
  contractId: number;
  services: ContractService[];
  signatures: {
    beneficiarySignedAt: string,
    medicalStaffSignedAt: string
  };
  status: number;

  ui: {
    variant: string;
    statusTranslate: string;
    totalRate: number;
    currency: string;
  };
}

export interface CreateContractReqBody {
  medicalStaffServices: { medicalStaffId: number, noOfRecurrences: number }[];
  patientId: number;
}

@Injectable({
  providedIn: 'root'
})
export class ContractsApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public findAllBeneficiaryContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.getBaseUrl()}/beneficiary/contracts`).pipe(
      map(response => response.map((contract) => ({
            ...contract,
            ui: {
              variant: StatusVariant[contract.status],
              statusTranslate: ContractStatusTypes[contract.status],
              totalRate: contract.services
                .map((service) => parseInt(service.rate.split(' ')[0], 10) * service.occurrences)
                .reduce((total, rate) => {
                  total += rate;
                  return total;
                }, 0),
              currency: contract.services[0] ? contract.services[0].rate.split(' ')[1] : ''
            }
          }
        )
      ))
    );
  }

  public findAllBeneficiaryContractsOngoing(): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.getBaseUrl()}/beneficiary/contracts/ongoing`);
  }

  public findBeneficiaryContractsById(contractId: string): Observable<Contract> {
    return this.http.get<Contract>(`${this.getBaseUrl()}/beneficiary/contracts/${contractId}`);
  }

  public findAllMedicalStaffContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.getBaseUrl()}/medical-staff/contracts`).pipe(
      map(response => response.map((contract) => ({
            ...contract,
            ui: {
              variant: StatusVariant[contract.status],
              statusTranslate: ContractStatusTypes[contract.status],
              totalRate: contract.services
                .map((service) => parseInt(service.rate.split(' ')[0], 10) * service.occurrences)
                .reduce((total, rate) => {
                  total += rate;
                  return total;
                }, 0),
              currency: contract.services[0] ? contract.services[0].rate.split(' ')[1] : ''
            }
          }
        )
      ))
    );
  }

  public findAllMedicalStaffOngoingContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(`${this.getBaseUrl()}/medical-staff/contracts/ongoing`);
  }

  public signContractByMedicalStaff(contractId: string): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/medical-staff/contracts/${contractId}/sign`, {});
  }

  public createContract(beneficiaryId: string, interviewId: string, payload: CreateContractReqBody): Observable<Contract> {
    return this.http.post<Contract>(`${this.getBaseUrl()}/beneficiary/${beneficiaryId}/interviews/${interviewId}/contract`, payload);
  }

  public getMedicalStaffAvailableHours(medicalStaffId: string, medicalServiceIds: string[]): Observable<any[]> {
    let extra = '';
    let firstTime = true;
    for (const medicalService of medicalServiceIds) {
      if (firstTime) {
        extra += '?mds[]=' + medicalService;
        firstTime = false;
      } else {
        extra += '&mds[]=' + medicalService;
      }
    }
    return this.http.get<any[]>(`${this.getBaseUrl()}/medical-staff/${medicalStaffId}/appointment-intervals${extra}`);
  }
}
