import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';

import * as medicalStaffProfileActions from '../actions/medical-staff-profile.actions';
import {MedicalStaffApiService} from '../../services/medical-staff-api/medical-staff-api.service';
import {ToastService} from '../../core/services/toast.service';

@Injectable()
export class MedicalStaffProfileEffects {
  loadMedicalStaffProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffProfileActions.loadMedicalStaffProfile),
      exhaustMap(() => {
        return this.medicalStaffApiService.findDetails().pipe(
          map(profile => medicalStaffProfileActions.loadMedicalStaffProfileSuccess({profile})),
          catchError((error) => of(medicalStaffProfileActions.loadMedicalStaffProfileFailed(error)))
        );
      })
    )
  );

  updateMedicalStaffProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalStaffProfileActions.updateMedicalStaffProfile),
      exhaustMap(({payload}) => {
        return this.medicalStaffApiService.updateDetails(payload).pipe(
          map(profile => medicalStaffProfileActions.updateMedicalStaffProfileSuccess()),
          tap(() => {
            this.toastService
              .presentToast({
                message: 'Detalii personale au fost actualizate.',
                color: 'success'
              });
          }),
          catchError((error) => of(medicalStaffProfileActions.updateMedicalStaffProfileFailed(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private medicalStaffApiService: MedicalStaffApiService,
    private toastService: ToastService
  ) {
  }
}
