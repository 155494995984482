import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { PopupMessageService } from 'src/app/_modal/popup-message/popup-message.service';
import { format } from 'date-fns';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../../../store';
import { BeneficiaryApiService } from 'src/app/core/http/beneficiary-api.service';
import { Subject, Subscribable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-propose-interview',
  templateUrl: './propose-interview.component.html',
  styleUrls: ['./propose-interview.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class ProposeInterviewComponent implements OnInit, OnDestroy {
  @Input() data: {
    date: string;
    raw: string;
    hours: { start: string; end: string }[];
  }[];
  @Input() medicalStaffID: string;
  @Input() reloadProfile: Subject<any>;

  loading = false;
  submited = false;

  public availableHours = null;

  public formProposal = new FormGroup({
    raw: new FormControl('', [Validators.required]),
    hours: new FormControl(null, [Validators.required]),
  });

  private tokenSubscription$: Subscription;

  public errors = null;
  public selectedDayModel = '';

  constructor(
    private modalCtrl: ModalController,
    private popupMessageService: PopupMessageService,
    private store: Store<fromStore.State>,
    private beneficiaryApiService: BeneficiaryApiService,
    private router: Router
  ) {}

  ngOnInit() {}

  submit() {
    const date =
      this.formProposal.value.raw + ' ' + this.formProposal.value.hours.start;
    // // native mobile app is not converting date correctly
    // if (this.platform.is('cordova') && this.platform.is('ios')) {
    //     date = format(
    //       new Date(
    //         new Date(this.formProposal.value.raw + 'T' + this.formProposal.value.hours.start).toUTCString().substr(0, 25)
    //       ),
    //       'yyyy-MM-dd HH:mm:ss');
    //  }
    // set data for api call
    const data = {
      date,
      comments: 'Test',
    };

    this.tokenSubscription$ = this.store
      .pipe(select(fromStore.selectToken))
      .subscribe((authToken) => {
        if (!authToken) {
          return;
        }

        this.loading = true;
        this.beneficiaryApiService
          .setInterviewWithMedicalStaff(authToken, this.medicalStaffID, data)
          .subscribe(
            (result) => {
              this.submited = true;
              this.reloadProfile.next();
              // refresh interviews in redux
              this.store.dispatch(fromStore.loadInterviews());
            },
            (err) => {
              let message =
                'Inteviul nu a putut fi programat. Va rugam reveniti.';
              if (err.error === 'Cannot schedule an interview in the past') {
                message =
                  'Va rugam selectati o data din viitor. Nu se pot programa inteviuri in trecut. Va multumim.';
              }
              if (
                err.error ===
                'There is already an interview scheduled for this medical staff and beneficiary'
              ) {
                message =
                  'Exista un interviu programat intre dumneavoastra si cadrul medical. Il puteti vizualiza in sectiunea de interviuri. Va multumim.';
              }
              this.reloadProfile.next();

              this.errors = message;
              this.formProposal.patchValue({ hours: null, raw: '', date: '' });
              this.loading = false;
              this.selectedDayModel = '';
            }
          );
      });
  }

  dismissToHome() {
    this.closeModal();
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.tokenSubscription$) {
      this.tokenSubscription$.unsubscribe();
    }
  }

  onSelectDay($event) {
    this.formProposal.patchValue({
      hours: null,
      raw: $event.detail.value.raw,
      date: '',
    });
    this.availableHours = $event.detail.value.hours;
  }

  onSelectHour($event) {
    this.formProposal.patchValue({ hours: $event.detail.value });
    console.log(this.formProposal.value);
  }
}
