<ion-card color="tertiary">
  <ion-card-header class="pb-0">
    <div
      class="
        d-flex d-flex-row
        ion-justify-content-between ion-align-items-center
      "
    >
      <ion-text class="font-18 font-bold color--primary"> Factură </ion-text>

      <ion-badge [color]="data.ui.statusVariant" class="w-50">
        {{ data.ui.statusText }}
      </ion-badge>
    </div>
  </ion-card-header>
  <ion-card-content>
    <ion-item style="--padding-start: 0" detail>
      <div style="display: flex; width: 100%">
        <ion-text style="flex-grow: 1"> {{ data.ui.serial }} </ion-text>
        <div class="font-bold" style="flex-grow: 1; text-align: right">
          {{ data.total }} {{ data.currency }}
        </div>
      </div>
    </ion-item>

    <div *ngIf="data.ui.hasPayError">
      Momentan această plată nu poate fi efectuată.
    </div>

    <ion-button
      *ngIf="data.ui.canBePaid"
      color="primary"
      expand="block"
      class="submit-button"
      (click)="onSubmit()"
    >
      Plătește
    </ion-button>
  </ion-card-content>
</ion-card>
