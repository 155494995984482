import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../models/invoice.model';
import { AbstractApiService } from './abstract-api.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public findAll(): Observable<Invoice[]> {
    return this.http
      .get<Invoice[]>(`${this.getBaseUrl()}/beneficiary/estimates/`)
      .pipe();
  }

  // TODO
  public payInvoice(): Observable<void> {
    return this.http.put<void>(`${this.getBaseUrl()}/invoices/`, {});
  }
}
