<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="profile/beneficiary"></ion-back-button>
    </ion-buttons>
    <ion-title>Hartă</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-map></app-map>
</ion-content>
