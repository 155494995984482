import {Injectable} from '@angular/core';

import {PatientApiService} from '../http/patient-api.service';
import {Observable} from 'rxjs';
import {Patient} from '../models/patient';
import {PatientAddress} from '../models/patient-address';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PatientsService {
  constructor(private patientsApiService: PatientApiService) {
  }

  /**
   * Get all patients from API
   *   *
   * @returns Observable
   */
  getAllPatients(): Observable<any> {
    return this.patientsApiService.getPatients();
  }

  /**
   * Get a patient by id
   *
   * @param patientId The patient id
   */
  getPatient(patientId: string): Observable<Patient> {
    return this.patientsApiService.getPatient(patientId).pipe(map(response => response[0]));
  }

  /**
   * Add patient to current logged in benefiary
   *
   * @param patient Patient model
   */
  addPatient(patient: Patient): Observable<any> {
    return this.patientsApiService.addPatient(patient);
  }

  /**
   * Add a patient address
   *
   * @param patientAddress PatientAddress
   * @param authToken Authentication token string
   */
  addPatientAddress(patientAddress: PatientAddress): Observable<any> {
    return this.patientsApiService.addPatientAddress(patientAddress);
  }

  /**
   * Get appointments
   *
   */
  getAppointments(): Observable<any> {
    return this.patientsApiService.getAppointments();
  }
}
