import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss'],
})
export class PopupMessageComponent {

  @Input() public message: string;

  constructor(
      public modalCtrl: ModalController
  ) {}

  closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
