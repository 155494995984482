import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent implements OnInit {

  public formAdaugaCard = new FormGroup({
    numarCard: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    numeTitular: new FormControl('', Validators.required),
    dataExpirare: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    CVV: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
  });

  public submited = false;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  submit() {
    if (this.formAdaugaCard.valid) {
      this.modalCtrl.dismiss(this.formAdaugaCard.value);
    }
  }

}
