import {Component, Input, OnInit} from '@angular/core';

export interface ListAvatarData {
  avatarName: string;
  text: string;
  link: string;
  avatarUrl?: string;
}

@Component({
  selector: 'app-list-avatar',
  templateUrl: './list-avatar.component.html',
  styleUrls: ['./list-avatar.component.scss'],
})
export class ListAvatarComponent implements OnInit {

  @Input() data: ListAvatarData[];

  private avatarUrl = 'https://eu.ui-avatars.com/api/?size=500&background=63bbff&font-size=0.55&bold=false&color=0B1865&format=svg&name=';

  constructor() {
  }

  ngOnInit() {
    this.data.forEach(data => {
      data.avatarUrl = this.avatarUrl + data.avatarName;
    });
  }
}
