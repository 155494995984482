import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'selecteddatepipe',
    pure: false
})
export class ProposeInterviewPipe implements PipeTransform {
    transform(items: {date: string, hours: {start: string, end: string}[]}[], filter: string): any {
        if (!items || !filter) {
            return items;
        }

        const found = items.find(item => item.date === filter);
        if (found) {
            return found.hours;
        }

        return [];
    }
}
