import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PopupCheckinoutComponent } from './popup-checkinout.component';

@Injectable({
  providedIn: 'root',
})
export class PopupCheckinoutService {
  private modal: HTMLIonModalElement;

  constructor(public modalController: ModalController) {}

  async presentModal(appointmentId, checkout = false) {
    this.modal = await this.modalController.create({
      component: PopupCheckinoutComponent,
      componentProps: {
        appointmentId,
        checkout,
      },
    });
    return await this.modal.present();
  }

  async closeModal() {
    if (this.modal) {
      this.modal.dismiss().then(() => {
        this.modal = null;
      });
    }
  }
}
