import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonTabs } from '@ionic/angular';

import {select, Store} from '@ngrx/store';

import {AccountType} from 'src/app/core/models';

import * as fromStore from '../../store';
import {BaseComponentDirective} from '../base.components';

@Component({
  selector: 'app-tabs',
  templateUrl: './app-tabs.component.html',
  styleUrls: ['./app-tabs.component.css']
})
export class AppTabsComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  public userType: AccountType;

  public currentUser$ = this.store.pipe(select(fromStore.selectCurrentUser));

  public profileLinks = {
    [AccountType.Beneficiary]: '/profile/beneficiary',
    [AccountType.Medical]: '/profile/medical-staff',
  };

  constructor(
    public router: Router,
    private store: Store<fromStore.State>,
  ) {
    super();
  }

  ngOnInit() {
  }

  refreshHome() {
    // load interviews
    // console.log('loadInterviews');
    // this.store.pipe(select(fromStore.selectToken))
    // .subscribe(authToken => {
    //   this.store.dispatch(fromStore.loadInterviews({ authToken }));
    // });
  }

  ngOnDestroy(){
    // console.log('IS NOT RESETING SUBSCRIBER');
  }
}
