import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Store, select} from '@ngrx/store';

import * as fromStore from '../../../store';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css']
})
export class BeneficiaryPersonalDataComponent implements OnInit {

  public personalDataForm: FormGroup = new FormGroup({
    lastName: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  public submited = false;

  public currentUser$;

  constructor(private store: Store<fromStore.State>) {
  }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(fromStore.selectCurrentUser)).pipe(tap(user => {
      this.personalDataForm.patchValue({
        lastName: user.lastName,
        firstName: user.firstName,
        phone: user.phoneNumber,
        email: user.email
      }, {emitEvent: false});
    }));
  }

  submit() {
  }
}
