import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

const getWidgetsState = createFeatureSelector<fromReducers.WidgetsState>('widgets');

export const selectInterviews = createSelector(
    getWidgetsState,
  (state: fromReducers.WidgetsState) => state.interviews,
);

export const selectInterviewsError = createSelector(
  getWidgetsState,
  (state: fromReducers.WidgetsState) => state.interviewsError,
);

export const selectInterviewsLoading = createSelector(
  getWidgetsState,
  (state: fromReducers.WidgetsState) => state.loading,
);
