import {Component, OnInit, OnDestroy} from '@angular/core';
import {Store, select} from '@ngrx/store';
import * as fromStore from '../../../store';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-medical-staff-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class MedicalStaffViewDocumentComponent implements OnInit, OnDestroy {

  private uuid: string;
  private type: string;

  private downloadedDocument$: Subscription;

  public document: Blob = null;
  public image: string | ArrayBuffer = null;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromStore.State>
  ) {
    // read route params
    this.route.params.subscribe(params => {
      this.uuid = params.uuid;
      this.type = params.type;

      // trigger download document
      this.store.pipe(select(fromStore.selectToken))
        .subscribe(authToken => {
          this.store.dispatch(fromStore.getDocument({uuid: this.uuid}));
        });
    });

    // when document is downloaded, get notified
    this.downloadedDocument$ = this.store.pipe(
      select(fromStore.selectDownloadedDocument),
    ).subscribe(content => {
      if (content === null) {
        return;
      }

      if (this.type === 'pdf') {
        this.document = content;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(content);
        reader.onloadend = () => {
          this.image = reader.result;
        };
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe from store
    this.downloadedDocument$.unsubscribe();

    // reset downloaded document
    this.store.dispatch(fromStore.resetDownloadedDocument());
  }
}
