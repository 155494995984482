import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BeneficiaryContractsComponent} from './routes/beneficiary-contracts/beneficiary-contracts.component';
import {MedicalStaffContractsComponent} from './routes/medical-staff-contracts/medical-staff-contracts.component';
import {BeneficiaryEstablishContractComponent} from './routes/beneficiary-establish-contract/beneficiary-establish-contract.component';


const routes: Routes = [
  {
    path: 'beneficiary',
    component: BeneficiaryContractsComponent
  },
  {
    path: 'interview/:interviewId/beneficiary/establish',
    component: BeneficiaryEstablishContractComponent
  },
  {
    path: 'medical-staff',
    component: MedicalStaffContractsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ContractsRoutingModule {
}
