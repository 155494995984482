<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button
        defaultHref="profile/beneficiary"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Date Personale</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form
    class="ion-padding"
    [formGroup]="personalDataForm"
    *ngIf="currentUser$ | async"
    class="full-form"
  >
    <div class="full-form-data">
      <ion-list>
        <ion-item lines="full">
          <ion-label position="stacked">Nume</ion-label>
          <ion-input formControlName="lastName" placeholder=""></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="stacked">Prenume</ion-label>
          <ion-input formControlName="firstName" placeholder=""></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="stacked">Telefon</ion-label>
          <ion-input
            formControlName="phone"
            placeholder=""
            type="tel"
          ></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="stacked">Email</ion-label>
          <ion-input
            formControlName="email"
            placeholder=""
            type="email"
          ></ion-input>
        </ion-item>
      </ion-list>
    </div>
    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      [disabled]="personalDataForm.invalid || !personalDataForm.dirty"
      (click)="submit()"
    >
      Salvează
    </ion-button>
  </form>
</ion-content>
