<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="scheduleForm" class="full-form">
    <div class="full-form-data">
      <ion-list>
        <ion-item>
          <ion-label position="stacked">Ziua</ion-label>
          <ion-input formControlName="day" type="text" placeholder="Alege o zi" (click)="openPicker()">
          </ion-input>
        </ion-item>

        <div formGroupName="availableTimeFrame">
          <ion-item>
            <ion-label position="stacked">Ora de început</ion-label>
            <ion-datetime formControlName="start" display-format="HH:mm A" picker-format="HH:mm"
                          placeholder="Alege ora">
            </ion-datetime>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Ora de încheiere</ion-label>
            <ion-datetime formControlName="end" display-format="HH:mm A" picker-format="HH:mm"
                          placeholder="Alege ora">
            </ion-datetime>
          </ion-item>
        </div>
      </ion-list>

      <ng-container *ngIf="scheduleForm.hasError('bkError')">
        <div class="errors">
          <p *ngFor="let err of scheduleForm.getError('bkError')">
            {{err}}
          </p>
        </div>
      </ng-container>
    </div>

    <ion-button class="submit-button" expand="block" color="secondary" (click)="onSave()"
                [disabled]="scheduleForm.disabled">
      <ng-container *ngIf="!scheduleForm.disabled">
        {{buttonLabel}}
      </ng-container>

      <ng-container *ngIf="scheduleForm.disabled">
        <ion-spinner></ion-spinner>
      </ng-container>
    </ion-button>
  </form>
</ion-content>
