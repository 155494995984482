<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="/home"></ion-back-button>
    </ion-buttons>
    <ion-title>Pacienți</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div *ngIf="(patients$ | async) as patients">
    <ion-list class="ion-no-margin ion-no-padding">
      <ion-item *ngFor="let patient of patients" lines="none" class="ion-item ion-align-items-center" color="tertiary"
                [routerLink]="patient.id">
        <ion-avatar slot="start">
          <img
            src="https://eu.ui-avatars.com/api/?background=3dc2ff&font-size=0.55&bold=false&color=0B1865&format=svg&name={{patient.firstName + ' ' + patient.lastName}}">
        </ion-avatar>

        <div class="item-content color--primary">
          <div class="font-20 ">
            {{patient.firstName + ' ' + patient.lastName}}
          </div>

          <div class="font-bold">
            <ng-container *ngIf="patient.age">
              {{patient.age}},
            </ng-container>
            <ng-container *ngIf="patient.weight">
              {{patient.weight + 'kg'}},
            </ng-container>
            <ng-container *ngIf="patient.height">
              {{patient.height + 'cm'}},
            </ng-container>
            <ng-container *ngIf="patient.gender">
              sex {{patient.gender | uppercase}}
            </ng-container>
          </div>
        </div>
      </ion-item>
    </ion-list>

    <div *ngIf="!patients.length">
      Nu aveți niciun pacient
    </div>
  </div>

  <ion-spinner *ngIf="loading$ | async" class="center-spinner"></ion-spinner>
</ion-content>
