<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button
        text=""
        defaultHref="profile/medical-staff"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Detalii Personale</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-spinner *ngIf="fetching$ | async" class="center-spinner"></ion-spinner>

  <ng-container *ngIf="personalDetails$ | async as personalDetails">
    <form [formGroup]="personalDetailsForm" class="full-form">
      <div class="full-form-data">
        <ion-list>
          <ion-item>
            <ion-label position="stacked">Data nașterii</ion-label>
            <ion-datetime
              formControlName="dateOfBirth"
              displayFormat="DD/MM/YYYY"
            >
            </ion-datetime>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Sex</ion-label>
            <ion-select formControlName="sex" okText="Alege" cancelText="Închide">
              <ion-select-option value="m">Masculin</ion-select-option>
              <ion-select-option value="f">Feminin</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item>
            <ion-label position="stacked">Profesia</ion-label>
            <ion-input formControlName="jobTitle"> </ion-input>
          </ion-item>
        </ion-list>

        <div class="errors" *ngIf="errors$ | async as errors">
          <ng-container *ngFor="let error of errors">
            {{ error }}
          </ng-container>
        </div>
      </div>

      <ion-button
        [disabled]="(updating$ | async) || personalDetailsForm.invalid"
        (click)="submit()"
        expand="block"
        color="secondary"
        class="submit-button"
      >
        <ng-container *ngIf="!(updating$ | async)">
          Salvează
        </ng-container>

        <ng-container *ngIf="updating$ | async">
          <ion-spinner></ion-spinner>
        </ng-container>
      </ion-button>
    </form>
  </ng-container>
</ion-content>
