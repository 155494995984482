<ion-header class="ion-no-border">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button
        text=""
        defaultHref="profile/medical-staff"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Program Orar</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top" color="primary" class="top-tab-bar">
      <ion-tab-button tab="schedule-edit">
        <ion-label>Orar</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="schedule-exception-edit">
        <ion-label>Întreruperi</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
