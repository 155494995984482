import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Contract, ContractsApiService, CreateContractReqBody} from '../../services/contracts/contracts-api.service';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryContractsFacadeService {

  private _contracts = new Subject<Contract[]>();
  public contracts$ = this._contracts.asObservable();

  private _selectedContract = new Subject<Contract>();
  public selectedContract$ = this._contracts.asObservable();

  constructor(
    private contractsApiService: ContractsApiService
  ) {
  }

  public dispatchLoadContracts() {
    this.contractsApiService.findAllBeneficiaryContracts().subscribe(response => {
      this._contracts.next(response);
    });
  }

  public dispatchLoadContract(contractId: string) {
    this.contractsApiService.findBeneficiaryContractsById(contractId).subscribe(response => {
      this._selectedContract.next(response);
    });
  }

  public dispatchCreateContract(beneficiaryId: string, interviewId: string, payload: CreateContractReqBody) {
    return this.contractsApiService.createContract(beneficiaryId, interviewId, payload);
  }
}
