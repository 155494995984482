<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă programare</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="form" *ngIf="!appointmentAdded" class="full-form">
    <div class="full-form-data">
      <ion-item>
        <ion-label position="stacked">Contract/Pacient</ion-label>
        <ion-select (ionChange)="onSelectContract($event)" okText="Alege" cancelText="Închide">
          <ion-select-option *ngFor="let contract of contracts" [value]="contract">
            {{ contract.patient.firstName }} {{ contract.patient.lastName }} -
            Ctr. {{ contract.contractId }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Serviciu</ion-label>
        <ion-select [disabled]="!selectedContract" (ionChange)="onSelectMedicalService($event)" multiple="true"
                    okText="Alege" cancelText="Închide">
          <ion-select-option *ngFor="let service of services" [value]="service.medicalServiceId">
            {{ service.medicalServiceName }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Ziua</ion-label>
        <ion-select [disabled]="!availableDays" (ionChange)="onSelectDay($event)" okText="Alege" cancelText="Închide">
          <ion-select-option *ngFor="let day of availableDays" [value]="day.raw">
            {{ day.displayDate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Ora</ion-label>
        <ion-select [disabled]="!availableDays" (ionChange)="onSelectHour($event)" okText="Alege" cancelText="Închide">
          <ion-select-option *ngFor="let hour of availableHours" [value]="hour.start">
            {{ hour.start }} - {{ hour.end }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item lines="none" class="item-textarea">
        <ion-label position="stacked" class="mb-1">Mențiuni</ion-label>
        <ion-textarea formControlName="comments" class="app-textarea" rows="5"></ion-textarea>
      </ion-item>

      <div *ngIf="errorMessage" class="errors">
        {{errorMessage}}
      </div>
    </div>

    <ion-button expand="block" color="secondary" class="submit-button" (click)="submitForm()" [disabled]="!form.valid || loading">
      <span *ngIf="!loading">Adaugă</span>
      <ion-spinner *ngIf="loading"></ion-spinner>
    </ion-button>
  </form>

  <div *ngIf="appointmentAdded" [@slideInOut]>
    <span class="success-message">
      Programarea pentru pacientul
      {{selectedContract.patient.firstName + ' ' + selectedContract.patient.lastName}}
      a fost adăugată în {{ getDisplayDate() }} la ora {{ selectedHour }}
    </span>
    <ion-button expand="block" class="submit-button" color="secondary" (click)="closeModal()">
      Înapoi
    </ion-button>
  </div>
</ion-content>
