import {Action, createReducer, on} from '@ngrx/store';

import {Patient} from '../../core/models/patient';

import * as fromPatientsActions from '../actions/patients.actions';

export interface PatientsState {
  patient: Patient[];
  singlePatient: any;
  patientsError: any;
  patientsLoading: boolean;
  beneficiaryAppointments: any;
  beneficiaryAppointmentsError: null;
  loading: boolean;
}

export const initialState: PatientsState = {
  patient: null,
  patientsError: null,
  patientsLoading: false,
  singlePatient: null,
  beneficiaryAppointments: null,
  beneficiaryAppointmentsError: null,
  loading: false
};

const patientsReducer = createReducer(
  initialState,

  on(fromPatientsActions.loadPatients, (state: PatientsState) => ({
    ...state,
    patientsLoading: true
  })),
  on(fromPatientsActions.loadPatientsSuccess, (state: PatientsState, payload) => ({
    ...state,
    patient: payload.patients,
    patientsLoading: false
  })),
  on(fromPatientsActions.loadPatientsFailed, (state: PatientsState, payload) => ({
    ...state,
    patientsError: payload.error,
    patientsLoading: false
  })),

  on(fromPatientsActions.addPatientSuccess, (state: PatientsState, payload) => ({
    ...state,
  })),
  on(fromPatientsActions.loadPatientSuccess, (state: PatientsState, payload) => ({
    ...state,
    singlePatient: payload.patient
  })),


  on(fromPatientsActions.loadBeneficiaryAppointments, (state: PatientsState, payload) => ({
    ...state,
    beneficiaryAppointmentsError: null,
    loading: true
  })),
  on(fromPatientsActions.loadBeneficiaryAppointmentsSuccess, (state: PatientsState, payload) => ({
    ...state,
    beneficiaryAppointments: payload.beneficiaryAppointments,
    loading: false
  })),
  on(fromPatientsActions.loadBeneficiaryAppointmentsFailed, (state: PatientsState, payload) => ({
    ...state,
    beneficiaryAppointmentsError: payload.error,
    loading: false
  })),
);

export function reducer(state: PatientsState | undefined, action: Action): PatientsState {
  return patientsReducer(state, action);
}

