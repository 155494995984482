import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {
  MedicalStaffScheduleExceptionApiService
} from '../../medical-staff-schedule-exception-api/medical-staff-schedule-exception-api.service';
import {
  ScheduleException,
  ScheduleExceptionCreateReqBody,
  ScheduleExceptionUpdateReqBody
} from '../../../core/models/medical-staff-schedule-exception';
import {OverlayEventDetail} from '@ionic/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MedicalStaffScheduleExceptionFacadeService {

  constructor(
      private scheduleExceptionApi: MedicalStaffScheduleExceptionApiService,
      private alertController: AlertController
  ) { }

  public fetchScheduleException(): Observable<ScheduleException[]> {
    return this.scheduleExceptionApi.findAll();
  }

  public createScheduleException(body: ScheduleExceptionCreateReqBody): Observable<void> {
    return this.scheduleExceptionApi.create(body);
  }

  public updateScheduleExceptionById(id: number, body: ScheduleExceptionUpdateReqBody): Observable<void> {
    return this.scheduleExceptionApi.update(id, body);
  }

  public deleteScheduleExceptionById(id: number): Observable<void> {
    return this.scheduleExceptionApi.delete(id);
  }

  public async openDeleteScheduleExceptionAlert(): Promise<OverlayEventDetail> {
    const alert = await this.alertController.create({
      header: 'Sterge intervalul',
      // subHeader: 'Subtitle',
      message: 'Esti sigur ca vrei sa stergi concediul?',
      buttons: [
        {
          text: 'Da',
          role: 'accept'
        },
        {
          text: 'Nu',
          role: 'cancel'
        }
      ]
    });

    await alert.present();

    return alert.onDidDismiss();
  }

  // @date - Date string format
  // @return - string format: Y-m-d H:i:s
  public adaptDateToScheduleExceptionApiFormat(date: string, isStart = true): string {
    const startDayHours = '00:00:01';
    const endDayHours = '23:59:59';
    const tempDate = new Date(date).toISOString().split('T')[0];

    return `${tempDate} ${isStart ? startDayHours : endDayHours}`;
  }
}
