<ion-content>
  <ng-container *ngIf="(scheduleDays$ | async) as scheduleDays else loading">
    <ng-container *ngIf="scheduleDays.length else zeroResults">
      <ion-list>
        <ion-card *ngFor="let weekday of scheduleDays" color="tertiary">
          <ion-card-header>
            <ion-text color="primary" class="ion-padding-start ion-color-primary font-18 font-bold">
              {{weekday.day}}
            </ion-text>
          </ion-card-header>

          <ion-card-content>
            <ion-list class="list-menu" lines="full">
              <ng-container *ngFor="let timeFrame of weekday.availableTimeFrame; let last = last"
                            (click)="onEdit(weekday.day, timeFrame)">
                <ion-item [lines]="last ? 'none' : 'full'">
                  <ion-label>
                    <div class=" font-20">
                      {{timeFrame.start}} - {{timeFrame.end}}
                    </div>
                  </ion-label>

                  <ion-icon name="create-outline" slot="end" color="primary" (click)="onEdit(weekday.day, timeFrame)">
                  </ion-icon>

                  <ion-icon name="trash" slot="end" color="primary" (click)="onDelete(timeFrame.scheduleId)">
                  </ion-icon>
                </ion-item>
              </ng-container>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </ng-container>


    <ion-fab [vertical]="scheduleDays.length ? 'bottom' : 'center'"
             [horizontal]="scheduleDays.length ? 'end' : 'center'" slot="fixed">
      <ion-fab-button (click)="onCreate()" color="secondary">
        <ion-icon name="add-outline" color="primary"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
</ion-content>

<ng-template #loading>
  <div class="ion-padding">
    <ion-spinner class="center-spinner"></ion-spinner>
  </div>
</ng-template>

<ng-template #zeroResults>
  <div class="ion-padding center-float-message">
    Momentan nu ai un orar setat.
  </div>
</ng-template>
