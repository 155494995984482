import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AbstractApiService } from './abstract-api.service';

@Injectable({ providedIn: 'root' })
export class InterviewsApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  joinInterview(authToken: string, interviewId): Observable<any>{
    return this.http.get(`${this.getBaseUrl()}/interviews/${interviewId}/join`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }).pipe(
      map(result => result),
      catchError(err => throwError(err)),
    );
  }

  getInterviews(): Observable<any>{
    return this.http.get(`${this.getBaseUrl()}/interviews`).pipe(
      map(result => result),
      catchError(err => throwError(err)),
    );
  }

}
