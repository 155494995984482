import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {GeoCodingService} from '../../../map/services/geocoding.service';
import * as fromStore from '../../../store';
import {debounceTime, filter, map, pluck, publishReplay, startWith} from 'rxjs/operators';
import {MedicalPersonelApiService} from '../../../core/http/medicalpersonel-api.service';
import {selectMedicalStaffServicesAreaError, selectMedicalStaffServicesAreaUpdating} from '../../../store';


@Component({
  selector: 'app-services-supply',
  templateUrl: './services-supply.component.html',
  styleUrls: ['./services-supply.component.css']
})
export class ServicesSupplyComponent implements OnInit {

  public serviceSupplyAreaForm: FormGroup;

  public serviceSupplyAreaFormValues$: Observable<any>;

  public radius$: Observable<number>;

  public updating$: Observable<boolean> = this.store.select(selectMedicalStaffServicesAreaUpdating);

  public errors$: Observable<any> = this.store.select(selectMedicalStaffServicesAreaError).pipe(
    map(errors => {
      return errors ? Object.keys(errors).map(error => errors[error]) : errors;
    })
  );

  public addressId = null;

  constructor(
    private store: Store<fromStore.State>,
    private geoCodingService: GeoCodingService,
    private medicalPersonelApiService: MedicalPersonelApiService
  ) {
  }

  ngOnInit() {
    this.buildForm();

    this.serviceSupplyAreaFormValues$ = this.serviceSupplyAreaForm.valueChanges.pipe(
      debounceTime(500),
      map(value => {
        return {
          city: value.city,
          country: value.country,
          district: value.district,
          street: value.street,
          streetNumber: value.streetNumber
        };
      })
    );

    this.radius$ = this.serviceSupplyAreaForm.controls.radius.valueChanges.pipe(
      startWith(1),
      map(value => parseInt(value, 10) * 1000)
    );

    this.medicalPersonelApiService.getAddress().pipe(
      pluck('addresses'),
      map(response => response[response.length - 1])
    ).subscribe(
      value => {
        this.addressId = value.id;
        return this.serviceSupplyAreaForm.setValue({
          city: value.city,
          country: value.country,
          district: value.district,
          radius: value.radius,
          street: value.street,
          streetNumber: value.streetNumber,
        });
      }
    );
  }

  private buildForm() {
    this.serviceSupplyAreaForm = new FormGroup({
      country: new FormControl('', []),
      district: new FormControl('', []),
      city: new FormControl('', []),
      street: new FormControl('', []),
      streetNumber: new FormControl('', []),
      radius: new FormControl('1', []),
    });
  }

  public submit() {
    this.geoCodingService
      .getCoordinates(`${this.serviceSupplyAreaForm.value.street} ${this.serviceSupplyAreaForm.value.streetNumber}, ${this.serviceSupplyAreaForm.value.city}, ${this.serviceSupplyAreaForm.value.country}`)
      .subscribe(res => {
        const markerPositions = res[0].geometry.location;

        const servicesArea: any = {
          country: this.serviceSupplyAreaForm.value.country,
          district: this.serviceSupplyAreaForm.value.district,
          city: this.serviceSupplyAreaForm.value.city,
          street: this.serviceSupplyAreaForm.value.street,
          streetNumber: this.serviceSupplyAreaForm.value.streetNumber,
          longitude: markerPositions.lng(),
          latitude: markerPositions.lat(),
          details: '',
          radius: this.serviceSupplyAreaForm.value.radius,
        };

        if (this.addressId) {
          servicesArea.id = this.addressId;
        }

        this.store.dispatch(fromStore.addAddress({
          servicesArea
        }));
      });
  }

}
