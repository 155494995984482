import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractApiService} from '../../../../core/http/abstract-api.service';
import {
  Anamnesis,
  Diagnostic,
  MedicalDocument,
  MedicalDocumentResponse,
  MedicalServiceGroup, MedicalStaffContractService,
  Patient,
  PatientAddress,
  PatientDiagnostic,
  PatientMedicalHistory,
  PatientMedicalRecord
} from '../../model/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public findAllPatients(): Observable<Patient[]> {
    return this.http.get<Patient[]>(`${this.getBaseUrl()}/patients`);
  }

  public findPatientById(patientId: string): Observable<Patient[]> {
    return this.http.get<Patient[]>(`${this.getBaseUrl()}/patients/${patientId}`);
  }

  public addPatient(payload: Patient): Observable<void> {
    return this.http.post<void>(
      `${this.getBaseUrl()}/patients`,
      {...payload}
    );
  }

  public updatePatient(payload: Patient): Observable<void> {
    return this.http.post<void>(
      `${this.getBaseUrl()}/patients/${payload.id}/update`,
      {...payload}
    );
  }

  public findPatientDiagnosticById(beneficiaryId: string, patientId: string): Observable<PatientDiagnostic> {
    return this.http.get<PatientDiagnostic>(`${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/diagnostic`);
  }

  public findPatientAddressesById(patientId: string): Observable<PatientAddress[]> {
    return this.http.get<PatientAddress[]>(`${this.getBaseUrl()}/patients/${patientId}/addresses`);
  }

  public findPatientAddressById(patientId: string, addressId: string): Observable<PatientAddress[]> {
    return this.http.get<PatientAddress[]>(`${this.getBaseUrl()}/patients/${patientId}/addresses/${addressId}`);
  }

  public addPatientAddress(patientId, payload: PatientAddress): Observable<void> {
    return this.http.post<void>(
      `${this.getBaseUrl()}/patients/${patientId}/addresses`,
      {...payload}
    );
  }

  public updatePatientAddress(patientId: string, addressId: string, payload: PatientAddress): Observable<void> {
    return this.http.post<void>(
      `${this.getBaseUrl()}/patients/${patientId}/addresses/${addressId}/update`,
      {...payload}
    );
  }

  public findMedicalServices(): Observable<MedicalServiceGroup[]> {
    return this.http.get<MedicalServiceGroup[]>(`${this.getBaseUrl()}/medical-services`);
  }

  public findMedicalServicesByMedicalStaffId(medicalStaffId: string): Observable<MedicalStaffContractService[]> {
    return this.http.get<MedicalStaffContractService[]>(`${this.getBaseUrl()}/medical-services/${medicalStaffId}`);
  }

  public findPatientMedicalRecord(beneficiaryId: string, patientId: string): Observable<PatientMedicalRecord> {
    return this.http.get<PatientMedicalRecord>(
      `${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/medical-record`
    );
  }

  public createOrUpdateAnamnesis(beneficiaryId: string, patientId: string, payload: Anamnesis): Observable<Anamnesis> {
    return this.http.post<Anamnesis>(`${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/anamnesis`, {...payload});
  }

  public findMedicalHistoryByPatientId(beneficiaryId: string, patientId: string): Observable<Diagnostic[]> {
    return this.http.get<Diagnostic[]>(
      `${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/medical-history`
    );
  }

  public addMedicalHistory(beneficiaryId: string, patientId: string, payload: PatientMedicalHistory): Observable<PatientMedicalHistory> {
    return this.http.post<PatientMedicalHistory>(
      `${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/medical-history`,
      {...payload}
    );
  }

  public addMedicalDocument(beneficiaryId: string, patientId: string, payload: MedicalDocument): Observable<MedicalDocumentResponse> {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('description', payload.description);
    formData.append('documentType', payload.documentType);

    return this.http.post<MedicalDocumentResponse>(
      `${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/documents`,
      formData
    );
  }

  public findAllMedicalDocumentsByPatientId(beneficiaryId: string, patientId: string): Observable<MedicalDocument[]> {
    return this.http.get<MedicalDocument[]>(`${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/documents`);
  }

  public downloadDocumentByDocumentId(beneficiaryId: string, patientId: string, documentId: string): Observable<any> {
    const url = `${this.getBaseUrl()}/beneficiary/${beneficiaryId}/patients/${patientId}/documents/${documentId}`;
    const options = {responseType: 'blob' as const};
    // @ts-ignore
    return this.http.get<any>(url, options);
  }
}
