<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Contracte</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="(contracts$ | async) as contracts">
    <ng-container *ngIf="contracts.length else zeroRecords">
      <medical-staff-contract
        (viewContract)="handleViewContract($event)" [data]="contracts">
      </medical-staff-contract>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="loading$ | async">
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="(error$ | async) as error">
    <div class="ion-padding">
      {{error.message}}
    </div>
  </ng-container>
</ion-content>

<ng-template #zeroRecords>
  <div class="ion-padding">
    Nu am găsit contracte
  </div>
</ng-template>

<ion-footer style="height: 56px;">
  <app-tabs></app-tabs>
</ion-footer>
