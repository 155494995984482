import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Subscription } from 'rxjs';
import { MedicalStaffApiService } from 'src/app/services/medical-staff-api/medical-staff-api.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';

@Component({
  selector: 'app-popup-checkinout',
  templateUrl: './popup-checkinout.component.html',
  styleUrls: ['./popup-checkinout.component.scss'],
})
export class PopupCheckinoutComponent implements OnInit, OnDestroy {
  @Input() public appointmentId: string;
  @Input() public checkout: string;

  public loading = true;
  public locationError = false;
  public location: any = null;

  public response: string = null;
  public responseError = false;

  private watchGeo: Subscription = null;
  private checkinSubscription: Subscription = null;

  constructor(
    private store: Store<fromStore.State>,
    private modalCtrl: ModalController,
    private geolocation: Geolocation,
    private medicalStaffApiService: MedicalStaffApiService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.loading = false;

    this.watchGeo = this.geolocation.watchPosition().subscribe((data: any) => {
      this.zone.run(() => {
        // console.log('watch - ', data);
        // location received
        if (data.coords) {
          // console.log('data.coords exists');
          if (!this.location) {
            this.location = data.coords;
            this.locationError = false;
            // console.log('docall - ', data);
            this.doCall();
          }
        } else {
          // console.log('data.coords not exists - error');
          this.locationError = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.watchGeo) {
      this.watchGeo.unsubscribe();
    }
    if (this.checkinSubscription) {
      this.checkinSubscription.unsubscribe();
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  doFinish() {
    this.store.dispatch(fromStore.loadMedicalStaffAppointments());
    this.dismissModal();
  }

  findLocation() {
    this.locationError = false;
    this.geolocation
      .getCurrentPosition({ timeout: 3000 })
      .then((resp) => {
        this.zone.run(() => {
          // resp.coords.latitude
          // resp.coords.longitude
          if (!this.location) {
            this.location = resp.coords;
            //  console.log('findLocation', resp);
            this.locationError = false;
            this.doCall();
          }
        });
      })
      .catch((error) => {
        this.zone.run(() => {
          this.locationError = true;
          //  console.log('findLocation Error getting location', error);
        });
      });
  }

  private doCall() {
    if (this.checkout) {
      this.sendCheckout();
    } else {
      this.sendCheckin();
    }
  }

  private sendCheckin() {
    if (this.loading) {
      return;
    }

    const data = {
      latitude: this.location.latitude,
      longitude: this.location.longitude,
    };

    this.loading = true;
    this.checkinSubscription = this.medicalStaffApiService
      .sendCheckin(data, this.appointmentId)
      .subscribe(
        (response) => {
          //  console.log('response', response);
          this.loading = false;
          this.response = 'Acțiunea a fost înregistrată cu succes.';
        },
        (error) => {
          //  console.log('error', error);
          this.loading = false;
          this.response =
            'Acțiunea nu a fost înregistrată. Vă rugăm reîncercati';
          this.responseError = true;
        }
      );
  }

  private sendCheckout() {
    if (this.loading) {
      return;
    }

    const data = {
      latitude: this.location.latitude,
      longitude: this.location.longitude,
    };

    this.loading = true;
    this.checkinSubscription = this.medicalStaffApiService
      .sendCheckout(data, this.appointmentId)
      .subscribe(
        (response) => {
          //  console.log('response', response);
          this.loading = false;
          this.response = 'Acțiunea a fost înregistrată cu succes.';
        },
        (error) => {
          //  console.log('error', error);
          this.loading = false;
          this.response =
            'Acțiunea nu a fost înregistrată. Vă rugăm reîncercati';
          this.responseError = true;
        }
      );
  }
}
