import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';

import {of, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private hasToken = false;

  constructor(
    private router: Router,
    private store: Store<fromStore.State>
  ) {
  }

  canActivate(): Observable<boolean> {
    this.store.pipe(
      select(fromStore.selectToken)
    ).subscribe(res => {
      if (res !== null) {
        this.hasToken = true;
      } else {
        this.router.navigate(['/auth/logout']);
      }
    });

    return of(this.hasToken);
  }
}
