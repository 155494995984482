import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/core/models/invoice.model';
import {
  selectAllBeneficiaryInvoices,
  selectAllBeneficiaryInvoicesError,
  selectAllBeneficiaryInvoicesLoading,
} from 'src/app/store/selectors/beneficiary-invoice.selectors';
import * as fromStore from '../../../../store';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-receipt-history',
  templateUrl: './receipt-history.component.html',
  styleUrls: ['./receipt-history.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
  providers: [InAppBrowser],
})
export class ReceiptHistoryComponent implements OnInit {
  public invoices$: Observable<Invoice[]> = this.store.select(
    selectAllBeneficiaryInvoices
  );

  public loading$: Observable<boolean> = this.store.select(
    selectAllBeneficiaryInvoicesLoading
  );

  public error$: Observable<any> = this.store.select(
    selectAllBeneficiaryInvoicesError
  );

  constructor(
    private store: Store<fromStore.State>,
    private iab: InAppBrowser
  ) {}

  ngOnInit() {
    this.store.dispatch(fromStore.loadBeneficiaryInvoices());
  }

  public jumpToPayWindow(invoice: Invoice) {
    const browser = this.iab.create(invoice.paymentUrl, '_self');
  }
}
