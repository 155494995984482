import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

import * as contractsActions from '../actions/contracts.actions';
import {ContractsApiService} from '../../pages/contracts/services/contracts/contracts-api.service';

@Injectable()
export class ContractsEffects {
  loadContracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contractsActions.loadContracts),
      exhaustMap(({isBeneficiary}) => {
        if (isBeneficiary) {
          return this.contractsService.findAllBeneficiaryContracts().pipe(
            map(contracts => contractsActions.loadContractsSuccess({contracts})),
            catchError((error) => of(contractsActions.loadContractsFailed(error)))
          );
        } else {
          return this.contractsService.findAllMedicalStaffContracts().pipe(
            map(contracts => contractsActions.loadContractsSuccess({contracts})),
            catchError((error) => of(contractsActions.loadContractsFailed(error)))
          );
        }
      })
    )
  );

  constructor(private actions$: Actions, private contractsService: ContractsApiService) {
  }
}
