<ion-card *ngFor="let contract of data" (click)="onViewContract(contract)" color="tertiary">
  <ion-card-header class="pb-0">
    <div class="d-flex d-flex-row ion-justify-content-between ion-align-items-center">
      <ion-text class="font-18 font-bold color--primary">
        Contract - {{contract.contractId}}
      </ion-text>

      <ion-badge [color]="contract.ui.variant" class="w-50">
        {{contract.ui.statusTranslate}}
      </ion-badge>
    </div>
  </ion-card-header>

  <ion-card-content>
    <ion-list class="list-menu font-18" lines="full">
      <ion-item>
        <ion-text>
          Pacient
        </ion-text>
        <div slot="end" class="font-bold">
          {{contract.patient.firstName}}
          {{contract.patient.lastName}}
        </div>
      </ion-item>
      <ion-item lines="none">
        <div>
          Beneficiar
        </div>
        <div slot="end" class="font-bold">
          {{contract.beneficiary.firstName}}
          {{contract.beneficiary.lastName}}
        </div>
      </ion-item>
    </ion-list>

    <ion-button color="primary" expand="block">
      Vezi tot contractul
    </ion-button>
  </ion-card-content>
</ion-card>
