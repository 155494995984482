import { Action, createReducer, on } from '@ngrx/store';

import * as fromMedicalReportsActions from '../actions/medical-report.actions';
import { MedicalReport } from '../../core/http/medical-report-api.service';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface MedicalReportState extends EntityState<MedicalReport> {
  selectedMedicalReport: {
    data: MedicalReport;
    loading: boolean;
    error: any;
  };
  loading: boolean;
  error: any;
}

export const medicalReportAdapter: EntityAdapter<MedicalReport> =
  createEntityAdapter<MedicalReport>({});

export const initialState: MedicalReportState =
  medicalReportAdapter.getInitialState({
    selectedMedicalReport: {
      data: null,
      loading: false,
      error: null,
    },
    loading: false,
    error: null,
  });

const medicalReportReducer = createReducer(
  initialState,

  // fetch all
  on(
    fromMedicalReportsActions.loadMedicalReports,
    (state: MedicalReportState) => ({
      ...state,
      error: null,
      loading: true,
    })
  ),
  on(
    fromMedicalReportsActions.loadMedicalReportsSuccess,
    (state: MedicalReportState, { medicalReports }) =>
      medicalReportAdapter.setAll(medicalReports, {
        ...state,
        loading: false,
      })
  ),
  on(
    fromMedicalReportsActions.loadMedicalReportsFailed,
    (state: MedicalReportState, payload) => ({
      ...state,
      error: payload.error,
      loading: false,
    })
  ),

  on(
    fromMedicalReportsActions.setCurrentMedicalReport,
    (state: MedicalReportState, { report }) => ({
      ...state,
      selectedMedicalReport: {
        ...state.selectedMedicalReport,
        data: report,
      },
    })
  ),

  // fetch by id
  on(
    fromMedicalReportsActions.loadMedicalReport,
    (state: MedicalReportState) => ({
      ...state,
      selectedMedicalReport: {
        ...state.selectedMedicalReport,
        data: null,
        loading: true,
        error: null,
      },
    })
  ),
  on(
    fromMedicalReportsActions.loadMedicalReportSuccess,
    (state: MedicalReportState, payload) => ({
      ...state,
      selectedMedicalReport: {
        ...state.selectedMedicalReport,
        data: payload.medicalReport,
        loading: false,
      },
    })
  ),
  on(
    fromMedicalReportsActions.loadMedicalReportFailed,
    (state: MedicalReportState, payload) => ({
      ...state,
      selectedMedicalReport: {
        ...state.selectedMedicalReport,
        loading: false,
        error: payload.error,
      },
    })
  ),

  // approve medical report
  on(
    fromMedicalReportsActions.approveMedicalReport,
    (state: MedicalReportState) => ({
      ...state,
    })
  ),
  on(
    fromMedicalReportsActions.approveMedicalReportSuccess,
    (state: MedicalReportState, payload) => ({
      ...state,
      selectedMedicalReport: {
        ...state.selectedMedicalReport,
        data: {
          ...state.selectedMedicalReport.data,
          statusId: 2,
          ui: {
            ...state.selectedMedicalReport.data.ui,
            message: 'Aprobat',
            variant: 'success',
          },
        },
      },
    })
  ),
  on(
    fromMedicalReportsActions.approveMedicalReportFailed,
    (state: MedicalReportState, payload) => ({
      ...state,
    })
  )
);

export function reducer(
  state: MedicalReportState | undefined,
  action: Action
): MedicalReportState {
  return medicalReportReducer(state, action);
}
