import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {select, Store} from '@ngrx/store';

import * as fromStore from '../../../store';

import {AddListingComponent} from './add-listing/add-listing.component';
import {OverlayEventDetail} from '@ionic/core';
import {filter, map, pluck} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MedicalService} from '../../../core/models/medical-staff-service';
import {ToastService} from '../../../core/services/toast.service';

@Component({
  selector: 'app-medical-staff-listing',
  templateUrl: './medical-staff-listing.component.html',
  styleUrls: ['./medical-staff-listing.component.scss'],
})
export class MedicalStaffListingComponent implements OnInit {
  public medicalServices$: Observable<MedicalService[]> = this.store.pipe(
    select(fromStore.selectMedicalServices),
    filter(services => !!services),
    pluck('medicalServices'),
    map(medicalServicesGroups => medicalServicesGroups
      .map(({services}) => services)
      .reduce((acc, curr) => {
        curr.forEach(c => acc.push(c));
        return acc;
      }, [])
    )
  );

  public fetching$: Observable<boolean> = this.store.pipe(
    select(fromStore.selectMedicalServicesFetching)
  );

  constructor(
    public modalController: ModalController,
    private store: Store<fromStore.State>,
    private alertController: AlertController
  ) {
  }

  ngOnInit() {
    this.store.dispatch(fromStore.getServices());
  }

  async addMedicalService() {
    const modal = await this.modalController.create({
      component: AddListingComponent,
    });
    return await modal.present();
  }

  public async removeMedicalService(medicalServiceId: string) {
    const {role} = await this.presentConfirm();

    if (role === 'accept') {
      this.store.dispatch(fromStore.deleteService({medicalServiceId}));
    }
  }

  private async presentConfirm(): Promise<OverlayEventDetail> {
    const alert = await this.alertController.create({
      header: 'Sterge serviciul',
      message: 'Esti sigur ca vrei sa stergi serviciul?',
      buttons: [
        {
          text: 'Da',
          role: 'accept',
        },
        {
          text: 'Nu',
          role: 'cancel',
        },
      ],
    });

    await alert.present();

    return alert.onDidDismiss();
  }
}
