import {Injectable} from '@angular/core';
import {MedicalStaffScheduleApiService} from '../../medical-staff-schedule-api/medical-staff-schedule-api.service';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {
  ScheduleDay,
  ScheduleDayCreateReqBody,
  ScheduleDaysResponse,
  ScheduleDayUpdateReqBody,
  ScheduleTimeFrame,
  WeekDaysEnum,
  WeekDaysMapping
} from '../../../core/models/medical-staff-schedule';


@Injectable({
  providedIn: 'root'
})
export class MedicalStaffScheduleEditFacadeService {

  constructor(
    private scheduleApiService: MedicalStaffScheduleApiService
  ) {
  }

  public fetchSchedule(): Observable<ScheduleDay[]> {
    return this.scheduleApiService.findAll().pipe(
      pluck(0),
      map((response: ScheduleDaysResponse) => {
        response.days.forEach(day => {
          day.day = WeekDaysMapping[day.day];
        });
        return response;
      }),
      map((response: ScheduleDaysResponse) => {
        response.days.sort((a: ScheduleDay, b: ScheduleDay) => {
          return WeekDaysEnum[a.day] - WeekDaysEnum[b.day];
        });
        response.days.forEach(day => {
          day.availableTimeFrame.sort((a: ScheduleTimeFrame, b: ScheduleTimeFrame) => {
            return this.scheduleTimeToTime(a.start) - this.scheduleTimeToTime(b.start);
          });
        });
        return response.days;
      })
    );
  }

  public createSchedule(body: ScheduleDayCreateReqBody) {
    return this.scheduleApiService.create(body);
  }

  public updateScheduleById(id: number, body: ScheduleDayUpdateReqBody) {
    return this.scheduleApiService.update(id, body);
  }

  public deleteScheduleById(timeFrameId: number): Observable<void> {
    return this.scheduleApiService.delete(timeFrameId);
  }

  public scheduleTimeToISOString(date: string): string {
    const today = new Date();
    const hours = date.split(':').map(v => parseInt(v, 10));
    const [h, m, s] = hours;
    today.setHours(h, m, s);
    return today.toISOString();
  }

  public scheduleTimeToTime(date: string): number {
    const today = new Date();
    const hours = date.split(':').map(v => parseInt(v, 10));
    const [h, m, s] = hours;
    today.setHours(h, m, s);
    return today.getTime();
  }
}
