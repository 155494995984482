import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeneficiaryContractsComponent} from './routes/beneficiary-contracts/beneficiary-contracts.component';
import {MedicalStaffContractsComponent} from './routes/medical-staff-contracts/medical-staff-contracts.component';
import {ContractsRoutingModule} from './contracts-routing.module';
import {IonicModule} from '@ionic/angular';
import {BeneficiaryEstablishContractComponent} from './routes/beneficiary-establish-contract/beneficiary-establish-contract.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AppTabsModule} from '../../components/app-tabs/app-tabs.module';
import {BeneficiaryContractComponent} from './components/beneficiary-contract/beneficiary-contract.component';
import {MedicalStaffContractComponent} from './components/medical-staff-contract/medical-staff-contract.component';
import {ContractDetailsModalComponent} from './modals/contract-details-modal/contract-details-modal.component';

@NgModule({
  declarations: [
    BeneficiaryContractComponent,
    BeneficiaryContractsComponent,
    MedicalStaffContractsComponent,
    BeneficiaryEstablishContractComponent,
    MedicalStaffContractComponent,
    ContractDetailsModalComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    ContractsRoutingModule,
    IonicModule,
    ReactiveFormsModule,
    AppTabsModule
  ]
})
export class ContractsModule {
}
