<ion-content fullscreen class="call-content" scroll-x="false">
  <div id="peerView"></div>
  <div id="userView"></div>

  <div class="profile-info">
    <div class="profile-holder">
      <img
        src="https://eu.ui-avatars.com/api/?size=500&background=3dc2ff&font-size=0.55&bold=false&color=0B1865&format=svg&name={{
          peerName
        }}"
        class="avatar-img"
      />
    </div>
    <span class="peer-name">{{ peerName }}</span>
    <span class="call-status">{{ callStatus }}</span>
  </div>

  <div class="buttons">
    <div class="option-holder">
      <ion-button
        icon-only
        shape="round"
        color="primary"
        (click)="toggleVideo()"
        *ngIf="!videoDisabled"
      >
        <ion-icon name="videocam" color="light"></ion-icon>
      </ion-button>
      <ion-button
        icon-only
        shape="round"
        color="primary"
        (click)="toggleVideo()"
        class="video-disabled"
        *ngIf="videoDisabled"
      >
        <ion-icon name="videocam" color="light"></ion-icon>
      </ion-button>
    </div>

    <div class="option-holder">
      <!-- Somehow only ngif works -->
      <ion-button
        icon-only
        shape="round"
        color="primary"
        (click)="toggleAudio()"
        *ngIf="!audioDisabled"
      >
        <ion-icon name="mic" color="light"></ion-icon>
      </ion-button>
      <ion-button
        icon-only
        shape="round"
        color="primary"
        (click)="toggleAudio()"
        class="audio-disabled"
        *ngIf="audioDisabled"
      >
        <ion-icon name="mic" color="light"></ion-icon>
      </ion-button>
    </div>

    <div class="option-holder">
      <ion-button
        icon-only
        shape="round"
        class="end-call"
        (click)="closeCall()"
      >
        <ion-icon name="call" color="light"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-content>
