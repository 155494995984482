<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă raport medical</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form class="full-form" style="padding: 20px" [formGroup]="medicalReportForm">
    <div class="full-form-data">
      <ion-textarea
        placeholder="Adăugați aici informațiile..."
        class="app-textarea"
        enterkeyhint="enter"
        rows="20"
        formControlName="description"
      ></ion-textarea>
      <div style="padding-top: 20px">
        <span *ngFor="let error of errors" class="message-errors">{{
          error
        }}</span>
      </div>
    </div>
    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      [disabled]="!medicalReportForm.valid"
      (click)="doSubmit()"
    >
      <span *ngIf="!medicalReportForm.disabled">Trimite raport</span>
      <ion-spinner *ngIf="medicalReportForm.disabled"></ion-spinner>
    </ion-button>
  </form>
</ion-content>
