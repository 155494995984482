import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

const getAuthFeatureState = createFeatureSelector<fromReducers.AuthState>('auth');

export const selectLoggedIn = createSelector(
  getAuthFeatureState,
  (state: fromReducers.AuthState) => state.login,
);

export const selectToken = createSelector(
  getAuthFeatureState,
  (state: fromReducers.AuthState) => state.token,
);

export const selectRegistered = createSelector(
  getAuthFeatureState,
  (state: fromReducers.AuthState) => state.register,
);

export const selectVerifiedAccount = createSelector(
  getAuthFeatureState,
  (state: fromReducers.AuthState) => state.verified,
);

export const selectCurrentUser = createSelector(
  getAuthFeatureState,
  (state: fromReducers.AuthState) => state.currentUser,
);
