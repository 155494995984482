import {Action, createReducer, on} from '@ngrx/store';


import * as fromWidgetsActions from '../actions/widgets.actions';

export interface WidgetsState {
  interviews: any;
  interviewsError: any;
  loading: boolean;
}

export const initialState: WidgetsState = {
  interviews: null,
  interviewsError: null,
  loading: false
};

const WidgetsStatusReducer = createReducer(
  initialState,
  on(fromWidgetsActions.loadInterviews, (state: WidgetsState) => {
    return ({
      ...state,
      interviewsError: null,
      interviews: null,
      loading: true
    });
  }),
  on(fromWidgetsActions.loadInterviewsStatusComplete, (state: WidgetsState, payload) => {
    return ({
      ...state,
      interviews: payload.response,
      loading: false
    });
  }),
  on(fromWidgetsActions.loadInterviewsStatusFailed, (state: WidgetsState, payload) => {
    return ({
      ...state,
      interviewsError: payload,
      loading: false
    });
  }),
);

export function reducer(state: WidgetsState | undefined, action: Action): WidgetsState {
  return WidgetsStatusReducer(state, action);
}

