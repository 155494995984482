import {Injectable} from '@angular/core';

import {ofType, Actions, createEffect} from '@ngrx/effects';

import {of} from 'rxjs';
import {exhaustMap, map, catchError} from 'rxjs/operators';

// Services
import {InterviewsApiService} from 'src/app/core/http/interview-api.service';
import {InterviewData} from 'src/app/services/interview-data.service';

// Actions
import * as widgetsActions from '../actions/widgets.actions';

@Injectable()
export class WidgetsEffects {
  loadInterviews$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(widgetsActions.loadInterviews),
        exhaustMap(() => {
          return this.interviewsApiService.getInterviews().pipe(
            map(response => {
              const responseData = this.interviewData.normalize(response);
              return widgetsActions.loadInterviewsStatusComplete({response: responseData});
            }),
            catchError((error) => {
              return of(widgetsActions.loadInterviewsStatusFailed(error.error));
            })
          );
        })
      );
    }
  );

  constructor(
    private actions$: Actions,
    private interviewsApiService: InterviewsApiService,
    private interviewData: InterviewData,
  ) {
  }
}
