import { Injectable } from '@angular/core';

import { ofType, Actions, createEffect } from '@ngrx/effects';

import { of } from 'rxjs';
import { exhaustMap, map, mergeMap, catchError, tap } from 'rxjs/operators';

// Services
import { ApiProfileStatusService } from 'src/app/core/services/api-profileStatus.service';

// Actions
import * as profileStatusActions from '../actions/profileStatus.actions';

@Injectable()
export class ProfileStatusEffects {
  loadProfileStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileStatusActions.loadProfileStatus),
      exhaustMap(data =>
        this.apiProfileStatusService.getProfileStatus(data.authToken).pipe(
          map(response => {
              console.log('LOG PROFILE STATUS', response);
              return profileStatusActions.loadProfileStatusComplete({ response });
          }),
          catchError((error) => {
            console.log('error', error);
            return of(profileStatusActions.loadProfileStatusFailed(error));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiProfileStatusService: ApiProfileStatusService) {}
}
