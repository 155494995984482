import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { distinctUntilChanged, filter, map, pluck, tap } from 'rxjs/operators';
import { WidgetConfig } from '../ui/widget/widget.component';
import { ListAvatarData } from '../ui/list-avatar/list-avatar.component';

@Component({
  selector: 'app-widget-pacienti',
  templateUrl: './pacienti.component.html',
  styleUrls: ['./pacienti.component.scss'],
})
export class PatientsWidgetComponent implements OnInit {
  public patients$: Observable<ListAvatarData[]>;

  public isBeneficiary: boolean;

  public loading$: Observable<boolean>;

  public error$: Observable<any>;

  public widgetsConfig: WidgetConfig = {
    title: 'Pacienți',
    viewAllLinkText: 'Vezi toți',
    viewAllLink: '',
    variant: 'tertiary',
    contentHasPadding: true,
  };

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store
      .pipe(
        select(fromStore.selectCurrentUser),
        pluck('accountType'),
        filter((value) => value !== null),
        distinctUntilChanged(),
        map((accountType) => {
          return +accountType === 1;
        }),
        tap((isBeneficiary) => {
          this.widgetsConfig.viewAllLink = isBeneficiary
            ? '/patients'
            : '/medical-staff/patients';
        })
      )
      .subscribe((isBeneficiary) => {
        isBeneficiary
          ? this.loadBeneficiaryPatients()
          : this.loadMedicalStaffPatients();
        this.isBeneficiary = isBeneficiary;
      });
  }

  private loadMedicalStaffPatients() {
    this.loading$ = this.store.pipe(
      select(fromStore.selectMedicalStaffPacientsLoading)
    );

    this.error$ = this.store.pipe(
      select(fromStore.selectMedicalStaffPatientsError)
    );

    this.store.dispatch(fromStore.loadMedicalStaffPatients());

    this.patients$ = this.store.pipe(
      select(fromStore.selectMedicalStaffPacients),
      map((response) => (response ? response.slice(0, 3) : response)),
      filter((response) => !!response),
      map((patients) =>
        patients.map((patient) => ({
          avatarName: `${patient.firstName} ${patient.lastName}`,
          text: `${patient.firstName} ${patient.lastName}`,
          link: this.isBeneficiary
            ? `/patients/${patient.id}/edit`
            : `/medical-staff/patients/${patient.id}/details`,
        }))
      )
    );
  }

  private loadBeneficiaryPatients() {
    this.loading$ = this.store.pipe(
      select(fromStore.selectWidgetPatientsLoading)
    );

    this.error$ = this.store.pipe(select(fromStore.selectWidgetPatientsError));

    this.store.dispatch(fromStore.loadPatients());

    this.patients$ = this.store.pipe(
      select(fromStore.selectWidgetPatients),
      map((response) => (response ? response.slice(0, 3) : response)),
      filter((response) => !!response),
      map((patients) =>
        patients.map((patient) => ({
          avatarName: `${patient.firstName} ${patient.lastName}`,
          text: `${patient.firstName} ${patient.lastName}`,
          link: this.isBeneficiary
            ? `/patients/${patient.id}/edit`
            : `/medical-staff/view-patient/${patient.id}`,
        }))
      )
    );
  }
}
