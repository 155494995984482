import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { User } from 'src/app/core/models';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { BackdropMessageService } from 'src/app/services/backdrop-message/backdrop-message.service';
import { InterviewsApiService } from 'src/app/core/http/interview-api.service';
import { Subscription } from 'rxjs';

declare var AudioToggle;
declare var OT;
declare var cordova;

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css'],
})
export class InterviewComponent implements OnInit, OnDestroy {
  public currentUser$: User;

  public session;
  public token: string;
  public interviewId: number;
  public peerName: string;

  public publisher = null;
  public peerSubscriber = null;

  public audioDisabled = false;
  public videoDisabled = false;

  public callStatus = 'Apel video in curs...';

  private currentUserSubscription$: Subscription;
  private tokenSubscription$: Subscription;

  private authToken: string;

  constructor(
    public navCtrl: NavController,
    private route: ActivatedRoute,
    public alertController: AlertController,
    private store: Store<fromStore.State>,
    private permissionsService: PermissionsService,
    private androidPermissions: AndroidPermissions,
    private platform: Platform,
    private backdropMessageService: BackdropMessageService,
    private interviewsApiService: InterviewsApiService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.interviewId = parseInt(params.get('id'), 10);
      this.peerName = params.get('peerName');
    });

    this.tokenSubscription$ = this.store
      .pipe(select(fromStore.selectToken))
      .subscribe((authToken) => {
        if (!authToken) {
          return;
        }
        this.authToken = authToken;

        this.currentUserSubscription$ = this.store
          .pipe(select(fromStore.selectCurrentUser))
          .subscribe(async (user) => {
            this.currentUser$ = user;
            if (this.platform.is('android')) {
              try {
                await this.permissionsService.checkPermissions([
                  this.androidPermissions.PERMISSION.CAMERA,
                  this.androidPermissions.PERMISSION.RECORD_AUDIO,
                  this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS,
                ]);
                // user is being called and the answer screen is displayed
                this.initSession();
              } catch (err) {
                if (err.hasOwnProperty('errorMessage')) {
                  await this.backdropMessageService.presentModal(
                    err.errorMessage
                  );
                }
              }
            } else {
              // user is being called and the answer screen is displayed
              this.initSession();
            }
          });
      });

    // keep screen awake
    /* tslint:disable */
    if (this.platform.is('cordova') && window['plugins'].insomnia) {
      window['plugins'].insomnia.keepAwake();
    }
    /* tslint:enabled */
  }

  async closeCall() {
    const alert = await this.alertController.create({
      cssClass: 'alert',
      header: 'Atentie',
      message:
        'Sunteti sigur ca doriti sa iesiti din ecranul de interviu? Acest lucru va duce la inchiderea conversatiei.',
      buttons: [
        {
          text: 'Anuleaza',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
          },
        },
        {
          text: 'Confirm',
          handler: () => {
            this.navCtrl.back();
          },
        },
      ],
    });

    await alert.present();
  }

  initSession() {
    this.interviewsApiService
      .joinInterview(this.authToken, this.interviewId)
      .subscribe((response) => {
        this.token = response.token;
        this.session = OT.initSession('47075354', response.sessionId);

        try {
          this.startCall();
        } catch (e) {
          console.log('error', e);
        }
      });
  }

  startCall() {
    if (this.platform.is('cordova')) {
      AudioToggle.setAudioMode(AudioToggle.SPEAKER);
    }

    // when stream received
    this.session.on('streamCreated', (event) => {
      const subscriberOptions = {
        name: 'subscriber' + new Date().getTime(),
        width: '100%',
        height: '100%',
        fitMode: 'cover' as 'cover',
        insertMode: 'append' as 'append',
      };
      if (this.session) {
        // for mobile
        if (this.platform.is('cordova')) {
          (document.querySelector('#peerView') as HTMLElement).style.zIndex =
            '-10';
        }
        this.peerSubscriber = this.session.subscribe(
          event.stream,
          'peerView',
          subscriberOptions,
          (error) => {
            if (error) {
              console.log('peerSubscriber ERROR', error);
            }
          }
        );
        setTimeout(() => {
          this.hideApp();
        }, 500);
      }
    });

    this.session.on('streamDestroyed', (event) => {
      this.showApp();
    });

    // create publisher
    const publisherOptions = {
      name: 'publisher' + new Date().getTime(),
      width: '100%',
      height: '100%',
      fitMode: 'cover' as 'cover',
      insertMode: 'append' as 'append',
    };

    this.publisher = OT.initPublisher('userView', publisherOptions, (error) => {
      if (error) {
        console.log('callbackError', error);
      }
    });

    //  when session is connected, publish user stream
    this.session.once('sessionConnected', (event) => {
      console.log('sessionConnected', event);
      // for mobile native views
      if (this.platform.is('cordova')) {
        if(document.querySelector('#userView .OT_root')){
          (
            document.querySelector('#userView .OT_root') as HTMLElement
          ).style.height = '100%';
          (
            document.querySelector('#userView .OT_root video') as HTMLElement
          ).style.borderRadius = '10px';
          (
            document.querySelector('#userView .OT_root') as HTMLElement
          ).style.background = 'transparent';
        }
        setTimeout(() => {
          OT.updateViews();
        }, 500);
      }

      if (this.session) {
        console.log('session NOT NULL');
        this.session.publish(this.publisher, (err) => {
          console.log('this.callStatus', this.callStatus);
          this.callStatus = 'Conectat. Va rugam asteptati participantii...';
          console.log('this.callStatus ', this.callStatus);
          if (err) {
            console.log('done session.publish', err);
          }
        });
      }
    });

    this.session.on('streamDestroyed', (event) => {
      console.log(`Stream ${event.stream.name} ended because ${event.reason}`);
    });

    this.session.connect(this.token, (error: any) => {
      if (error) {
        console.log(`There was an error connecting to the session ${error}`);
      }
    });
  }

  ngOnDestroy() {
    // resume screen awake
    /* tslint:disable */
    if (this.platform.is('cordova') && window['plugins'].insomnia) {
      window['plugins'].insomnia.allowSleepAgain();
    }
    /* tslint:enables */

    if (this.currentUserSubscription$) {
      this.currentUserSubscription$.unsubscribe();
    }
    if (this.tokenSubscription$) {
      this.tokenSubscription$.unsubscribe();
    }

    if (this.session) {
      if (this.publisher) {
        this.publisher.publishVideo(false);
        this.session.unpublish(this.publisher);
        this.publisher.destroy();
      }

      this.session.off('streamCreated', (event) => {
        console.log('off streamCreated', event);
      });

      if (this.session.connection) {
        this.session.disconnect();
      }
    }

    if (this.platform.is('cordova')) {
      AudioToggle.setAudioMode(AudioToggle.EARPIECE);
    }
  }

  toggleAudio() {
    if (this.audioDisabled) {
      this.publisher.publishAudio(true);
    } else {
      this.publisher.publishAudio(false);
    }
    this.audioDisabled = !this.audioDisabled;
  }

  toggleVideo() {
    if (this.videoDisabled) {
      this.publisher.publishVideo(true);
    } else {
      this.publisher.publishVideo(false);
    }
    this.videoDisabled = !this.videoDisabled;
  }

  hideApp() {
    // for mobile native views
    if (this.platform.is('cordova')) {
      (
        document.querySelector('#peerView .OT_root') as HTMLElement
      ).style.background = 'transparent';

      (document.querySelector('.call-content') as HTMLElement).classList.add(
        'hideBg'
      );
      if (
        document.querySelector('#peerView video')
      ) {
        (
          document.querySelector('#peerView video') as HTMLElement
        ).style.display = 'none';
      }

      (document.querySelector('.profile-info') as HTMLElement).style.display =
        'none';
      document.querySelector('html').style.background = 'transparent';
      document.querySelector('body').style.background = 'transparent';
      (document.querySelector('.OT_root') as HTMLElement).style.background =
        'transparent';
      OT.updateViews();
    }
  }

  showApp() {
    // for mobile native views
    if (this.platform.is('cordova')) {
      document.querySelector('html').style.background = 'initial';
      document.querySelector('body').style.background = 'initial';

      (document.querySelector('.call-content') as HTMLElement).classList.remove(
        'hideBg'
      );
      (document.querySelector('.profile-info') as HTMLElement).style.display =
        'block';
      (document.querySelector('#peerView video') as HTMLElement).style.display =
        'block';
    }
  }
}
