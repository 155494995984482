import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class BackdropMessageService {

    public modal: HTMLIonAlertElement = null;

    constructor(
        public alertController: AlertController
    ) {
    }

    async presentModal(messageText) {
        if (this.modal !== null) {
            await this.modal.dismiss();
        }

        this.modal = await this.alertController.create({
            cssClass: 'popupModal',
            message: messageText,
            buttons: ['OK']
        });

        this.modal.onDidDismiss().then(() => this.modal = null);

        return await this.modal.present();
    }

    async closeModal() {
        if (this.modal !== null) {
            return await this.modal.dismiss();
        }
    }
}
