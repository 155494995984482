import {createAction, props} from '@ngrx/store';

import {LoginCredentials, AccountType, User} from '../../core/models';

export enum AuthActionTypes {
  Logout = '[Auth] Logged out successfully',
}

export const loginUser = createAction(
  '[Auth] Login user',
  props<{ credentials: LoginCredentials }>()
);

export const loginSuccess = createAction(
  '[Auth] User logged in',
  props<{ authToken: any, credentials: LoginCredentials }>(),
);

export const loginFailed = createAction(
  '[Auth] User login failed',
  props<{ error: any }>(),
);

export const registerUser = createAction(
  '[Auth] Register user',
  props<{ user: User }>(),
);

export const registerUserSuccess = createAction(
  '[Auth] Register user success',
);

export const registerUserFailed = createAction(
  '[Auth] Register user failed',
  props<{ error: any }>(),
);

export const verifyAccount = createAction(
  '[Auth] Verify new account',
  props<{ phoneNumber: string, phonePrefix: string, accountType: AccountType, code: string, authToken?: any }>(),
);

export const verifyAccountSuccess = createAction(
  '[Auth] Verify new account successfully',
  props<{ authToken: any }>(),
);

export const verifyAccountFailed = createAction(
  '[Auth] Verify new account failed',
  props<{ error: any }>(),
);

export const getCurrentUser = createAction(
  '[Auth] Get current user',
);

export const getCurrentUserSuccess = createAction(
  '[Auth] Get current user success',
  props<{ user: User }>(),
);

export const getCurrentUserError = createAction(
  '[Auth] Error getting current user',
  props<{ error: any }>(),
);

export const logoutUser = createAction(
  '[Auth] Logout user'
);

export const logoutUserSuccess = createAction(
  '[Auth] Logged out successfully',
);

export const logoutUserFailed = createAction(
  '[Auth] Logout failed',
  props<{ error: any }>(),
);
