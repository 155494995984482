import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromReducers from '../reducers';

const getMedicalStaffFeatureState = createFeatureSelector<fromReducers.MedicalStaffState>('medicalStaff');

export const selectAllMedicalStaffDocuments = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => ({files: state.documents.data, categories: state.documents.categories}),
);

export const selectAllMedicalStaffDocumentsLoading = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.documents.loading,
);

export const selectAllMedicalStaffDocumentsFetchError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.documents.fetchError,
);

export const selectAllMedicalStaffDocumentsUploadError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.documents.uploadError,
);

export const selectDocumentUploadError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.error,
);

export const selectDownloadedDocument = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.documents.downloadedDocument.data,
);

// medical services
export const selectMedicalServices = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffServices,
);

export const selectMedicalServicesUpdated = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffServicesUpdated,
);

export const selectMedicalServicesFetching = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffServicesFetching,
);

export const selectMedicalServicesUpdating = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffServicesUpdating,
);

export const selectMedicalServicesError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffServicesError,
);

export const selectMedicalStaffProfile = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffProfile,
);
export const selectMedicalStaffProfileError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffProfileError,
);
export const selectAvailableMedicalServices = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffAvailableServices,
);

export const selectAvailableMedicalServicesFetching = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffAvailableServicesFetching,
);

export const selectFinancialDetails = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.financialDetails,
);

export const selectFinancialDetailsError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.financialDetailsError,
);

export const selectFinancialDetailsUpdating = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.financialDetailsUpdating,
);

export const selectMedicalStaffPacientsLoading = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffPatientsLoading,
);

export const selectMedicalStaffPatientsError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffPatientsError,
);

// selected patient
export const selectMedicalStaffSelectedPatient = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.patient.data,
);

export const selectMedicalStaffSelectedPatientLoading = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.patient.loading,
);

export const selectMedicalStaffSelectedPatientError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.patient.error,
);

// select patient address
export const selectMedicalStaffSelectedPatientAddresses = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.addresses.data,
);

export const selectMedicalStaffSelectedPatientAddressesLoading = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.addresses.loading,
);

export const selectMedicalStaffSelectedPatientAddressesError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.selectedPatient.addresses.error,
);

export const selectMedicalStaffPacients = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffPatients,
);

export const selectMedicalStaffAppointments = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffAppointments,
);

export const selectMedicalStaffAppointmentsError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffAppointmentsError,
);

export const selectMedicalStaffAppointmentsLoading = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.medicalStaffAppointmentsLoading,
);

export const selectMedicalStaffServicesArea = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.servicesArea,
);

export const selectMedicalStaffServicesAreaUpdating = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.servicesAreaUpdating,
);

export const selectMedicalStaffServicesAreaError = createSelector(
  getMedicalStaffFeatureState,
  (state: fromReducers.MedicalStaffState) => state.servicesAreaError,
);
