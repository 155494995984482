import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MedicalPersonelApiService } from 'src/app/core/http/medicalpersonel-api.service';

@Component({
  selector: 'app-list-ratings',
  templateUrl: './list-ratings.component.html',
  styleUrls: ['./list-ratings.component.css'],
})
export class ListRatingsComponent implements OnInit {
  @Input() public userId: string;

  loading = true;
  data = null;

  constructor(
    public medicalPersonelApiService: MedicalPersonelApiService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    this.medicalPersonelApiService.getRatings(this.userId).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          delete response.average;
          this.data = Object.values(response).map((item) => item);
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.data = [];
        this.loading = false;
      }
    );
  }
  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
