<div class="widget-holder">
  <ion-item lines="none">
    <ion-label class="widget-title">SESIZARI</ion-label>
    <ion-label slot="end" class="widget-link">VEZI TOATE</ion-label>
  </ion-item>

  <ion-item lines="none" class="record-holder" detail="true">
    <div>
      <ion-label class="widget-record-text-left">Sesizare nr. 125 - <span>26 Martie 2020</span></ion-label>
      <ion-label class="widget-record-textextra-left color-denied">Refuzata</ion-label>
    </div>
  </ion-item>

  <ion-item lines="none" class="record-holder" detail="true">
    <div>
      <ion-label class="widget-record-text-left">Sesizare nr. 125 - <span>26 Martie 2020</span></ion-label>
      <ion-label class="widget-record-textextra-left">In revizie</ion-label>
    </div>
  </ion-item>
</div>
