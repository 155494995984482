import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private androidPermissions: AndroidPermissions
  ) {

  }

  checkPermissions(permisssions: any[]): Promise<void|{}> {
    return new Promise<void|{}>( (resolve, reject) => {
      this.androidPermissions.requestPermissions(permisssions).then((result) => {
        if (result.hasPermission === true) {
          resolve();
        } else {
          reject({
            errorMessage: 'Please allow permissions in order to use this feature.'
          });
        }
      }).catch((err) => {
        if (err === 'cordova_not_available') {
              resolve();
            } else {
              console.log('[PermissionsService]', err);
              reject({
                errorMessage: 'There is a issue with permission management'
              });
            }
      });
    }) ;


  }

}




