import { Injectable } from '@angular/core';
import {
  Anamnesis,
  Diagnostic,
  MedicalDocument,
  MedicalServiceGroup,
  Patient,
  PatientAddress,
  PatientDiagnostic,
  PatientMedicalHistory,
  PatientMedicalRecord,
} from '../../model/patient.model';
import { Subject, Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { PatientApiService } from '../../service/patient-api/patient-api.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PatientFacadeService {
  private _patients = new Subject<Patient[]>();
  public patients$ = this._patients.asObservable();

  private _patient = new Subject<Patient>();
  public patient$ = this._patient.asObservable();

  private _patientDiagnostic = new Subject<PatientDiagnostic>();
  public patientDiagnostic$ = this._patientDiagnostic.asObservable();

  private _medicalServices = new Subject<MedicalServiceGroup[]>();
  public medicalServices$ = this._medicalServices.asObservable();

  private _medicalHistory = new Subject<PatientMedicalHistory>();
  public medicalHistory$ = this._medicalHistory.asObservable();

  private _patientAddresses = new Subject<PatientAddress[]>();
  public patientAddresses$ = this._patientAddresses.asObservable();

  private _patientAddress = new Subject<PatientAddress>();
  public patientAddress$ = this._patientAddress.asObservable();

  private _patientMedicalRecord = new Subject<PatientMedicalRecord>();
  public patientMedicalRecord$ = this._patientMedicalRecord.asObservable();

  private _updateInProgress = new Subject<boolean>();
  public updateInProgress$ = this._updateInProgress.asObservable();

  private _errors = new Subject<boolean>();
  public errors$ = this._errors.asObservable();

  private _loadingPatients = new BehaviorSubject(false);
  public loadingPatients$ = this._loadingPatients.asObservable();

  constructor(
    private patientApiService: PatientApiService,
    public toastController: ToastController
  ) {}

  // patients
  public dispatchLoadPatients() {
    this._loadingPatients.next(true);
    this._errors.next(null);

    return this.patientApiService.findAllPatients().subscribe(
      (response) => {
        this._patients.next(response);
        this._loadingPatients.next(false);
      },
      (error) => {
        this._errors.next(error);
        this._loadingPatients.next(false);
      }
    );
  }

  public dispatchLoadPatient(patientId: string) {
    this.patientApiService.findPatientById(patientId).subscribe(
      ([response]) => {
        this._patient.next(response);
      },
      (error) => {
        this._errors.next(error);
      }
    );
  }

  public dispatchAddPatient(payload: Patient) {
    return this.patientApiService.addPatient(payload).pipe(
      catchError((error) => {
        this._errors.next(error);
        return throwError(error);
      })
    );
  }

  public dispatchUpdatePatient(payload: Patient) {
    this._updateInProgress.next(true);

    return this.patientApiService.updatePatient(payload).pipe(
      tap(async () => {
        const toast = await this.toastController.create({
          message: 'Modificările au fost salvate',
          position: 'bottom',
          color: 'success',
          duration: 2000,
        });
        await toast.present();
      }),
      finalize(() => this._updateInProgress.next(false)),
      catchError((error) => {
        this._errors.next(error);
        return throwError(error);
      })
    );
  }

  // TODO
  public dispatchLoadMedicalServices() {
    this.patientApiService.findMedicalServices().subscribe(
      (response) => {
        this._medicalServices.next(response);
      },
      (error) => {
        this._errors.next(error);
      }
    );
  }

  // patients address
  public dispatchLoadPatientAddresses(patientId: string) {
    this.patientApiService.findPatientAddressesById(patientId).subscribe(
      (response) => {
        this._patientAddresses.next(response);
      },
      (error) => {
        this._errors.next(error);
      }
    );
  }

  public dispatchAddPatientAddress(patientId, payload: PatientAddress) {
    return this.patientApiService.addPatientAddress(patientId, payload).pipe(
      catchError((error) => {
        this._errors.next(error);
        return throwError(error);
      })
    );
  }

  public dispatchUpdatePatientAddress(
    patientId: string,
    addressId: string,
    payload: PatientAddress
  ) {
    this._updateInProgress.next(true);
    return this.patientApiService
      .updatePatientAddress(patientId, addressId, payload)
      .pipe(
        tap(async () => {
          const toast = await this.toastController.create({
            message: 'Adresa a fost salvată.',
            position: 'bottom',
            color: 'success',
            duration: 2000,
          });
          await toast.present();
        }),
        finalize(() => this._updateInProgress.next(false)),
        catchError((error) => {
          this._errors.next(error);
          return throwError(error);
        })
      );
  }

  public dispatchLoadPatientAddress(patientId: string, addressId: string) {
    return this.patientApiService
      .findPatientAddressById(patientId, addressId)
      .subscribe(
        ([response]) => {
          this._patientAddress.next(response);
        },
        (error) => {
          this._errors.next(error);
        }
      );
  }

  // patient medical records

  // patient medical diagnostic
  // on error - empty
  public dispatchLoadPatientDiagnostic(
    beneficiaryId: string,
    patientId: string
  ) {
    this.patientApiService
      .findPatientDiagnosticById(beneficiaryId, patientId)
      .pipe
      // catchError(error => {
      //   const missingDiagnosis: PatientDiagnostic = {
      //     patient: null,
      //     diagnostic: {
      //       id: null,
      //       date: null,
      //       name: 'Lipsa diagnostic',
      //       advice: null,
      //       remarks: null
      //     }
      //   };
      //   return of(missingDiagnosis);
      // })
      ()
      .subscribe((response) => {
        this._patientDiagnostic.next(response);
      });
  }

  public dispatchLoadPatientMedicalHistory(
    beneficiaryId: string,
    patientId: string
  ): Observable<Diagnostic[]> {
    return this.patientApiService.findMedicalHistoryByPatientId(
      beneficiaryId,
      patientId
    );
  }

  public dispatchAddPatientAnamnesis(
    beneficiaryId,
    patientId,
    payload: Anamnesis
  ) {
    return this.patientApiService
      .createOrUpdateAnamnesis(beneficiaryId, patientId, payload)
      .pipe(
        catchError((error) => {
          this._errors.next(error);
          return throwError(error);
        })
      );
  }

  public dispatchLoadPatientMedicalRecord(
    beneficiaryId: string,
    patientId: string
  ) {
    this.patientApiService
      .findPatientMedicalRecord(beneficiaryId, patientId)
      .subscribe(
        (response) => {
          this._patientMedicalRecord.next(response);
        },
        (error) => {
          this._errors.next(error);
        }
      );
  }

  public dispatchAddPatientMedicalHistory(
    beneficiaryId,
    patientId,
    payload: PatientMedicalHistory
  ) {
    return this.patientApiService
      .addMedicalHistory(beneficiaryId, patientId, payload)
      .pipe(
        catchError((error) => {
          this._errors.next(error);
          return throwError(error);
        })
      );
  }

  public uploadMedicalDocuments(
    beneficiaryId,
    patientId,
    payload: MedicalDocument
  ) {
    return this.patientApiService
      .addMedicalDocument(beneficiaryId, patientId, payload)
      .pipe(
        catchError((error) => {
          this._errors.next(error);
          return throwError(error);
        })
      );
  }

  public dispatchLoadMedicalDocuments(
    beneficiaryId: string,
    patientId: string
  ): Observable<MedicalDocument[]> {
    return this.patientApiService.findAllMedicalDocumentsByPatientId(
      beneficiaryId,
      patientId
    );
  }

  public dispatchDownloadMedicalDocument(
    beneficiaryId: string,
    patientId: string,
    documentId: string
  ): Observable<any> {
    return this.patientApiService.downloadDocumentByDocumentId(
      beneficiaryId,
      patientId,
      documentId
    );
  }
}
