import { createAction, props } from '@ngrx/store';
import { Invoice } from 'src/app/core/models/invoice.model';

const INVOICE_TAG = '[Invoice]';

export const loadBeneficiaryInvoices = createAction(
  `${INVOICE_TAG} Fetch beneficiary invoices`
);

export const loadBeneficiaryInvoicesSuccess = createAction(
  `${INVOICE_TAG} Fetch beneficiary invoices successfully`,
  props<{ invoices: Invoice[] }>()
);

export const loadBeneficiaryInvoicesFailed = createAction(
  `${INVOICE_TAG} Fetch beneficiary invoices failed`,
  props<{ error: any }>()
);

export const payInvoice = createAction(
  `${INVOICE_TAG} Pay beneficiary invoice`,
  props<{ id: number }>()
);

export const payInvoiceSuccess = createAction(
  `${INVOICE_TAG} Pay beneficiary invoice success`,
  props<{ id: number }>()
);

export const payInvoiceFailed = createAction(
  `${INVOICE_TAG} Pay beneficiary invoice failed`,
  props<{ id: number; error: any }>()
);
