<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Adaugă un card nou</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content slot="fixed">

  <form [formGroup]="formAdaugaCard">

    <ion-item [style.color]="!formAdaugaCard.controls.numarCard.valid && submited ? '#b50017' : '' ">
      <ion-label position="stacked">NUMAR CARD</ion-label>
      <ion-input formControlName="numarCard" type='number'></ion-input>
    </ion-item>

    <ion-item [style.color]="!formAdaugaCard.controls.numeTitular.valid && submited ? '#b50017' : '' ">
      <ion-label position="stacked">NUME TITULAR CARD</ion-label>
      <ion-input formControlName="numeTitular"></ion-input>
    </ion-item>

    <ion-item [style.color]="!formAdaugaCard.controls.dataExpirare.valid && submited ? '#b50017' : '' ">
      <ion-label position="stacked">DATA EXPIRARE</ion-label>
      <ion-input formControlName="dataExpirare" type='number'></ion-input>
    </ion-item>

    <ion-item [style.color]="!formAdaugaCard.controls.CVV.valid && submited ? '#b50017' : '' ">
      <ion-label position="stacked">CVV</ion-label>
      <ion-input formControlName="CVV" type='number'></ion-input>
    </ion-item>

    <ion-button expand="block" class="btn-adauga" shape="round" [disabled]="!formAdaugaCard.valid" (click)="submit()">
      Adaugă card
    </ion-button>
  </form>

</ion-content>
