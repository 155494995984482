<app-widget [widgetConfig]="widgetConfig" [loading]="loading$ | async" [error]="error$ | async">
  <ng-container *ngIf="(appointments$ | async) as appointments">
    <ion-list>
      <ion-item *ngFor="let appointment of appointments" lines="none" color="warning-light"
                class="ion-item ion-align-items-center">
        <div slot="start">
          <span class="font-18 color--warning">
            {{appointment.patient.firstName + ' ' + appointment.patient.lastName}}
          </span>
        </div>

        <span slot="end" class="font-bold color--warning">{{appointment.appointment.date}}</span>
      </ion-item>
    </ion-list>

    <ng-container *ngIf="!appointments.length">
      Nu am găsit {{isBeneficiary ? 'vizite' : 'programări'}}
    </ng-container>
  </ng-container>
</app-widget>
