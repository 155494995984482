import { Injectable } from '@angular/core';
import { format, formatDistanceToNow } from 'date-fns';
import { ro } from 'date-fns/locale';


@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(
  ) {

  }

  normalize(data: any[]) {
    const result = [];
    if (data.length > 0) {
      data.forEach(appointment => {

        // TODO: DO NOT EVER DO THIS!!!!! FIX IN API DATE STRING FORMAT SHOULD BE 2021-02-08T23:18:20
        const appointmentDate = new Date( appointment.appointment.date.replace(' ', 'T'));

        const processed = {...appointment};

        processed.medicalServicesNames = '';
        appointment.medicalServices.map((value, key) => {
          if (processed.medicalServicesNames) {
            processed.medicalServicesNames += ', ' + value.name;
          } else {
            processed.medicalServicesNames += value.name;
          }
        });


        processed.dateDisplay = format(new Date(appointmentDate), 'PPPp', {locale: ro});
        processed.distanceDisplay = formatDistanceToNow(new Date(appointmentDate), {locale: ro});
        processed.beneficiaryName = appointment.patient.firstName + ' ' + appointment.patient.lastName;

        if (appointmentDate.getTime() + (processed.duration * 60 * 1000)  < new Date().getTime()) {
          processed.active = false;
        } else {
          if (appointmentDate.getTime() > new Date().getTime()) {
            processed.active = true;
            processed.status = processed.distanceDisplay;
          } else {
            processed.active = false;
            processed.status = 'Incheiata';
          }
        }
        result.push(processed);
      });
    }
    return result;
  }
}







