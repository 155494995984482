import { Injectable } from '@angular/core';
import { AbstractApiService } from './abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface MedicalReport {
  id: string;
  appointmentId: string;
  status: string;
  statusId: number;
  description: string;
  date: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  ui?: {
    variant?: string; // 'success' | 'warning' | 'error'
    message?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MedicalReportApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  public findAll(patientId: string): Observable<MedicalReport[]> {
    return this.http
      .get<MedicalReport[]>(
        `${this.getBaseUrl()}/patients/${patientId}/medical-reports/`
      )
      .pipe(
        map((reports) =>
          reports.map((report) => ({
            ...report,
            id: report.id.toString(),
            appointmentId: report.appointmentId.toString(),
            ui: {
              variant: report.statusId === 1 ? 'warning' : 'success',
              message: report.statusId === 1 ? 'În așteptare' : 'Aprobat',
            },
          }))
        )
      );
  }

  public findById(
    patientId: string,
    reportId: string
  ): Observable<MedicalReport> {
    return this.http
      .get<MedicalReport>(
        `${this.getBaseUrl()}/patients/${patientId}/medical-reports/${reportId}/`
      )
      .pipe(
        map((report) => ({
          ...report,
          id: report.id.toString(),
          appointmentId: report.appointmentId.toString(),
          ui: {
            variant: report.statusId === 1 ? 'warning' : 'success',
            message: report.statusId === 1 ? 'În așteptare' : 'Aprobat',
          },
        }))
      );
  }

  public approveMedicalReport(
    patientId: string,
    reportId: string
  ): Observable<MedicalReport> {
    return this.http
      .post<MedicalReport>(
        `${this.getBaseUrl()}/patients/${patientId}/medical-reports/${reportId}/`,
        {}
      )
      .pipe
      // map((report) => ({
      //   ...report,
      //   id: report.id.toString(),
      //   appointmentId: report.appointmentId.toString(),
      //   ui: {
      //     variant: report.statusId === 1 ? 'warning' : 'success',
      //     message: report.statusId === 1 ? 'În așteptare' : 'Aprobat',
      //   },
      // }))
      ();
  }
}
