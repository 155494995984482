import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppTabsComponent} from './app-tabs.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AppTabsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    AppTabsComponent
  ]
})
export class AppTabsModule {}
