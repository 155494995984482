import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import { medicalReportAdapter } from '../reducers/medical-report.reducer';

const getContractsFeatureState =
  createFeatureSelector<fromReducers.MedicalReportState>('medicalReport');

export const { selectAll: selectAllMedicalReports } =
  medicalReportAdapter.getSelectors(getContractsFeatureState);

export const selectAllMedicalReportsLoading = createSelector(
  getContractsFeatureState,
  (state: fromReducers.MedicalReportState) => state.loading
);

export const selectAllMedicalReportsError = createSelector(
  getContractsFeatureState,
  (state: fromReducers.MedicalReportState) => state.error
);

export const selectMedicalReport = createSelector(
  getContractsFeatureState,
  (state: fromReducers.MedicalReportState) => state.selectedMedicalReport.data
);

export const selectMedicalReportLoading = createSelector(
  getContractsFeatureState,
  (state: fromReducers.MedicalReportState) =>
    state.selectedMedicalReport.loading
);

export const selectMedicalReportError = createSelector(
  getContractsFeatureState,
  (state: fromReducers.MedicalReportState) => state.selectedMedicalReport.error
);
