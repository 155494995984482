<ion-content>
  <ng-container *ngIf="!(invoices$ | async) && (loading$ | async)">
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="error$ | async as error">
    <div class="center-float-message">
      {{ error.message }}
    </div>
  </ng-container>

  <ng-container *ngIf="!(error$ | async)">
    <ng-container *ngIf="invoices$ | async as invoices">
      <ng-container *ngIf="invoices.length; else zeroRecords">
        <ion-list class="list-menu font-18" lines="none">
          <beneficiary-invoice
            *ngFor="let invoice of invoices"
            [data]="invoice"
            (submit)="jumpToPayWindow($event)"
          >
          </beneficiary-invoice>
        </ion-list>
      </ng-container>
    </ng-container>
  </ng-container>
</ion-content>

<ng-template #zeroRecords>
  <div class="center-float-message">Nu am găsit facturi</div>
</ng-template>
