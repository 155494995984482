import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';

import {filter, map, pluck, takeUntil, tap} from 'rxjs/operators';

import {BaseComponentDirective} from 'src/app/components/base.components';

import * as fromStore from '../../../store';
import {Observable} from 'rxjs';
import {selectFinancialDetailsError, selectFinancialDetailsUpdating} from '../../../store';

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.css']
})
export class FinancialDetailsComponent extends BaseComponentDirective implements OnInit {

  public financialDetails$;

  public financialDetailsForm = new FormGroup({
    bankAccountNumber: new FormControl('', []),
    bankName: new FormControl('', []),
  });

  public updating$: Observable<boolean> = this.store.select(selectFinancialDetailsUpdating);

  public errors$: Observable<any> = this.store.select(selectFinancialDetailsError).pipe(
    map(errors => {
      if (!errors) {
        return;
      }

      if (errors.message) {
        return Object.keys(errors.message).map(error => errors.message[error]);
      }

      return ['GENERAL_ERRROR'];
    })
  );

  constructor(private store: Store<fromStore.State>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(fromStore.getFinancialDetails());

    this.financialDetails$ = this.store.pipe(
      select(fromStore.selectFinancialDetails),
      filter(value => !!value),
      tap(value => {
        this.financialDetailsForm.patchValue(value, {emitEvent: false});
        this.financialDetailsForm.markAsPristine({onlySelf: true});
      }),
      takeUntil(this.ngDestroyed$)
    );
  }

  submit() {
    this.store.dispatch(fromStore.addFinancialDetails({
      financialDetails: {
        bankAccountNumber: this.financialDetailsForm.value.bankAccountNumber,
        bankName: this.financialDetailsForm.value.bankName
      }
    }));
  }
}
