import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import {
  Contract,
  ContractsApiService,
} from 'src/app/pages/contracts/services/contracts/contracts-api.service';
import { format, formatDistanceToNow } from 'date-fns';
import { ro } from 'date-fns/locale';
import { MedicalPersonelApiService } from 'src/app/core/http/medicalpersonel-api.service';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../../../store';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-add-apointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '400ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class AddAppointmentComponent implements OnInit, OnDestroy {
  public contracts$: Observable<Contract[]>;
  public availableHours$: Subscription;

  public medicalStaffId: any = null;
  public contracts: any[] = null;
  public services: any[] = null;
  public availableHours: any[] = null;
  public hours: any[] = null;
  public availableDays: any[] = null;
  public selectedDay: string = null;
  public selectedContract: any = null;
  public selectedHour: any = null;

  public loading = false;
  public appointmentAdded = false;

  public errorMessage: string;

  public form = new FormGroup({
    contractId: new FormControl(null, []),
    medicalStaffId: new FormControl(null, []),
    patientId: new FormControl(null, []),
    medicalServiceIds: new FormControl([], []),
    date: new FormControl(null, []),
    comments: new FormControl(''),
  });

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromStore.State>,
    private contractsApiService: ContractsApiService,
    private medicalPersonelApiService: MedicalPersonelApiService
  ) {}

  ngOnDestroy(): void {
    if (this.availableHours$) {
      this.availableHours$.unsubscribe();
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  ngOnInit(): void {
    this.contracts$ =
      this.contractsApiService.findAllMedicalStaffOngoingContracts();
    this.contracts$.subscribe(
      (resp) => {
        this.contracts = resp;
      },
      ({ error }) => {
        this.errorMessage = error.message;
      }
    );
  }

  public onSelectContract(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      contractId: event.srcElement.value.contractId,
      medicalStaffId: event.srcElement.value.medicalStaff.identifier,
      patientId: event.srcElement.value.patient.id,
      date: null,
    });

    this.selectedContract = event.srcElement.value;
    this.services = event.srcElement.value.services;
    this.medicalStaffId = event.srcElement.value.medicalStaff.identifier;
  }

  public onSelectMedicalService(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      medicalServiceIds: event.srcElement.value,
      date: null,
    });

    this.availableHours$ = this.contractsApiService
      .getMedicalStaffAvailableHours(
        this.medicalStaffId,
        event.srcElement.value
      )
      .subscribe(
        (hours) => {
          this.hours = hours;
          this.availableDays = [];
          for (const day of Object.keys(hours)) {
            this.availableDays.push({
              raw: day,
              displayDate: format(new Date(day), 'PPP', { locale: ro }),
            });
          }
        },
        ({ error }) => {
          this.errorMessage = error.message;
        }
      );
  }

  public onSelectDay(event) {
    if (event.srcElement.value === undefined) {
      return;
    }
    this.selectedDay = event.srcElement.value;
    this.availableHours = this.hours[event.srcElement.value];
  }

  public onSelectHour(event) {
    if (event.srcElement.value === undefined) {
      return;
    }

    this.form.patchValue({
      date: this.selectedDay + ' ' + event.srcElement.value,
    });
    this.selectedHour = event.srcElement.value;
  }

  public submitForm() {
    this.loading = true;

    this.medicalPersonelApiService.addAppointment(this.form.value).subscribe(
      () => {
        this.appointmentAdded = true;
        this.loading = false;
        this.store.dispatch(fromStore.loadMedicalStaffAppointments());
      },
      ({ error }) => {
        this.loading = false;
        this.errorMessage = error.message;
      }
    );
  }

  public getDisplayDate() {
    if (this.selectedDay) {
      return format(new Date(this.selectedDay), 'PPP', { locale: ro });
    }
  }
}
