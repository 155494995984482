import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MedicalStaffScheduleEditFacadeService } from '../../../services/facade/medical-staff-schedule-edit-facade/medical-staff-schedule-edit-facade.service';
import {
  MedicalStaffScheduleModalConfig,
  ModalActionType,
  ScheduleDay,
  ScheduleFormValue,
  ScheduleTimeFrame,
} from '../../../core/models/medical-staff-schedule';
import { OverlayEventDetail } from '@ionic/core';
import { ModalScheduleEditComponent } from '../../../components/modals/modal-schedule-edit/modal-schedule-edit.component';
import {
  AlertController,
  IonRouterOutlet,
  ModalController,
} from '@ionic/angular';
import { ToastService } from '../../../core/services/toast.service';

@Component({
  selector: 'app-medical-staff-schedule-edit-route',
  templateUrl: './medical-staff-schedule-edit-route.component.html',
  styleUrls: ['./medical-staff-schedule-edit-route.component.css'],
})
export class MedicalStaffScheduleEditRouteComponent implements OnInit {
  private readonly MODALS_LOCALES = {
    create: {
      title: 'Adaugă un interval',
      button: 'Adaugă',
    },
    edit: {
      title: 'Modifică intervalul',
      button: 'Salvează',
    },
  };

  public scheduleDays$: Observable<ScheduleDay[]>;

  public isLoading: boolean;

  constructor(
    private scheduleFacade: MedicalStaffScheduleEditFacadeService,
    private modalController: ModalController,
    private alertController: AlertController,
    private routerOutlet: IonRouterOutlet,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.scheduleDays$ = this.scheduleFacade.fetchSchedule();
  }

  public async onCreate() {
    await this.presentModal({
      formValue: null,
      timeFrameId: null,
      action: ModalActionType.CREATE,
      i18n: this.MODALS_LOCALES.create,
    });
  }

  public async onEdit(day: string, scheduleTimeFrame: ScheduleTimeFrame) {
    const formValue: ScheduleFormValue = {
      day,
      availableTimeFrame: {
        start: scheduleTimeFrame.start,
        end: scheduleTimeFrame.end,
      },
    };

    await this.presentModal({
      formValue,
      timeFrameId: scheduleTimeFrame.scheduleId,
      action: ModalActionType.UPDATE,
      i18n: this.MODALS_LOCALES.edit,
    });
  }

  public async onDelete(scheduleId: number) {
    const { role } = await this.presentDeleteScheduleAlert();

    if (role !== 'cancel') {
      this.scheduleFacade.deleteScheduleById(scheduleId).subscribe(
        () => {
          this.scheduleDays$ = this.scheduleFacade.fetchSchedule();
          this.toastService.presentToast({
            message: 'Intervalul a fost sters.',
            color: 'success',
          });
        },
        (err) => {
          this.toastService.presentToast({
            message: 'Intervalul nu a putut fi sters.',
            color: 'success',
          });
        }
      );
    }
  }

  private async presentModal(modalConfig: MedicalStaffScheduleModalConfig) {
    const { role } = await this.openModal(modalConfig);

    if (role !== 'cancel') {
      this.scheduleDays$ = this.scheduleFacade.fetchSchedule();
    }
  }

  private async openModal(
    config: MedicalStaffScheduleModalConfig
  ): Promise<OverlayEventDetail> {
    let formValue: ScheduleFormValue = null;

    if (config.formValue) {
      formValue = {
        day: config.formValue.day,
        availableTimeFrame: {
          start: this.scheduleFacade.scheduleTimeToISOString(
            config.formValue.availableTimeFrame.start
          ),
          end: this.scheduleFacade.scheduleTimeToISOString(
            config.formValue.availableTimeFrame.end
          ),
        },
      };
    }

    const modal = await this.modalController.create({
      component: ModalScheduleEditComponent,
      componentProps: {
        title: config.i18n.title,
        actionType: config.action,
        initialFormValue: formValue,
        buttonLabel: config.i18n.button,
        timeFrameId: config.timeFrameId,
      },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  private async presentDeleteScheduleAlert(): Promise<OverlayEventDetail> {
    const alert = await this.alertController.create({
      header: 'Sterge intervalul',
      // subHeader: 'Subtitle',
      message: 'Esti sigur ca vrei sa stergi intervalul?',
      buttons: [
        {
          text: 'Da',
          role: 'accept',
        },
        {
          text: 'Nu',
          role: 'cancel',
        },
      ],
    });

    await alert.present();

    return alert.onDidDismiss();
  }
}
