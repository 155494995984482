import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../../store';
import {User} from '../../../core/models';
import {getCurrentUser} from '../../../store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-beneficiary-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class BeneficiaryProfileComponent {

  public currentUser$: Observable<User>;

  public ngDestroyed$: Subject<void>;

  constructor(
    private store: Store<fromStore.State>
  ) {
  }

  ionViewWillEnter() {
    this.ngDestroyed$ = new Subject();
    this.store.dispatch(getCurrentUser());

    this.currentUser$ = this.store.pipe(
      select(fromStore.selectCurrentUser),
      takeUntil(this.ngDestroyed$)
    );
  }

  ionViewWillLeave() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
}
