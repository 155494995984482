import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from 'src/app/core/models';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../store';
import {Observable, Subscription} from 'rxjs';
import {InterviewData} from 'src/app/services/interview-data.service';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-list-interviews',
  templateUrl: './list-interviews.component.html',
  styleUrls: ['./list-interviews.component.css']
})
export class ListInterviewsComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean>;

  public error$: Observable<any>;

  public interviews$: Observable<any>;

  private currentUserSubscription$: Subscription;

  private tokenSubscription$: Subscription;

  public isBeneficiary = false;

  constructor(
    private store: Store<fromStore.State>,
    private interviewData: InterviewData
  ) {
  }

  ngOnInit() {
    this.loading$ = this.store.pipe(
      select(fromStore.selectInterviewsLoading)
    );

    this.error$ = this.store.pipe(
      select(fromStore.selectInterviewsError)
    );

    this.currentUserSubscription$ = this.store.pipe(
      select(fromStore.selectCurrentUser),
    ).subscribe(user => {
      switch (+user.accountType) {
        case 2:
          this.isBeneficiary = false;
          break;
        case 1:
        default:
          this.isBeneficiary = true;
      }
      this.initList();
    });
  }

  initList() {
    this.tokenSubscription$ = this.store.pipe(select(fromStore.selectToken))
      .subscribe(authToken => {
        if (!authToken) {
          return;
        }
        this.store.dispatch(fromStore.loadInterviews());
      });

    this.interviews$ = this.store.pipe(
      select(fromStore.selectInterviews),
      filter(data => !!data),
      map(content => {
        const parsedData = this.interviewData.normalize(content);

        return parsedData.map(interview => {
          return {
            ...interview,
            avatarName: this.isBeneficiary ? interview.beneficiaryName : interview.medicalStaffName,
          };
        });
      })
    );
  }


  ngOnDestroy() {
    if (this.currentUserSubscription$) {
      this.currentUserSubscription$.unsubscribe();
    }
    if (this.tokenSubscription$) {
      this.tokenSubscription$.unsubscribe();
    }
  }
}
