import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MedicalStaffScheduleExceptionFacadeService} from '../../../services/facade/medical-staff-schedule-exception-facade/medical-staff-schedule-exception-facade.service';
import {
  ModalScheduleExceptionConfig,
  ScheduleException
} from '../../../core/models/medical-staff-schedule-exception';
import {ModalController} from '@ionic/angular';
import {ModalScheduleExceptionEditComponent} from '../../../components/modals/modal-schedule-exception-edit/modal-schedule-exception-edit.component';
import {ModalActionType} from '../../../core/models/medical-staff-schedule';
import {ToastService} from '../../../core/services/toast.service';


@Component({
  selector: 'app-medical-staff-schedule-exception-edit-route',
  templateUrl: './medical-staff-schedule-exception-edit-route.component.html',
  styleUrls: ['./medical-staff-schedule-exception-edit-route.component.css']
})
export class MedicalStaffScheduleExceptionEditRouteComponent implements OnInit {
  private readonly MODALS_LOCALES = {
    create: {
      title: 'Adaugă un concediu',
      button: 'Adaugă'
    },
    edit: {
      title: 'Modifică un concediu',
      button: 'Salvează'
    }
  };

  public scheduleException$: Observable<ScheduleException[]>;

  constructor(
    private scheduleExceptionFacade: MedicalStaffScheduleExceptionFacadeService,
    private modalController: ModalController,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.scheduleException$ = this.scheduleExceptionFacade.fetchScheduleException().pipe(
      catchError(() => of([]))
    );
  }

  public async onCreate() {
    const {role} = await this.openModal({
      scheduleException: {
        id: null,
        startDate: null,
        endDate: null,
        description: null,
        isWorkingDay: null,
        medicalStaffId: null
      },
      action: ModalActionType.CREATE,
      i18n: this.MODALS_LOCALES.create
    });

    if (role !== 'cancel') {
      this.scheduleException$ = this.scheduleExceptionFacade.fetchScheduleException().pipe(
        catchError(() => of([]))
      );
    }
  }

  public async onEdit(scheduleException: ScheduleException) {
    const {role} = await this.openModal({
        scheduleException,
        action: ModalActionType.UPDATE,
        i18n: this.MODALS_LOCALES.edit
      }
    );

    if (role !== 'cancel') {
      this.scheduleException$ = this.scheduleExceptionFacade.fetchScheduleException().pipe(
        catchError(() => of([]))
      );
    }
  }

  public async onDelete(scheduleExceptionId: number) {
    const {role} = await this.scheduleExceptionFacade.openDeleteScheduleExceptionAlert();

    if (role !== 'cancel') {
      this.scheduleExceptionFacade.deleteScheduleExceptionById(scheduleExceptionId).subscribe(
        () => {
          this.scheduleException$ = this.scheduleExceptionFacade.fetchScheduleException().pipe(
            catchError(() => of([]))
          );
          this.toastService.presentToast({message: 'Concediul a fost sters.', color: 'success'});
        },
        err => {
          this.toastService.presentToast({message: 'Concediul nu a putut fi sters.', color: 'warning'});
        }
      );
    }
  }

  private async openModal(modalConfig: ModalScheduleExceptionConfig) {
    const modal = await this.modalController.create({
      component: ModalScheduleExceptionEditComponent,
      componentProps: {
        initialFormValue: {
          startDate: modalConfig.scheduleException.startDate,
          endDate: modalConfig.scheduleException.endDate,
          description: modalConfig.scheduleException.description
        },
        scheduleExceptionId: modalConfig.scheduleException.id,
        actionType: modalConfig.action,
        title: modalConfig.i18n.title,
        buttonLabel: modalConfig.i18n.button
      }
    });

    await modal.present();

    return modal.onDidDismiss();
  }
}
