import { createAction, props } from '@ngrx/store';
import {
  MedicalStaffService,
  MedicalStaffServiceGroup,
} from 'src/app/core/models/medical-staff-service';
import { MedicalStaffServicesInterface } from 'src/app/core/models/medical-services.interface';
import { MedicalStaffPersonalDetailsInterface } from 'src/app/core/models/medical-staff-personal-details.interface';
import { MedicalStaffFinancialDetailsInterface } from '../../core/models/medical-staff-financial-details.interface';
import { MedicalStaffAddressInterface } from 'src/app/core/models/medical-address.interface';
import { Patient } from '../../core/models/patient';
import { PatientAddress } from '../../pages/patient/model/patient.model';
import { MedicalStaffDocumentsRouteData } from '../../pages/medical-staff/documents/documents.component';
import { DocumentsInterface } from '../../core/models/documents.interface';

const MEDICAL_STAFF = '[MedicalStaff]';

export const getAllMedicalStaffDocuments = createAction(
  `${MEDICAL_STAFF} Get all documents for user account`
);

export const uploadMedicalStaffDocument = createAction(
  `${MEDICAL_STAFF} Upload medical staff document`,
  props<{ file: any; documentType: string }>()
);

export const uploadMedicalStaffDocumentSuccess = createAction(
  `${MEDICAL_STAFF} Upload success`,
  props<{ file: DocumentsInterface; documentType: string }>()
);

export const uploadMedicalStaffDocumentError = createAction(
  `${MEDICAL_STAFF} Upload failed`,
  props<{ error: any }>()
);

export const getDocumentsSuccessful = createAction(
  `${MEDICAL_STAFF} Get all documents success`,
  props<{ documents: DocumentsInterface[] }>()
);

export const getDocumentsFailed = createAction(
  `${MEDICAL_STAFF} Get all documents failed`,
  props<{ error: any }>()
);

export const getDocument = createAction(
  `${MEDICAL_STAFF} Get uuid document`,
  props<{ uuid: string }>()
);

export const getDocumentSuccessful = createAction(
  `${MEDICAL_STAFF} Get uuid document success`,
  props<{ document: any }>()
);

export const getDocumentFailed = createAction(
  `${MEDICAL_STAFF} Get uuid document failed`,
  props<{ error: any }>()
);

export const resetDownloadedDocument = createAction(
  `${MEDICAL_STAFF} reset downloaded document`
);
export const getServices = createAction(
  `${MEDICAL_STAFF} Get medical services`
);

export const getServicesSuccess = createAction(
  `${MEDICAL_STAFF} Get medical services success`,
  props<{ medicalServices: MedicalStaffService }>()
);

export const getServicesFailed = createAction(
  `${MEDICAL_STAFF} Get medical services failed`,
  props<{ error: any }>()
);

export const getProfile = createAction(
  `${MEDICAL_STAFF} Get medical staff profile`,
  props<{ profileID: any; authToken: string }>()
);
export const getProfileSuccessful = createAction(
  `${MEDICAL_STAFF} Get medical staff profile success`,
  props<{ medicalStaffProfile: any }>()
);
export const getProfileFailed = createAction(
  `${MEDICAL_STAFF} Get medical staff profile failed`,
  props<{ error: any }>()
);
export const resetGetProfile = createAction(
  `${MEDICAL_STAFF} reset medical staff profile`
);

export const getAvailableServices = createAction(
  `${MEDICAL_STAFF} Get all available medical services`
);

export const getAvailableServicesSuccess = createAction(
  `${MEDICAL_STAFF} Get all available medical services success`,
  props<{ medicalServices: MedicalStaffServiceGroup[] }>()
);

export const getAvailableServicesFailed = createAction(
  `${MEDICAL_STAFF} Get all available medical services failed`,
  props<{ error: any }>()
);

export const addService = createAction(
  `${MEDICAL_STAFF} Add medical service`,
  props<{ service: MedicalStaffServicesInterface }>()
);

export const addServiceSuccess = createAction(
  `${MEDICAL_STAFF} Add medical services success`,
  props<{ medicalServices: MedicalStaffService }>()
);

export const addServiceFailed = createAction(
  `${MEDICAL_STAFF} Add medical services failed`,
  props<{ error: any }>()
);

export const resetServicesState = createAction(
  `${MEDICAL_STAFF} Reset medical services state`
);

export const deleteService = createAction(
  `${MEDICAL_STAFF} Remove medical service`,
  props<{ medicalServiceId: string }>()
);

export const deleteServiceSuccess = createAction(
  `${MEDICAL_STAFF} Remove medical service successfully`,
  props<{ medicalServices: MedicalStaffService }>()
);

export const deleteServiceFailed = createAction(
  `${MEDICAL_STAFF} Remove medical service failed`,
  props<{ error: any }>()
);

export const addPersonalDetails = createAction(
  `${MEDICAL_STAFF} Adding personal details`,
  props<{ personalDetails: MedicalStaffPersonalDetailsInterface }>()
);

export const addPersonalDetailsSuccess = createAction(
  `${MEDICAL_STAFF} Adding personal details successfully`,
  props<{ medicalStaff: any }>()
);

export const addPersonalDetailsFailed = createAction(
  `${MEDICAL_STAFF} Adding personal details failed`,
  props<{ error: any }>()
);

export const addFinancialDetails = createAction(
  `${MEDICAL_STAFF} Adding financial details`,
  props<{ financialDetails: MedicalStaffFinancialDetailsInterface }>()
);

export const addFinancialDetailsSuccess = createAction(
  `${MEDICAL_STAFF} Added financial details successfully`,
  props<{ financialDetails: MedicalStaffFinancialDetailsInterface }>()
);

export const addFinancialDetailsFailed = createAction(
  `${MEDICAL_STAFF} Adding financial details failed`,
  props<{ error: any }>()
);

export const getFinancialDetails = createAction(
  `${MEDICAL_STAFF} Get financial details`
);

export const getFinancialDetailsSuccess = createAction(
  `${MEDICAL_STAFF} Get financial details success`,
  props<{ financialDetails: MedicalStaffFinancialDetailsInterface }>()
);

export const getFinancialDetailsFailed = createAction(
  `${MEDICAL_STAFF} Get financial details failed`,
  props<{ error: any }>()
);

export const addAddress = createAction(
  `${MEDICAL_STAFF} Adding address`,
  props<{ servicesArea: MedicalStaffAddressInterface }>()
);

export const addAddressSuccess = createAction(
  `${MEDICAL_STAFF} Added address successfully`,
  props<{ servicesArea: MedicalStaffAddressInterface }>()
);

export const addAddressFailed = createAction(
  `${MEDICAL_STAFF} Adding address failed`,
  props<{ error: any }>()
);

// patients
export const loadMedicalStaffPatients = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Pacients`
);
export const loadMedicalStaffPatientsSuccess = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Pacients Success`,
  props<{ medicalStaffPatients: any }>()
);
export const loadMedicalStaffPatientsFailed = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Pacients Failed`,
  props<{ error: any }>()
);

// selected patient
export const loadMedicalStaffPatient = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient`,
  props<{ patientId: string }>()
);
export const loadMedicalStaffPatientSuccess = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient Success`,
  props<{ patient: Patient }>()
);
export const loadMedicalStaffPatientFailed = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient Failed`,
  props<{ error: any }>()
);

// selected patient address
export const loadMedicalStaffPatientAddresses = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient address`,
  props<{ patientId: string }>()
);
export const loadMedicalStaffPatientAddressesSuccess = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient address Success`,
  props<{ addresses: PatientAddress[] }>()
);
export const loadMedicalStaffPatientAddressesFailed = createAction(
  `${MEDICAL_STAFF} Load Medical Staff selected patient address Failed`,
  props<{ error: any }>()
);

export const loadMedicalStaffAppointments = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Appointments`
);
export const loadMedicalStaffAppointmentsSuccess = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Appointments Success`,
  props<{ medicalStaffAppointments: any }>()
);
export const loadMedicalStaffAppointmentsFailed = createAction(
  `${MEDICAL_STAFF} Loading Medical Staff Appointments Failed`,
  props<{ error: any }>()
);
