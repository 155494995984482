<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Propune interviu</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="formProposal">
  <div class="full-form" *ngIf="!submited" [@slideInOut]>
    <div class="full-form-data">
      <form>
        <ion-list>
          <ion-item lines="full">
            <ion-label position="stacked">Zile disponibile</ion-label>
            <ion-select
              placeholder=""
              (ionChange)="onSelectDay($event)"
              [(ngModel)]="selectedDayModel"
              name="selectedDayModel"
            >
              <ion-select-option *ngFor="let record of data" [value]="record">
                {{ record.date }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item lines="full">
            <ion-label position="stacked">Intervale disponibile</ion-label>
            <ion-select
              placeholder=""
              (ionChange)="onSelectHour($event)"
              [disabled]="!formProposal.controls.raw.valid"
            >
              <ion-select-option
                *ngFor="let record of availableHours"
                [value]="record"
              >
                {{ record.start }} - {{ record.end }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </form>
      <div *ngIf="errors" class="ion-padding">
        <span class="message-errors">{{ errors }}</span>
      </div>
    </div>
    <ion-button
      expand="block"
      class="submit-button"
      color="secondary"
      [disabled]="!formProposal.valid"
      (click)="submit()"
      >{{ loading ? '' : 'Propune interviu' }}
      <ion-spinner *ngIf="loading"></ion-spinner>
    </ion-button>
  </div>

  <div class="result-holder" *ngIf="submited" [@slideInOut]>
    <ion-icon name="checkmark-outline" class="success-icon"></ion-icon>
    <!-- TODO: Go to interviews page to see the record -->
    <p class="result-text">
      Solicitarea de interviu a fost trimisă către îngrijitor. Veți fi notificat
      dacă îngrijitorul acceptă sau refuză, împreună cu data și ora interviului
      în cazul unui răspuns favorabil.
    </p>
    <ion-button
      expand="block"
      color="secondary"
      class="submit-button"
      (click)="dismissToHome()"
    >
      Închide
    </ion-button>
  </div>
</ion-content>
