import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { InterviewData } from 'src/app/services/interview-data.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WidgetConfig } from '../ui/widget/widget.component';
import { ro } from 'date-fns/locale';
import { format } from 'date-fns';

@Component({
  selector: 'app-widget-interviews',
  templateUrl: './interviews-widget.component.html',
  styleUrls: ['./interviews-widget.component.scss'],
})
export class InterviewsWidgetComponent implements OnInit {
  public interviews$: Observable<any>;

  public loading$: Observable<boolean>;

  public isBeneficiary$: Observable<boolean>;

  public error$: Observable<any>;

  public widgetConfig: WidgetConfig = {
    variant: 'tertiary',
    contentHasPadding: true,
    title: 'Interviuri',
    viewAllLink: '/medical-staff/interviews',
    viewAllLinkText: 'Vezi toate',
  };

  public fomatedDate = null;

  constructor(
    private store: Store<fromStore.State>,
    private interviewData: InterviewData
  ) {}

  ngOnInit() {
    this.store.dispatch(fromStore.loadInterviews());

    this.loading$ = this.store.pipe(select(fromStore.selectInterviewsLoading));

    this.error$ = this.store.pipe(select(fromStore.selectInterviewsError));

    this.isBeneficiary$ = this.store.pipe(
      select(fromStore.selectCurrentUser),
      map((user) => {
        return +user.accountType === 1;
      })
    );

    this.interviews$ = this.store.pipe(
      select(fromStore.selectInterviews),
      filter((interviews) => !!interviews),
      map((interviews) => {
        return this.interviewData.normalize(interviews).slice(0, 3);
      })
    );

    // const dateFormated = format(new Date('2021-04-05 22:00:00'), 'PPPP', {
    //   locale: ro,
    // });
    // const timeFormated = format(new Date('2021-04-05 22:00:00'), 'M MMMM', {
    //   locale: ro,
    // });
    // const hourFormated = format(new Date('2021-04-05 22:00:00'), 'p', {
    //   locale: ro,
    // });
    // console.log('dateFormated', dateFormated);
    // console.log('dateFormated', timeFormated);
    // const splitDate: any = dateFormated.split(',');
    // const splitTime: any = timeFormated.split(',');
    // this.fomatedDate = {
    //   dayOfWeek: splitDate[0].charAt(0).toUpperCase() + splitDate[0].slice(1),
    //   dayDate: timeFormated,
    //   hour: hourFormated,
    // };
  }
}
