<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="profile/medical-staff"></ion-back-button>
    </ion-buttons>
    <ion-title>Detalii Financiare</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form class="full-form" [formGroup]="financialDetailsForm" *ngIf="(financialDetails$ | async) else loading">
    <div class="full-form-data">
      <ion-list>
        <ion-item>
          <ion-label position="stacked">
            Cont Bancar
          </ion-label>
          <ion-input formControlName="bankAccountNumber"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">
            Numa Banca
          </ion-label>
          <ion-input formControlName="bankName"></ion-input>
        </ion-item>
      </ion-list>

      <div *ngIf="(errors$ | async) as errors" class="errors">
        <p *ngFor="let error of errors">
          {{error}}
        </p>
      </div>
    </div>

    <ion-button class="submit-button" expand="block" color="secondary"
                [disabled]="updating$ | async" (click)="submit()">
      <span *ngIf="!(updating$ | async)">
        Salvează
      </span>
      <span *ngIf="updating$ | async">
        <ion-spinner></ion-spinner>
      </span>
    </ion-button>
  </form>
</ion-content>

<ng-template #loading>
  <div class="ion-padding">
    <ion-spinner class="center-spinner"></ion-spinner>
  </div>
</ng-template>
