import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AbstractApiService } from './abstract-api.service';
import { Patient } from '../models/patient';
import { PatientAddress } from '../models/patient-address';

@Injectable({ providedIn: 'root' })
export class PatientApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Get All Patients
   */
  getPatients(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/patients`).pipe(
      map(patients => patients),
      catchError(err => throwError(err)),
    );
  }

  /**
   * Get patient by id
   */
  getPatient(patientId: string): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/patients/${patientId}`).pipe(
      map(patients => patients),
      catchError(err => throwError(err)),
    );
  }

  /**
   * Add patient to current logged in benefiary
   *
   * @param patient Patient
   */
  addPatient(patient: Patient): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/patients`,
    {
      ...patient,
    }).pipe(
      map(patients => patients),
      catchError(err => throwError(err)),
    );
  }

  /**
   * Add a patient address
   *
   * @param patientAddress PatientAddress interface
   */
  addPatientAddress(patientAddress: PatientAddress): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/patients/${patientAddress.id}/addresses`,
    {
      ...patientAddress,
    }).pipe(
      map(address => address),
      catchError(err => throwError(err)),
    );
  }

  getAppointments(): Observable<any> {
    return this.http.get(`${this.getBaseUrl()}/appointments`).pipe(
      map(address => address),
      catchError(err => throwError(err)),
    );
  }
}
