import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractApiService } from '../http/abstract-api.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiProfileStatusService extends AbstractApiService {
  constructor(private http: HttpClient) {
      super();
  }

  /**
   * Get medical staff profile status (complete/incomplete)
   *
   * @param authToken The authentication token
   *
   * @returns Observable
   */
  getProfileStatus(authToken: string): Observable<any> {
    return this.http.post(`${this.getBaseUrl()}/admin/medical-staff/status`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }).pipe(
      map((result) => result),
      catchError((err) => throwError(err))
    );
  }
}
