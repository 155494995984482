import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Invoice } from 'src/app/core/models/invoice.model';

@Component({
  selector: 'beneficiary-invoice',
  templateUrl: './beneficiary-invoice.component.html',
  styleUrls: ['./beneficiary-invoice.component.scss'],
})
export class BeneficiaryInvoiceComponent implements OnInit {
  @Input()
  data: Invoice;

  @Output()
  submit: EventEmitter<Invoice> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onSubmit() {
    this.submit.next(this.data);
  }
}
