import { createAction, props } from '@ngrx/store';
import { MedicalReport } from '../../core/http/medical-report-api.service';

const MEDICAL_REPORT = '[Medical report]';

// fetch all
export const loadMedicalReports = createAction(
  `${MEDICAL_REPORT} Fetch medical reports`,
  props<{ patientId: string }>()
);

export const loadMedicalReportsSuccess = createAction(
  `${MEDICAL_REPORT} Fetch medical reports successfully`,
  props<{ medicalReports: MedicalReport[] }>()
);

export const loadMedicalReportsFailed = createAction(
  `${MEDICAL_REPORT} Fetch medical reports failed`,
  props<{ error: any }>()
);

// select
export const setCurrentMedicalReport = createAction(
  `${MEDICAL_REPORT} Select medical report`,
  props<{ report: MedicalReport }>()
);

// fetch by id
export const loadMedicalReport = createAction(
  `${MEDICAL_REPORT} Fetch medical report`,
  props<{ patientId: string; reportId: string }>()
);

export const loadMedicalReportSuccess = createAction(
  `${MEDICAL_REPORT} Fetch medical report successfully`,
  props<{ medicalReport: MedicalReport }>()
);

export const loadMedicalReportFailed = createAction(
  `${MEDICAL_REPORT} Fetch medical report failed`,
  props<{ error: any }>()
);

// approve report
export const approveMedicalReport = createAction(
  `${MEDICAL_REPORT} Approve medical report`,
  props<{ patientId: any; reportId: string }>()
);
export const approveMedicalReportSuccess = createAction(
  `${MEDICAL_REPORT} Approve medical report Success`,
  props<{ patientId: any; reportId: string }>()
);
export const approveMedicalReportFailed = createAction(
  `${MEDICAL_REPORT} Approve medical report Failed`,
  props<{ error: any }>()
);
