import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Observer } from 'rxjs';
import { map, mergeMap, catchError, shareReplay, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class GeoCodingService {
  geocoder: google.maps.Geocoder;

  latestLatLng: any;

  loadedMap$: Observable<boolean>;

  constructor(private httpClient: HttpClient) {}

  loadMap$(): Observable<boolean> {
    this.loadedMap$ = this.httpClient.jsonp(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`,
      'callback'
    ).pipe(
        map(() => true),
        shareReplay(1),
        catchError(() => of(false)),
    );

    return this.loadedMap$;
  }

  getCoordinates(address: string): Observable<any> {
    return this.loadedMap$.pipe(
      mergeMap((googleMapLoaded: boolean): any => {
        if (googleMapLoaded) {
          this.geocoder = new google.maps.Geocoder();

          return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
              // Invokes geocode method of Google Maps API geocoding.
              this.geocoder.geocode({ address }, (
                  (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                      if (status === google.maps.GeocoderStatus.OK) {
                          observer.next(results);
                          observer.complete();
                      } else {
                          console.log('Geocoding service: geocoder failed due to: ' + status);
                          observer.error(status);
                      }
                  })
              );
          });
        }
      }),
    );
  }
}
