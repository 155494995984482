<div *ngFor="let contract of data">
  <ion-card color="tertiary" [@slideInOut]>
    <ion-card-header class="pb-0">
      <div
        class="d-flex d-flex-row ion-justify-content-between ion-align-items-center"
      >
        <ion-text class="font-18 font-bold color--primary"> Contract </ion-text>

        <ion-badge [color]="contract.ui.variant" class="w-50">
          {{ contract.ui.statusTranslate }}
        </ion-badge>
      </div>
    </ion-card-header>

    <ion-card-content>
      <ion-list class="list-menu font-18" lines="full">
        <ion-item>
          <ion-text> Pacient </ion-text>
          <div slot="end" class="font-bold">
            {{ contract.patient.firstName }}
            {{ contract.patient.lastName }}
          </div>
        </ion-item>
        <ion-item lines="none">
          <div>Cadru Medical</div>
          <div slot="end" class="font-bold">
            {{ contract.medicalStaff.firstName }}
            {{ contract.medicalStaff.lastName }}
          </div>
        </ion-item>
      </ion-list>

      <div class="row-buttons">
        <ion-button
          expand="block"
          (click)="onRateMedicalStaff(contract.medicalStaff.identifier)"
          *ngIf="contract.status === 4"
          color="secondary"
        >
          Recenzie cadru medical
        </ion-button>
        <ion-button
          expand="block"
          (click)="onViewContract(contract)"
          [class.w-50]="contract.status === 4"
          [color]="contract.status === 4 ? 'medium' : 'primary'"
        >
          Vezi tot contractul
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</div>
