import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromStore from '../../store';
import {Observable, Subscription} from 'rxjs';
import {WidgetConfig} from '../ui/widget/widget.component';
import {filter, map} from 'rxjs/operators';

@Component({
    selector: 'app-widget-vizite',
    templateUrl: './vizite.component.html',
    styleUrls: ['./vizite.component.scss'],
})
export class VisitsWidgetComponent implements OnInit {
    public appointments$: Observable<any>;

    private currentUserSubscription$: Subscription;

    public loading$: Observable<boolean>;

    public isBeneficiary = false;

    public error$: Observable<any>;

    public widgetConfig: WidgetConfig = {
        variant: 'tertiary',
        contentHasPadding: true,
        title: '',
        viewAllLink: '',
        viewAllLinkText: 'Vezi toate'
    };

    constructor(
        private store: Store<fromStore.State>,
    ) {
    }

    ngOnInit() {
        this.currentUserSubscription$ = this.store.pipe(
            select(fromStore.selectCurrentUser),
        ).subscribe(user => {
            switch (+user.accountType) {
                case 2:
                    this.isBeneficiary = false;
                    break;
                case 1:
                default:
                    this.isBeneficiary = true;
            }

            this.updateWidgetConfig();
            this.isBeneficiary ? this.loadBeneficiaryAppointments() : this.loadMedicalStaffAppointments();
        });
    }

    private updateWidgetConfig() {
        this.widgetConfig.title = this.isBeneficiary ? 'Vizite' : 'Programări';
        this.widgetConfig.viewAllLink = this.isBeneficiary ? '/beneficiary/visits' : '/medical-staff/appointments';
    }

    private loadMedicalStaffAppointments() {
        this.error$ = this.store.pipe(
            select(fromStore.selectMedicalStaffAppointmentsError)
        );

        this.loading$ = this.store.pipe(
            select(fromStore.selectMedicalStaffAppointmentsLoading)
        );

        this.store.dispatch(fromStore.loadMedicalStaffAppointments());

        this.appointments$ = this.store.pipe(
            select(fromStore.selectMedicalStaffAppointments),
            filter(appointments => !!appointments),
            map(appointments => {
                return appointments.length > 3 ? appointments.slice(0, 3) : appointments;
            })
        );
    }

    private loadBeneficiaryAppointments() {
        this.error$ = this.store.pipe(
            select(fromStore.selectBeneficiaryAppointmentsError)
        );

        this.loading$ = this.store.pipe(
            select(fromStore.selectBeneficiaryAppointmentsLoading)
        );

        // TODO: ad logic for beneficiary
        this.store.dispatch(fromStore.loadBeneficiaryAppointments());

        this.appointments$ = this.store.pipe(
            select(fromStore.selectBeneficiaryAppointments),
            filter(appointemnts => !!appointemnts),
            map(appointemnts => {
                appointemnts = appointemnts.length > 3 ? appointemnts.slice(0, 3) : appointemnts;
                return appointemnts.map(item => {
                    return {
                        ...item,
                        itemConfig: {
                            variant: 'warning'
                        }
                    };
                });
            })
        );
    }
}
