<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button
        text=""
        defaultHref="profile/medical-staff"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Servicii Medicale</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding">
    <ion-spinner *ngIf="fetching$ | async" class="center-spinner"></ion-spinner>

    <ng-container *ngIf="medicalServices$ | async as medicalServices">
      <ion-list *ngIf="medicalServices.length" class="list-menu" lines="full">
        <ion-item
          button
          detail="false"
          *ngFor="let medicalService of medicalServices"
        >
          <ion-label>
            <div class="font-bold">{{ medicalService.name | titlecase }}</div>
            <div class="font-20">{{ medicalService.currentRate }}</div>
          </ion-label>

          <ion-icon
            (click)="removeMedicalService(medicalService.identifier.toString())"
            name="trash"
            color="primary"
            slot="end"
          >
          </ion-icon>
        </ion-item>
      </ion-list>

      <div
        *ngIf="!medicalServices.length"
        class="ion-padding center-float-message"
      >
        Momentan nu ai servicii medicale adăugate.
      </div>
    </ng-container>
  </div>
</ion-content>

<ion-fab
  *ngIf="medicalServices$ | async as medicalServices"
  (click)="addMedicalService()"
  [vertical]="medicalServices.length ? 'bottom' : 'center'"
  [horizontal]="medicalServices.length ? 'end' : 'center'"
  slot="fixed"
>
  <ion-fab-button color="secondary">
    <ion-icon name="add-outline" color="primary"></ion-icon>
  </ion-fab-button>
</ion-fab>
