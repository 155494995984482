import { Component, Input, OnInit } from '@angular/core';

import { GeoCodingService } from '../../map/services/geocoding.service';

@Component({
  selector: 'app-map-page',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapPageComponent implements OnInit {
  @Input() street: string;
  @Input() streetNumber: string;

  constructor(public geoCodingService: GeoCodingService) {}

  ngOnInit() {
    this.geoCodingService.getCoordinates(`${this.street} ${this.streetNumber}`);
  }
}
