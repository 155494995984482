import {Component, Input, OnInit} from '@angular/core';

export interface ListItemConfig {
  iconName: string;
  avatarName: string;
  variant: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger';
}

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  @Input() config: ListItemConfig;

  public avatarUrl = 'https://eu.ui-avatars.com/api/?size=500&background=63bbff&font-size=0.55&bold=false&color=0B1865&format=svg&name=';

  constructor() {
  }

  ngOnInit() {
    this.avatarUrl += this.config.avatarName;
  }

}
