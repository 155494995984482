import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MedicalReport } from '../../../core/http/medical-report-api.service';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { selectCurrentUser } from '../../../store';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-medical-record-modal',
  templateUrl: './medical-report-modal.component.html',
  styleUrls: ['./medical-report-modal.component.scss'],
})
export class MedicalReportModalComponent implements OnInit {
  @Input()
  public medicalReport$: Observable<MedicalReport>;

  @Input()
  public patientId: string;

  public isBeneficary$: Observable<boolean> = this.store
    .select(selectCurrentUser)
    .pipe(map((user) => user.accountType === 1));

  constructor(
    private modalController: ModalController,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {}

  public approveMedicalReport(reportId) {
    this.store.dispatch(
      fromStore.approveMedicalReport({
        patientId: this.patientId,
        reportId,
      })
    );
  }

  public async onClose() {
    await this.modalController.dismiss();
  }
}
