import { Action, createReducer, on } from '@ngrx/store';


import * as fromProfileStatusActions from '../actions/profileStatus.actions';

export interface ProfileStatusState {
  response: any;
}

export const initialState: ProfileStatusState = {
  response: {},
};

const ProfileStatusReducer = createReducer(
  initialState,
  on(fromProfileStatusActions.loadProfileStatusComplete, (state: ProfileStatusState, payload) => ({
    ...state,
    profileStatus: payload
  })),
  on(fromProfileStatusActions.loadProfileStatusIncomplete, (state: ProfileStatusState, payload) => ({
    ...state,
    profileStatus: payload
  })),
);

export function reducer(state: ProfileStatusState | undefined, action: Action): ProfileStatusState {
  return ProfileStatusReducer(state, action);
}

