<ion-header xmlns="http://www.w3.org/1999/html">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="profile/medical-staff"></ion-back-button>
    </ion-buttons>
    <ion-title>Documente cadru medical</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content>
  <ng-container *ngIf="!(routeData$ | async) && (loading$ | async)">
    <ion-spinner class="center-spinner"></ion-spinner>
  </ng-container>

  <ng-container *ngIf="fetchError$ | async as error">
    <div class="ion-padding">
      {{ error.message }}
    </div>
  </ng-container>

  <ion-grid class="ion-no-padding" color="tertiary" *ngIf="(routeData$ | async) && !(loading$ | async)" [@slideInOut]>
    <ion-row *ngFor="let category of (routeData$ | async).categories" class="ion-padding ion-border-top-1">
      <ion-col class="ion-text-center">
        <div class="upload-section">
          <ion-text class="upload-section-title">{{category.title}}</ion-text>

          <input type="file" accept="image/*, .pdf" style="display: none" #input
                 (change)="uploadFile($event, category.name)" [multiple]="false"/>

          <ion-button class="ion-margin" color="secondary" expand="block" (click)="populateInputFile(input)">
            <div class="children-display-block">
              <ion-spinner *ngIf="category.loading">
              </ion-spinner>
              <ion-text *ngIf="!category.loading"> Încarcă document</ion-text>
            </div>
          </ion-button>
          <ion-text color="primary">
            <small class="ion-font-size-12"> (.pdf, .jpg, .png) </small>
          </ion-text>
        </div>

        <div *ngIf="(routeData$ | async).files.length">
          <ion-list lines="full" class="list-menu" *ngFor="let file of (routeData$ | async).files">
            <ion-item *ngIf="file.documentType === category.name" detail detailicon="chevron-forward-outline"
                      (click)="openFile($event, file.uuid)">
              <ion-text class="ion-font-size-14 link">
                {{ file.filename }}
              </ion-text>
              <ion-spinner color="secondary" class="ion-margin-horizontal" *ngIf="file.loading">
              </ion-spinner>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
