<ng-container *ngIf="user$ | async as currentUser">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <img
          width="100"
          src="assets/logo_HomeCare_int.svg"
          alt="HOMECARE"
          style="margin-left: 10px"
        />
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button [routerLink]="[profileLink]">
          <ion-icon slot="icon-only" name="person-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <app-widget-interviews></app-widget-interviews>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <app-widget-vizite></app-widget-vizite>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <app-widget-pacienti></app-widget-pacienti>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <widget-contracts [type]="userType"></widget-contracts>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>

  <ion-footer style="height: 56px">
    <app-tabs></app-tabs>
  </ion-footer>
</ng-container>

<!--TODO-->
<!-- Contracte semnteaza -->
<!-- Programari -->
