<ion-tabs *ngIf="currentUser$ | async as currentUser">
  <ion-tab-bar color="secondary">
<!--    <ion-tab-button  routerLink="/components" routerLinkActive="active">-->
<!--      <ion-icon name="logo-web-component"></ion-icon>-->
<!--      &lt;!&ndash;      <ion-label>Acasă</ion-label>&ndash;&gt;-->
<!--    </ion-tab-button>-->

    <ion-tab-button routerLink="/home" routerLinkActive="active">
      <ion-icon name='home-outline'></ion-icon>
      <!--      <ion-label>Acasă</ion-label>-->
    </ion-tab-button>

    <ion-tab-button *ngIf="+currentUser.accountType !== 2" routerLink="/patients"
                    routerLinkActive="active">
      <ion-icon name="people-outline"></ion-icon>
      <!--      <ion-label>Pacienți</ion-label>-->
    </ion-tab-button>

    <ion-tab-button *ngIf="+currentUser.accountType !== 1"
                    routerLink="/medical-staff/appointments"
                    routerLinkActive="active">
      <ion-icon name="calendar-outline"></ion-icon>
      <!--      <ion-label>Programări</ion-label>-->
    </ion-tab-button>


    <ion-tab-button *ngIf="+currentUser.accountType !== 2"
                    routerLink="/medical-staff/search" routerLinkActive="active">
      <ion-icon name="search-outline"></ion-icon>
      <!--      <ion-label>Caută cadre</ion-label>-->
    </ion-tab-button>

    <ion-tab-button
      [routerLink]="[+currentUser.accountType === 2 ? '/contracts/medical-staff' : '/contracts/beneficiary']"
      routerLinkActive="active">
      <ion-icon name="newspaper-outline"></ion-icon>
      <!--      <ion-label>Contracte</ion-label>-->
    </ion-tab-button>

    <ion-tab-button [routerLink]="profileLinks[currentUser.accountType]"
                    routerLinkActive="active">
      <ion-icon name="settings-outline"></ion-icon>
      <!--      <ion-label>Cont</ion-label>-->
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
