import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Invoice } from 'src/app/core/models/invoice.model';
import * as fromBeneficiaryInvoiceActions from '../actions/beneficiary-invoice.actions';

export const beneficiaryInvoiceAdapter: EntityAdapter<Invoice> =
  createEntityAdapter<Invoice>({});

export interface BeneficiaryInvoiceState extends EntityState<Invoice> {
  loading: boolean;
  error: any;
}

export const initialState: BeneficiaryInvoiceState =
  beneficiaryInvoiceAdapter.getInitialState({
    loading: false,
    error: null,
  });

const beneficiaryInvoiceReducer = createReducer(
  initialState,

  // FETCH
  on(
    fromBeneficiaryInvoiceActions.loadBeneficiaryInvoices,
    (state: BeneficiaryInvoiceState) => ({
      ...state,
      error: null,
      loading: true,
    })
  ),
  on(
    fromBeneficiaryInvoiceActions.loadBeneficiaryInvoicesSuccess,
    (state: BeneficiaryInvoiceState, { invoices }) =>
      beneficiaryInvoiceAdapter.setAll(invoices, state)
  ),
  on(
    fromBeneficiaryInvoiceActions.loadBeneficiaryInvoicesFailed,
    (state: BeneficiaryInvoiceState, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  // PAY INVOICE
  on(
    fromBeneficiaryInvoiceActions.payInvoice,
    (state: BeneficiaryInvoiceState, { id }) =>
      beneficiaryInvoiceAdapter.updateOne(
        { id, changes: { ui: { ...state.entities[id].ui, isUpdating: true } } },
        state
      )
  ),
  on(
    fromBeneficiaryInvoiceActions.payInvoiceSuccess,
    (state: BeneficiaryInvoiceState, { id }) =>
      beneficiaryInvoiceAdapter.updateOne(
        {
          id,
          changes: { ui: { ...state.entities[id].ui, isUpdating: false } },
        },
        state
      )
  ),
  on(
    fromBeneficiaryInvoiceActions.payInvoiceFailed,
    (state: BeneficiaryInvoiceState, { id }) =>
      beneficiaryInvoiceAdapter.updateOne(
        {
          id,
          changes: { ui: { ...state.entities[id].ui, isUpdating: false } },
        },
        state
      )
  )
);

export function reducer(
  state: BeneficiaryInvoiceState | undefined,
  action: Action
): BeneficiaryInvoiceState {
  return beneficiaryInvoiceReducer(state, action);
}
