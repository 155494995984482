import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {MedicalStaffComponent} from './pages/medical-staff/profile/medical-staff.component';
import {HomeComponent} from './pages/home/home.component';
import {BeneficiaryProfileComponent} from './pages/beneficiary/profile/profile.component';
import {BeneficiaryReceiptPaymentComponent} from './pages/beneficiary/receipt-payment/receipt-payment.component';
import {ReceiptHistoryComponent} from './pages/beneficiary/receipt-payment/receipt-history/receipt-history.component';
import {ReceiptSettingsComponent} from './pages/beneficiary/receipt-payment/receipt-settings/receipt-settings.component';
import {CardsComponent} from './pages/beneficiary/receipt-payment/cards/cards.component';
import {BeneficiaryPersonalDataComponent} from './pages/beneficiary/personal-data/personal-data.component';
import {MedicalStaffListingComponent} from './pages/medical-staff/medical-staff-listing/medical-staff-listing.component';
import {MedicalStaffDocumentsComponent} from './pages/medical-staff/documents/documents.component';
import {MedicalStaffViewDocumentComponent} from './pages/medical-staff/view-document/view-document.component';
import {MapPageComponent} from './pages/map/map.component';
import {ListInterviewsComponent} from './pages/list-interviews/list-interviews.component';
import {ViewProfileComponent} from './pages/medical-staff/view-profile/view-profile.component';
import {PersonalDetailsComponent} from './pages/medical-staff/personal-details/personal-details.component';
import {InterviewComponent} from './pages/interview/interview.component';

import {FinancialDetailsComponent} from './pages/medical-staff/financial-details/financial-details.component';
import {ServicesSupplyComponent} from './pages/medical-staff/services-supply/services-supply.component';
import {MedicalStaffScheduleEditRouteComponent} from './pages/medical-staff/medical-staff-schedule-edit-route/medical-staff-schedule-edit-route.component';
import {MedicalStaffScheduleExceptionEditRouteComponent} from './pages/medical-staff/medical-staff-schedule-exception-edit-route/medical-staff-schedule-exception-edit-route.component';
import {MedicalStaffScheduleComponent} from './pages/medical-staff/medical-staff-schedule/medical-staff-schedule.component';
import {ListPatientsComponent} from './pages/medical-staff/view-patient-profile/list-patients/list-patients.component';
import {AppointmentsComponent} from './pages/medical-staff/appointments/appointments.component';
import {AppTabsComponent} from './components/app-tabs/app-tabs.component';
import {VisitsComponent} from './pages/beneficiary/visits/visits.component';
import {AppThemeComponentsComponent} from './pages/app-theme-components/app-theme-components.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },

  {
    path: 'components',
    canActivate: [AuthGuard],
    component: AppThemeComponentsComponent,
  },

  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },

  {
    path: 'medical-staff/appointments',
    canActivate: [AuthGuard],
    component: AppointmentsComponent,
  },

  {
    path: 'patients',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/patient/patient.module').then(m => m.PatientModule)
  },

  {
    path: 'medical-staff',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/medical-staff-search/medical-staff-search.module').then(m => m.MedicalStaffSearchModule)
  },

  {
    path: 'contracts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contracts/contracts.module').then(m => m.ContractsModule)
  },

  {
    path: 'profile/medical-staff',
    canActivate: [AuthGuard],
    component: MedicalStaffComponent,
  },

  {
    path: 'profile/beneficiary',
    canActivate: [AuthGuard],
    component: BeneficiaryProfileComponent,
  },

  {
    path: 'profile/medical-staff/personal-details',
    canActivate: [AuthGuard],
    component: PersonalDetailsComponent,
  },

  {
    path: 'profile/medical-staff/list',
    canActivate: [AuthGuard],
    component: MedicalStaffListingComponent,
  },

  {
    path: 'profile/medical-staff/schedule',
    component: MedicalStaffScheduleComponent,
    children: [
      {
        path: 'schedule-edit',
        canActivate: [AuthGuard],
        component: MedicalStaffScheduleEditRouteComponent
      },
      {
        path: 'schedule-exception-edit',
        canActivate: [AuthGuard],
        component: MedicalStaffScheduleExceptionEditRouteComponent
      },
      {
        path: '',
        redirectTo: 'schedule-edit',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'profile/medical-staff/financial-details',
    canActivate: [AuthGuard],
    component: FinancialDetailsComponent,
  },
  {
    path: 'profile/medical-staff/services',
    canActivate: [AuthGuard],
    component: ServicesSupplyComponent,
  },
  {
    path: 'medical-staff/documents',
    canActivate: [AuthGuard],
    component: MedicalStaffDocumentsComponent,
  },
  {
    path: 'medical-staff/document/:uuid/:type',
    canActivate: [AuthGuard],
    component: MedicalStaffViewDocumentComponent,
  },
  {
    path: 'medical-staff/interviews',
    canActivate: [AuthGuard],
    component: ListInterviewsComponent,
  },
  {
    path: 'medical-staff/profile/:profileID',
    canActivate: [AuthGuard],
    component: ViewProfileComponent,
  },
  {
    path: 'beneficiary/personal-data',
    component: BeneficiaryPersonalDataComponent,
  },
  {
    path: 'beneficiary/visits',
    canActivate: [AuthGuard],
    component: VisitsComponent,
  },
  {
    path: 'beneficiary/payments',
    canActivate: [AuthGuard],
    component: BeneficiaryReceiptPaymentComponent,
    children:
      [
        {
          path: '1',
          children:
            [
              {
                path: '',
                component: ReceiptHistoryComponent
              }
            ]
        },
        {
          path: '2',
          children:
            [
              {
                path: '',
                component: ReceiptSettingsComponent
              }
            ]
        },
        {
          path: '3',
          children:
            [
              {
                path: '',
                component: CardsComponent
              }
            ]
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: '1'
        }
      ]
  },
  {
    path: 'map',
    component: MapPageComponent,
  },
  {
    path: 'interview/:id/:peerName',
    canActivate: [AuthGuard],
    component: InterviewComponent,
  },

  {
    path: 'medical-staff',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/medical-staff/view-patient-profile/view-patient-profile.module').then(
      m => m.ViewPatientProfilePageModule
    )
  },

  {
    path: 'static',
    canActivate: [AuthGuard],
    loadChildren: () => import ('./pages/static-routes/static-routes.module').then(m => m.StaticRoutesModule)
  },
  {
    path: 'view-patient-profile',
    loadChildren: () => import('./pages/medical-staff/view-patient-profile/view-patient-profile.module').then(
      m => m.ViewPatientProfilePageModule
    )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
