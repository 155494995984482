import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../services/contracts/contracts-api.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'contract-details-modal',
  templateUrl: './contract-details-modal.component.html',
  styleUrls: ['./contract-details-modal.component.css']
})
export class ContractDetailsModalComponent implements OnInit {

  @Input()
  contract: Contract;

  @Input()
  type: string;


  constructor(
    private modalController: ModalController
  ) {
  }

  ngOnInit(): void {
  }

  public async onClose() {
    await this.modalController.dismiss();
  }

  public async onSubmit() {
    await this.modalController.dismiss(null, 'submit');
  }
}
