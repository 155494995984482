import {
  ActionReducerMap,
  MetaReducer,
  ActionReducer,
  INIT,
} from '@ngrx/store';

import { environment } from '../../../environments/environment';

import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducer';
import * as fromPatients from './patients.reducer';
import * as fromProfileStatus from './profileStatus.reducer';
import * as fromMedicalStaff from './medicalstaff.reducer';
import * as fromWidgets from './widgets.reducer';
import * as fromContracts from './contracts.reducer';
import * as fromMedicalStaffProfile from './medical-staff-profile.reducer';
import * as fromMedicalReport from './medical-report.reducer';
import * as fromBeneficiaryInvoice from './beneficiary-invoice.reducer';
// import * as fromMedicalStaffSchedule from './medical-staff-schedule.reducer';

import * as fromActions from '../actions';

export interface State {
  auth: fromAuth.AuthState;
  patient: fromPatients.PatientsState;
  profileStatus: fromProfileStatus.ProfileStatusState;
  medicalStaff: fromMedicalStaff.MedicalStaffState;
  widgets: fromWidgets.WidgetsState;
  contracts: fromContracts.ContractsState;
  medicalStaffProfile: fromMedicalStaffProfile.MedicalStaffProfileState;
  medicalReport: fromMedicalReport.MedicalReportState;
  beneficiaryInvoice: fromBeneficiaryInvoice.BeneficiaryInvoiceState;
  // medicalStaffSchedule: fromMedicalStaffSchedule.MedicalStaffScheduleState;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  patient: fromPatients.reducer,
  profileStatus: fromProfileStatus.reducer,
  medicalStaff: fromMedicalStaff.reducer,
  widgets: fromWidgets.reducer,
  contracts: fromContracts.reducer,
  medicalStaffProfile: fromMedicalStaffProfile.reducer,
  medicalReport: fromMedicalReport.reducer,
  beneficiaryInvoice: fromBeneficiaryInvoice.reducer,
  // medicalStaffSchedule: fromMedicalStaffSchedule.reducer
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth'],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer, clearState]
  : [localStorageSyncReducer, clearState];

export function getInitialState() {
  return {
    auth: fromAuth.initialState,
  };
}

export function clearState(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return function clearStateFn(state, action) {
    if (action !== null && action.type === fromActions.AuthActionTypes.Logout) {
      return reducer(undefined, { type: INIT });
    }

    return reducer(state, action);
  };
}

export { AuthState, RegisterState } from './auth.reducer';
export { PatientsState } from './patients.reducer';
export { ProfileStatusState } from './profileStatus.reducer';
export { MedicalStaffState } from './medicalstaff.reducer';
export { WidgetsState } from './widgets.reducer';
export { ContractsState } from './contracts.reducer';
export { MedicalStaffProfileState } from './medical-staff-profile.reducer';
export { MedicalReportState } from './medical-report.reducer';
export { BeneficiaryInvoiceState } from './beneficiary-invoice.reducer';
// export {MedicalStaffScheduleState} from './medical-staff-schedule.reducer';
