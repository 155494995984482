import {Action, createReducer, on} from '@ngrx/store';

import {MedicalStaffInterface} from '../../core/models/medicalstaff.interface';
import {DocumentsInterface} from 'src/app/core/models/documents.interface';
import {MedicalStaffService, MedicalStaffServiceGroup} from 'src/app/core/models/medical-staff-service';

import * as fromMedicalStaffActions from '../actions/medicalstaff.actions';
import {MedicalStaffPersonalDetailsInterface} from 'src/app/core/models/medical-staff-personal-details.interface';
import {MedicalStaffFinancialDetailsInterface} from 'src/app/core/models/medical-staff-financial-details.interface';
import {MedicalStaffAddressInterface} from '../../core/models/medical-address.interface';
import {Patient} from '../../core/models/patient';
import {PatientAddress} from '../../pages/patient/model/patient.model';
import {MedicalStaffDocumentsCategory, MedicalStaffDocumentsRouteData} from '../../pages/medical-staff/documents/documents.component';

export interface MedicalStaffState {
  // ??
  medicalStaff: MedicalStaffInterface[];

  // medical staff available services
  medicalStaffAvailableServices: MedicalStaffServiceGroup[];
  medicalStaffAvailableServicesFetching: boolean;
  medicalStaffAvailableServicesError: any;

  // medical services
  medicalStaffServices: MedicalStaffService;
  medicalStaffServicesUpdated: boolean;
  medicalStaffServicesFetching: boolean;
  medicalStaffServicesUpdating: boolean;
  medicalStaffServicesError: any;

  // patients
  medicalStaffPatients: Patient[];
  medicalStaffPatientsError: any;
  medicalStaffPatientsLoading: boolean;

  // patient
  selectedPatient: {
    patient: {
      data: Patient,
      error: any;
      loading: boolean;
    };
    addresses: {
      data: PatientAddress[],
      error: any;
      loading: boolean;
    }
  };

  // appointments
  medicalStaffAppointments: any;
  medicalStaffAppointmentsError: any;
  medicalStaffAppointmentsLoading: boolean;

  // personal details
  personalDetails: MedicalStaffPersonalDetailsInterface;

  // financial details
  financialDetails: MedicalStaffFinancialDetailsInterface;
  financialDetailsError: any;
  financialDetailsUpdating: boolean;

  // profile
  medicalStaffProfile: any;
  medicalStaffProfileError: any;

  documents: {
    categories: MedicalStaffDocumentsCategory[];
    data: DocumentsInterface[];
    loading: boolean;
    fetchError: any;
    uploadError: any;
    downloadedDocument: {
      data: Blob;
      error: any;
    }
  };

  // address
  servicesArea: MedicalStaffAddressInterface;
  servicesAreaUpdating: boolean;
  servicesAreaError: any;

  error: any;
}

export const initialState: MedicalStaffState = {
  // ??
  medicalStaff: null,

  // medical staff available services
  medicalStaffAvailableServices: null,
  medicalStaffAvailableServicesFetching: false,
  medicalStaffAvailableServicesError: null,

  // medical services
  medicalStaffServices: null,
  medicalStaffServicesUpdated: false,
  medicalStaffServicesFetching: false,
  medicalStaffServicesUpdating: false,
  medicalStaffServicesError: null,

  // patients
  medicalStaffPatients: null,
  medicalStaffPatientsError: null,
  medicalStaffPatientsLoading: false,

  // selected patient
  selectedPatient: {
    patient: {
      data: null,
      error: null,
      loading: false
    },
    addresses: {
      data: null,
      error: null,
      loading: false
    }
  },

  // appointments
  medicalStaffAppointments: null,
  medicalStaffAppointmentsError: null,
  medicalStaffAppointmentsLoading: false,

  // personal details
  personalDetails: null,

  // financial details
  financialDetails: null,
  financialDetailsError: null,
  financialDetailsUpdating: false,

  // profile
  medicalStaffProfile: null,
  medicalStaffProfileError: null,

  // documents
  documents: {
    categories: [
      {
        title: 'Carte de identitate',
        name: 'identityCard',
        loading: false
      },
      {
        title: 'Cazier',
        name: 'record',
        loading: false
      },
      {
        title: 'Altele',
        name: 'other',
        loading: false
      }
    ],
    data: null,
    loading: false,
    fetchError: null,
    uploadError: null,
    downloadedDocument: {
      data: null,
      error: null
    }
  },

  // address
  servicesArea: null,
  servicesAreaUpdating: false,
  servicesAreaError: null,

  error: null,
};

const medicalStaffReducer = createReducer(
  initialState,

  on(fromMedicalStaffActions.uploadMedicalStaffDocument, (state: MedicalStaffState, payload) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        categories: [...state.documents.categories.map(category => ({...category, loading: category.name === payload.documentType}))]
      }
    };
  }),
  on(fromMedicalStaffActions.uploadMedicalStaffDocumentSuccess, (state: MedicalStaffState, payload) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        categories: [...state.documents.categories.map(category => ({...category, loading: false}))],
        data: [...state.documents.data, payload.file]
      }
    };
  }),
  on(fromMedicalStaffActions.uploadMedicalStaffDocumentError, (state: MedicalStaffState, payload) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        categories: [...state.documents.categories.map(category => ({...category, loading: false}))],
        uploadError: payload.error
      }
    };
  }),

  on(fromMedicalStaffActions.getAllMedicalStaffDocuments, (state: MedicalStaffState) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        loading: true
      }
    };
  }),
  on(fromMedicalStaffActions.getDocumentsSuccessful, (state: MedicalStaffState, {documents}) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        data: documents,
        loading: false
      }
    };
  }),
  on(fromMedicalStaffActions.getDocumentsFailed, (state: MedicalStaffState, {error}) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        loading: false,
        error
      }
    };
  }),

  on(fromMedicalStaffActions.getDocument, (state: MedicalStaffState, payload) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        data: [...state.documents.data.map(doc => ({...doc, loading: doc.uuid === payload.uuid}))]
      }
    };
  }),
  on(fromMedicalStaffActions.getDocumentSuccessful, (state: MedicalStaffState, {document}) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        data: [...state.documents.data.map(doc => ({...doc, loading: false}))],
        downloadedDocument: {
          ...state.documents.downloadedDocument,
          data: document
        }
      }
    };
  }),
  on(fromMedicalStaffActions.getDocumentFailed, (state: MedicalStaffState, {error}) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        data: [...state.documents.data.map(doc => ({...doc, loading: false}))],
        error
      }
    };
  }),

  on(fromMedicalStaffActions.resetDownloadedDocument, (state: MedicalStaffState) => {
    return {
      ...state,
      downloadedDocument: null
    };
  }),

  on(fromMedicalStaffActions.getProfileSuccessful, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffProfile: payload.medicalStaffProfile,
  })),
  on(fromMedicalStaffActions.getProfileFailed, (state: MedicalStaffState, error) => {
    return {
      ...state,
      medicalStaffProfileError: error
    };
  }),
  on(fromMedicalStaffActions.resetGetProfile, (state: MedicalStaffState) => {
    return {
      ...state,
      medicalStaffProfile: null,
      medicalStaffProfileError: null
    };
  }),
  on(fromMedicalStaffActions.addPersonalDetailsSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    personalDetails: payload.medicalStaff,
  })),

  // medical staff available services
  on(fromMedicalStaffActions.getAvailableServices, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffAvailableServicesFetching: true
  })),
  on(fromMedicalStaffActions.getAvailableServicesSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffAvailableServicesFetching: false,
    medicalStaffAvailableServices: payload.medicalServices,
  })),
  on(fromMedicalStaffActions.getAvailableServicesFailed, (state: MedicalStaffState, {error}) => ({
    ...state,
    medicalStaffAvailableServicesFetching: false,
    medicalStaffAvailableServices: error
  })),

  // medical staff services
  on(fromMedicalStaffActions.getServices, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffServices: null,
    medicalStaffServicesFetching: true
  })),
  on(fromMedicalStaffActions.getServicesSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffServices: payload.medicalServices,
    medicalStaffServicesFetching: false
  })),
  on(fromMedicalStaffActions.getServicesFailed, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffServicesError: payload.error,
    medicalStaffServicesFetching: false
  })),

  // add service
  on(fromMedicalStaffActions.addService, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffServicesUpdating: true,
    medicalStaffServicesError: null,
    medicalStaffServicesUpdated: false
  })),
  on(fromMedicalStaffActions.addServiceSuccess, (state: any, {medicalServices}) => {
    // TODO - TBD - HACK
    const deletedService = medicalServices.medicalServices[0];

    return {
      ...state,
      medicalStaffServicesUpdating: false,
      medicalStaffServicesUpdated: true,
      medicalStaffServices: {
        medicalStaff: state.medicalStaffServices.medicalStaff,
        medicalServices: [...state.medicalStaffServices.medicalServices, {services: [deletedService]}],
      }
    };
  }),
  on(fromMedicalStaffActions.addServiceFailed, (state: MedicalStaffState, {error}) => ({
    ...state,
    medicalStaffServicesUpdating: false,
    medicalStaffServicesUpdated: false,
    medicalStaffServicesError: error
  })),

  on(fromMedicalStaffActions.resetServicesState, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffServicesError: null,
    medicalStaffServicesUpdated: false
  })),

  // delete service
  on(fromMedicalStaffActions.deleteService, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffServicesUpdating: true
  })),
  on(fromMedicalStaffActions.deleteServiceSuccess, (state: MedicalStaffState, {medicalServices}) => {
    const deletedService = medicalServices.medicalServices[0];

    const filtered = state.medicalStaffServices.medicalServices.map(service => ({
      ...service,
      services: service.services.filter(({identifier}) => identifier !== deletedService.identifier)
    }));

    return {
      ...state,
      medicalStaffServicesUpdating: false,
      medicalStaffServices: {
        medicalStaff: state.medicalStaffServices.medicalStaff,
        medicalServices: filtered
      },
    };
  }),
  on(fromMedicalStaffActions.deleteServiceFailed, (state: MedicalStaffState, {error}) => {
    return {
      ...state,
      medicalStaffServicesUpdating: false,
      medicalStaffServicesError: error
    };
  }),

  // financial details
  on(fromMedicalStaffActions.getFinancialDetailsSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    financialDetails: payload.financialDetails,
  })),
  on(fromMedicalStaffActions.addFinancialDetails, (state: MedicalStaffState) => ({
    ...state,
    financialDetailsError: null,
    financialDetailsUpdating: true
  })),
  on(fromMedicalStaffActions.addFinancialDetailsSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    financialDetailsUpdating: false
  })),
  on(fromMedicalStaffActions.addFinancialDetailsFailed, (state: MedicalStaffState, {error}) => ({
    ...state,
    financialDetailsError: error,
    financialDetailsUpdating: false
  })),

  // patients
  on(fromMedicalStaffActions.loadMedicalStaffPatients, (state: MedicalStaffState) => ({
    ...state,
    medicalStaffPatientsLoading: true,
    medicalStaffPatients: null
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientsSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffPatients: payload.medicalStaffPatients,
    medicalStaffPatientsLoading: false
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientsFailed, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffPatientsError: payload.error,
    medicalStaffPatientsLoading: false
  })),

  // selected patient
  on(fromMedicalStaffActions.loadMedicalStaffPatient, (state: MedicalStaffState) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      patient: {
        ...state.selectedPatient.patient,
        data: null,
        loading: true
      }
    },
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      patient: {
        ...state.selectedPatient.patient,
        loading: false,
        data: payload.patient
      }
    },
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientFailed, (state: MedicalStaffState, payload) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      patient: {
        ...state.selectedPatient.patient,
        loading: false,
        error: payload.error
      }
    },
  })),

  // selected patient address
  on(fromMedicalStaffActions.loadMedicalStaffPatientAddresses, (state: MedicalStaffState) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      addresses: {
        ...state.selectedPatient.addresses,
        data: null,
        loading: true,
      }
    },
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientAddressesSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      addresses: {
        ...state.selectedPatient.addresses,
        data: payload.addresses,
        loading: false,
      }
    },
  })),
  on(fromMedicalStaffActions.loadMedicalStaffPatientAddressesFailed, (state: MedicalStaffState, payload) => ({
    ...state,
    selectedPatient: {
      ...state.selectedPatient,
      addresses: {
        ...state.selectedPatient.addresses,
        error: payload.error,
        loading: false
      }
    },
  })),

  // appointments
  on(fromMedicalStaffActions.loadMedicalStaffAppointments, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffAppointments: null,
    medicalStaffAppointmentsLoading: true
  })),
  on(fromMedicalStaffActions.loadMedicalStaffAppointmentsSuccess, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffAppointments: payload.medicalStaffAppointments,
    medicalStaffAppointmentsLoading: false
  })),
  on(fromMedicalStaffActions.loadMedicalStaffAppointmentsFailed, (state: MedicalStaffState, payload) => ({
    ...state,
    medicalStaffAppointmentsError: payload.error,
    medicalStaffAppointmentsLoading: false
  })),

  // address
  on(fromMedicalStaffActions.addAddress, (state: MedicalStaffState) => ({
    ...state,
    servicesAreaUpdating: true,
    servicesAreaError: null
  })),
  on(fromMedicalStaffActions.addAddressSuccess, (state: MedicalStaffState, {servicesArea}) => ({
    ...state,
    servicesArea,
    servicesAreaUpdating: false
  })),
  on(fromMedicalStaffActions.addAddressFailed, (state: MedicalStaffState, {error}) => ({
    ...state,
    servicesAreaUpdating: false,
    servicesAreaError: error
  })),
);

export function reducer(state: MedicalStaffState | undefined, action: Action): MedicalStaffState {
  return medicalStaffReducer(state, action);
}

