import { Component, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';

import { Observable } from 'rxjs';
import { User } from 'src/app/core/models';
import { BaseComponentDirective } from 'src/app/components/base.components';
import { tap } from 'rxjs/operators';
import { PopupRateUserService } from 'src/app/_modal/popup-rate-user/popup-rate-user.service';
import { IonRouterOutlet } from '@ionic/angular';
import { PopupMessageService } from 'src/app/_modal/popup-message/popup-message.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponentDirective implements OnInit {
  public user$: Observable<User>;

  public displayNotification = false;

  private profileLinkBase = '/profile/';

  public profileLink: string;

  public userType: string;

  constructor(
    private store: Store<fromStore.State>,
    private popupRateUserService: PopupRateUserService
  ) {
    super();
  }

  ngOnInit() {
    this.user$ = this.store.pipe(
      select(fromStore.selectCurrentUser),
      tap((currentUser) => {
        const isMedicalStaff =
          parseInt(String(currentUser.accountType), 10) === 2;
        this.profileLink = isMedicalStaff
          ? `/${this.profileLinkBase}/medical-staff`
          : `/${this.profileLinkBase}/beneficiary`;
        this.userType = isMedicalStaff ? 'medical-staff' : 'beneficiary';
      })
    );
  }

  async rateUser(userId) {
    await this.popupRateUserService.presentModal(userId);
  }
}
