import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import * as fromStore from '../../../../store';
import {selectMedicalStaffPacientsLoading} from '../../../../store';
import {Patient} from '../../../../core/models/patient';

@Component({
  selector: 'app-list-patients',
  templateUrl: './list-patients.component.html',
  styleUrls: ['./list-patients.component.css']
})
export class ListPatientsComponent implements OnInit, OnDestroy {

  public patients$: Observable<Patient[]> = this.store.pipe(
    select(fromStore.selectMedicalStaffPacients),
  );

  public loading$: Observable<boolean> = this.store.select(selectMedicalStaffPacientsLoading);

  constructor(
    private store: Store<fromStore.State>,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(fromStore.loadMedicalStaffPatients());
  }

  ngOnDestroy(): void {
  }

}
