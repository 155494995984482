import { Injectable } from '@angular/core';
import { AbstractApiService } from '../../core/http/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type MedicalStaffStatus = 'approved' | 'pending';

export interface MedicalStaffStatusDetails {
  identifier: string;
  firstName: string;
  lastName: string;
  email: string;
  accountType: number;
  phoneNumber: string;
  phonePrefix: string;
  status: {
    status: MedicalStaffStatus;
    comment: string;
  };
}

export interface MedicalStaffPersonalDetails {
  dateOfBirth: string;
  sex: string;
  jobTitle: string;
}

export interface MedicalStaffDetails {
  medicalStaff: {
    firstName: string;
    lastName: string;
    email: string;
    accountType: number;
    phoneNumber: string;
    phonePrefix: string;
  };
  personalDetails: MedicalStaffPersonalDetails;
}

@Injectable({
  providedIn: 'root',
})
export class MedicalStaffApiService extends AbstractApiService {
  constructor(private http: HttpClient) {
    super();
  }

  findStatus(): Observable<MedicalStaffStatusDetails> {
    return this.http.get<MedicalStaffStatusDetails>(
      `${this.getBaseUrl()}/medical-staff/status`
    );
  }

  findDetails(): Observable<MedicalStaffDetails> {
    return this.http.get<MedicalStaffDetails>(
      `${this.getBaseUrl()}/medical-staff/personal-details`
    );
  }

  updateDetails(
    payload: MedicalStaffPersonalDetails
  ): Observable<MedicalStaffPersonalDetails> {
    return this.http.post<MedicalStaffPersonalDetails>(
      `${this.getBaseUrl()}/medical-staff/personal-details`,
      payload
    );
  }

  getPacientMedicalRecord(patientId): Observable<any> {
    return this.http.get(
      `${this.getBaseUrl()}/patients/${patientId}/medical-record`
    );
  }

  public getPacientMedicalDocuments(patientId): Observable<any> {
    return this.http.get(
      `${this.getBaseUrl()}/patients/${patientId}/documents`
    );
  }

  public getPacientMedicalDocument(
    patientId: string,
    documentId: string
  ): Observable<any> {
    const options = { responseType: 'blob' as const };
    // @ts-ignore
    return this.http.get(
      `${this.getBaseUrl()}/patients/${patientId}/documents/${documentId}`,
      options
    );
  }

  public getPacientMedicalHistory(patientId): Observable<any> {
    return this.http.get(
      `${this.getBaseUrl()}/patients/${patientId}/medical-history`
    );
  }

  public sendCheckin(data, appointmentId) {
    return this.http.post(
      `${this.getBaseUrl()}/medical-staff/appointments/${appointmentId}/checkin`,
      data
    );
  }

  public sendCheckout(data, appointmentId) {
    return this.http.post(
      `${this.getBaseUrl()}/medical-staff/appointments/${appointmentId}/checkout`,
      data
    );
  }

  public addMedicalReport(data, appointmentId) {
    return this.http.post(
      `${this.getBaseUrl()}/medical-staff/appointments/${appointmentId}/report`,
      data
    );
  }
}
