import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

const getPatientFeatureState = createFeatureSelector<fromReducers.PatientsState>('patient');

export const selectAllPatients = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.patient,
);

export const selectWidgetPatients = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.patient,
);

export const selectWidgetPatientsLoading = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.patientsLoading,
);

export const selectWidgetPatientsError = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.patientsError,
);

export const selectOnePatient = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState, patientId) => state.patient.find(patient => patient.id === patientId),
);
export const selectSinglePatient = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.singlePatient,
);

export const selectBeneficiaryAppointments = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.beneficiaryAppointments,
);

export const selectBeneficiaryAppointmentsError = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.beneficiaryAppointmentsError,
);

export const selectBeneficiaryAppointmentsLoading = createSelector(
  getPatientFeatureState,
  (state: fromReducers.PatientsState) => state.loading,
);
