import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddAppointmentComponent } from './add-appointment.component';

@Injectable({
  providedIn: 'root',
})
export class AddAppointmentService {

  private modal: HTMLIonModalElement;

  constructor(public modalController: ModalController) {}

  async presentModal() {
    this.modal = await this.modalController.create({
      component: AddAppointmentComponent,
      cssClass: '',
      componentProps: {
      },
    });

    return await this.modal.present();
  }

  async closeModal() {
    if (this.modal) {
      this.modal.dismiss().then(() => { this.modal = null; });
    }
  }
}
