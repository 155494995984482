import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, timeout } from 'rxjs/operators';
import { InvoiceApiService } from 'src/app/core/http/invoice-api.service';
import * as beneficiaryInvoiceActions from '../actions/beneficiary-invoice.actions';

@Injectable()
export class BeneficiaryInvoiceEffects {
  loadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(beneficiaryInvoiceActions.loadBeneficiaryInvoices),
      exhaustMap(() =>
        this.invoiceApiService.findAll().pipe(
          map((invoices) =>
            beneficiaryInvoiceActions.loadBeneficiaryInvoicesSuccess({
              invoices: invoices.map((invoice) => ({
                ...invoice,
                ui: {
                  serial: `${invoice.serie}${invoice.numar}`,
                  canBePaid:
                    invoice.status === 0 && invoice?.paymentUrl.length > 0,
                  hasPayError:
                    invoice.status === 0 && invoice?.paymentUrl.length === 0,
                  isUpdating: false,
                  statusText: invoice.status === 0 ? 'Neplătit' : 'Plătită',
                  statusVariant: invoice.status === 0 ? 'warning' : 'success',
                },
              })),
            })
          ),
          catchError((error) =>
            of(
              beneficiaryInvoiceActions.loadBeneficiaryInvoicesFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private invoiceApiService: InvoiceApiService
  ) {}
}
