import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, throttleTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import * as fromStore from '../../store';
import {Store} from '@ngrx/store';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {

  private throttleLogout = new Subject();

  constructor(
    private router: Router,
    private store: Store<fromStore.State>
  ) {
    this.throttleLogout.pipe(throttleTime(3000)).subscribe(url => {
      this.router.navigate(['/auth/logout']);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            this.throttleLogout.next();
          }
          return throwError(response);
        }
      )
    );
  }
}
