import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MedicalService } from 'src/app/core/models/medical-staff-service';
import * as fromStore from '../../../../store';
import { selectAvailableMedicalServicesFetching } from '../../../../store';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.css'],
})
export class AddListingComponent implements OnInit {
  public addMedicalServiceForm = new FormGroup({
    medicalService: new FormControl(null, [Validators.required]),
    rate: new FormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
  });

  public availableMedicalServices$ = this.store.pipe(
    select(fromStore.selectAvailableMedicalServices),
    filter((services) => !!services),
    map((medicalServicesGroups) =>
      medicalServicesGroups
        .map(({ services }) => services)
        .reduce((acc, curr) => {
          curr.forEach((c) => acc.push(c));
          return acc;
        }, [])
    )
  );

  public isFetching$: Observable<boolean> = this.store.pipe(
    select(selectAvailableMedicalServicesFetching)
  );

  public isUpdating$: Observable<boolean> = this.store.pipe(
    select(fromStore.selectMedicalServicesUpdating),
    tap((isUpdating) => {
      isUpdating
        ? this.addMedicalServiceForm.disable({ emitEvent: false })
        : this.addMedicalServiceForm.enable({ emitEvent: false });
    })
  );

  public errors$: Observable<boolean> = this.store.pipe(
    select(fromStore.selectMedicalServicesError)
  );

  public selectedServiceMinRate: string;

  constructor(
    private store: Store<fromStore.State>,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.store.dispatch(fromStore.getAvailableServices());

    this.store
      .pipe(select(fromStore.selectMedicalServicesUpdated))
      .subscribe((wasUpdated) => {
        if (wasUpdated) {
          this.dismiss();
        }
      });
  }

  public onMedicalServiceChange(ev: CustomEvent) {
    const medicalService = ev.detail.value as MedicalService;
    const minRate = parseInt(medicalService.minRate.split(' ')[0], 10);

    this.selectedServiceMinRate = medicalService.minRate;
    this.addMedicalServiceForm.controls.rate.clearValidators();
    this.addMedicalServiceForm.controls.rate.setValidators(
      Validators.min(minRate)
    );
    this.addMedicalServiceForm.controls.rate.updateValueAndValidity();
  }

  public dismiss() {
    this.modalCtrl.dismiss();
    this.store.dispatch(fromStore.resetServicesState());
  }

  public submit() {
    if (this.addMedicalServiceForm.valid) {
      this.store.dispatch(
        fromStore.addService({
          service: {
            medicalServiceId:
              this.addMedicalServiceForm.value.medicalService.identifier,
            rate: `${this.addMedicalServiceForm.value.rate} RON`,
          },
        })
      );
    }
  }
}
