<div class="widget-holder">
  <ion-item lines="none">
    <ion-label class="widget-title">CONTRACTE</ion-label>
    <ion-label slot="end" class="widget-link">VEZI TOATE</ion-label>
  </ion-item>

  <ion-item lines="none" class="record-holder" detail="true">
      <ion-label class="widget-record-text-left">Contract Grigore Adrian</ion-label>
  </ion-item>
  <ion-item lines="none" class="record-holder" detail="true">
      <ion-label class="widget-record-text-left">Contract Popescu Adrian</ion-label>
  </ion-item>
</div>
