import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PickerController} from '@ionic/angular';
import {FormControl, FormGroup} from '@angular/forms';
import {MedicalStaffScheduleEditFacadeService} from '../../../services/facade/medical-staff-schedule-edit-facade/medical-staff-schedule-edit-facade.service';
import {ModalActionType, ScheduleDayCreateReqBody, ScheduleFormValue, WeekDaysMapping} from '../../../core/models/medical-staff-schedule';
import {ToastService} from '../../../core/services/toast.service';


@Component({
  selector: 'app-modal-schedule-edit',
  templateUrl: './modal-schedule-edit.component.html',
  styleUrls: ['./modal-schedule-edit.component.css']
})
export class ModalScheduleEditComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public buttonLabel: string;

  @Input()
  public initialFormValue: ScheduleFormValue;

  @Input()
  public timeFrameId: number;

  @Input()
  public actionType: ModalActionType;

  public scheduleForm = new FormGroup({
    day: new FormControl('', []),
    availableTimeFrame: new FormGroup({
      start: new FormControl('', []),
      end: new FormControl('', [])
    })
  });

  private weekDays = [
    {text: 'Luni', value: 'Luni'},
    {text: 'Marti', value: 'Marti'},
    {text: 'Miercuri', value: 'Miercuri'},
    {text: 'Joi', value: 'Joi'},
    {text: 'Vineri', value: 'Vineri'},
    {text: 'Sambata', value: 'Sambata'},
    {text: 'Duminica', value: 'Duminica'}
  ];

  constructor(
    private scheduleFacade: MedicalStaffScheduleEditFacadeService,
    private pickerController: PickerController,
    private modalController: ModalController,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    if (this.initialFormValue && this.timeFrameId) {
      this.scheduleForm.patchValue(this.initialFormValue);
      this.scheduleForm.markAsPristine();
    }
  }

  public async openPicker() {
    const picker = await this.pickerController.create({
      columns: [{name: 'day', options: this.weekDays}],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (value) => {
            this.scheduleForm.controls.day.patchValue(value.day.value);
          }
        }
      ]
    });

    await picker.present();
  }

  public onSave() {
    const reqBody: ScheduleDayCreateReqBody = this.buildReqBody();

    this.scheduleForm.disable();

    if (this.actionType === ModalActionType.CREATE) {
      this.scheduleFacade.createSchedule(reqBody).subscribe(
        () => {
          this.modalController.dismiss(null, 'create');
          this.toastService.presentToast({message: 'Intervalul a fost adaugat.', color: 'success'});
        },
        err => this.handleFormError(err.error)
      );
    } else {
      this.scheduleFacade.updateScheduleById(this.timeFrameId, reqBody).subscribe(
        () => {
          this.modalController.dismiss(null, 'update');
          this.toastService.presentToast({message: 'Intervalul a fost actualizat.', color: 'success'});
        },
        err => this.handleFormError(err.error)
      );
    }
  }

  public async onClose() {
    await this.modalController.dismiss(null, 'cancel');
  }

  private handleFormError(errors) {
    const bkErrors = Object.values(errors);
    this.scheduleForm.enable();
    this.scheduleForm.setErrors({bkError: bkErrors});
  }

  private buildReqBody(): ScheduleDayCreateReqBody {
    const formValue: ScheduleFormValue = this.scheduleForm.getRawValue();

    const timeFrameStart = new Date(formValue.availableTimeFrame.start)
      .toTimeString().split(' ')[0].replace(/..$/, '00');

    const timeFrameEnd = new Date(formValue.availableTimeFrame.end)
      .toTimeString().split(' ')[0].replace(/..$/, '00');

    return {
      days: [{
        day: WeekDaysMapping[formValue.day],
        availableTimeFrame: [{
          start: timeFrameStart,
          end: timeFrameEnd
        }]
      }]
    };
  }
}
