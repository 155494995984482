<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text="" defaultHref="profile/medical-staff"></ion-back-button>
    </ion-buttons>
    <ion-title>Zona Prestare Servicii</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-map [address]="serviceSupplyAreaFormValues$ | async" [radius]="radius$ | async" [zoom]="13">
  </app-map>

  <form [formGroup]="serviceSupplyAreaForm" class="ion-padding">
    <ion-list>
      <ion-item>
        <ion-label position="stacked">Țara</ion-label>
        <ion-input formControlName="country"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Județ</ion-label>
        <ion-input formControlName="district"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Oraș</ion-label>
        <ion-input formControlName="city"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Strada</ion-label>
        <ion-input formControlName="street"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Număr</ion-label>
        <ion-input formControlName="streetNumber"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Raza (km)</ion-label>
        <ion-input formControlName="radius"></ion-input>
      </ion-item>
    </ion-list>

    <div *ngIf="(errors$ | async) as errors" class="errors mb-2">
      <p *ngFor="let error of errors">
        {{error}}
      </p>
    </div>

    <ion-button [disabled]="serviceSupplyAreaForm.invalid || (updating$ | async)" (click)="submit()" color="secondary"
                expand="block" class="mt-2">
      <ng-container *ngIf="!(updating$ | async)">
        Salvează
      </ng-container>
      <ng-container *ngIf="updating$ | async">
        <ion-spinner></ion-spinner>
      </ng-container>
    </ion-button>
  </form>
</ion-content>
