import {createAction, props} from '@ngrx/store';
import {MedicalStaffDetails, MedicalStaffPersonalDetails} from '../../services/medical-staff-api/medical-staff-api.service';

const MEDICAL_STAFF_PROFILE = '[Medical staff profile]';

export const loadMedicalStaffProfile = createAction(
  `${MEDICAL_STAFF_PROFILE} Fetch profile data`
);

export const loadMedicalStaffProfileSuccess = createAction(
  `${MEDICAL_STAFF_PROFILE} Fetch profile data success`,
  props<{ profile: MedicalStaffDetails }>()
);

export const loadMedicalStaffProfileFailed = createAction(
  `${MEDICAL_STAFF_PROFILE} Fetch profile data failed`,
  props<{ error: any }>()
);

export const updateMedicalStaffProfile = createAction(
  `${MEDICAL_STAFF_PROFILE} Update profile data`,
  props<{ payload: MedicalStaffPersonalDetails }>()
);

export const updateMedicalStaffProfileSuccess = createAction(
  `${MEDICAL_STAFF_PROFILE} Update profile data success`
);

export const updateMedicalStaffProfileFailed = createAction(
  `${MEDICAL_STAFF_PROFILE} Update profile data failed`,
  props<{ error: any }>()
);
