import {Component, Input, OnInit} from '@angular/core';

export interface WidgetConfig {
  title: string;
  viewAllLink: string;
  viewAllLinkText: string;
  contentHasPadding?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger';
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  @Input() widgetConfig: WidgetConfig;

  @Input() loading: boolean;

  @Input() error: any;

  @Input() hasViewAllLink: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
