import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as medicalReportActions from '../actions/medical-report.actions';
import { MedicalReportApiService } from '../../core/http/medical-report-api.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Injectable()
export class MedicalReportEffects {
  loadMedicalReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalReportActions.loadMedicalReports),
      exhaustMap(({ patientId }) =>
        this.medicalReportService.findAll(patientId).pipe(
          map((medicalReports) =>
            medicalReportActions.loadMedicalReportsSuccess({ medicalReports })
          ),
          catchError((error) =>
            of(medicalReportActions.loadMedicalReportsFailed(error))
          )
        )
      )
    )
  );

  // at this moment, loadMedicalReports$ has all necessary data, this effect can be used
  // in the future when this call will provide more data

  // loadMedicalReport$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(medicalReportActions.loadMedicalReport),
  //     exhaustMap(({ patientId, reportId }) =>
  //       this.medicalReportService.findById(patientId, reportId).pipe(
  //         map((medicalReport) =>
  //           medicalReportActions.loadMedicalReportSuccess({ medicalReport })
  //         ),
  //         catchError((error) =>
  //           of(medicalReportActions.loadMedicalReportFailed(error))
  //         )
  //       )
  //     )
  //   )
  // );

  approveMedicalReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(medicalReportActions.approveMedicalReport),
      exhaustMap(({ patientId, reportId }) =>
        this.medicalReportService
          .approveMedicalReport(patientId, reportId)
          .pipe(
            map(() => {
              this.toastService.presentToast({
                message: 'Raportul a fost aprobat.',
                color: 'success',
              });

              return medicalReportActions.approveMedicalReportSuccess({
                patientId,
                reportId,
              });
            }),
            catchError((error) => {
              this.toastService.presentToast({
                message:
                  'Am întâmpinat o problemă, vă rugțăm să încercați mai târziu.',
                color: 'warning',
              });
              return of(medicalReportActions.approveMedicalReportFailed(error));
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private medicalReportService: MedicalReportApiService,
    private toastService: ToastService
  ) {}
}
