import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {select, Store} from '@ngrx/store';
import {BaseComponentDirective} from 'src/app/components/base.components';

import * as fromStore from '../../../store';
import {loadMedicalStaffProfile, updateMedicalStaffProfile} from '../../../store';
import {Observable} from 'rxjs';
import {filter, map, pluck, tap} from 'rxjs/operators';
import {MedicalStaffPersonalDetails} from '../../../services/medical-staff-api/medical-staff-api.service';
import {
  selectMedicalStaffProfile,
  selectMedicalStaffProfileError,
  selectMedicalStaffProfileFetching,
  selectMedicalStaffProfileUpdating
} from '../../../store/selectors/medical-staff-profile.selectors';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent extends BaseComponentDirective implements OnInit {

  public personalDetails$: Observable<MedicalStaffPersonalDetails>;

  public updating$: Observable<boolean> = this.store.pipe(
    select(selectMedicalStaffProfileUpdating),
    tap(isUpdating => {
      isUpdating ? this.personalDetailsForm.disable({emitEvent: false}) : this.personalDetailsForm.enable({emitEvent: false});
    })
  );

  public fetching$: Observable<boolean> = this.store.pipe(
    select(selectMedicalStaffProfileFetching)
  );

  public errors$: Observable<any>;

  public personalDetailsForm: FormGroup = new FormGroup({
    dateOfBirth: new FormControl('', []),
    sex: new FormControl('', []),
    jobTitle: new FormControl('', [])
  });

  constructor(
    private store: Store<fromStore.State>
  ) {
    super();
  }

  ngOnInit() {
    this.loadPersonalDetails();
  }

  private loadPersonalDetails() {
    this.store.dispatch(loadMedicalStaffProfile());

    this.personalDetails$ = this.store.pipe(
      select(selectMedicalStaffProfile),
      filter(medicalStaffDetails => !!medicalStaffDetails),
      pluck('personalDetails'),
      tap((personalDetails) => {
        this.personalDetailsForm.patchValue(personalDetails, {emitEvent: false});
        this.personalDetailsForm.markAsPristine();
      })
    );

    this.errors$ = this.store.pipe(
      select(selectMedicalStaffProfileError),
      filter(errors => !!errors),
      map(errors => {
        return this.parseErrors(errors);
      })
    );
  }

  private parseErrors(errors) {
    const matchedErrors = [];

    Object.keys(errors).forEach((error) => {
      if (this.personalDetailsForm.controls[error]) {
        errors[error].forEach(e => matchedErrors.push(e));
      }
    });

    return matchedErrors;
  }

  public submit() {
    this.store.dispatch(updateMedicalStaffProfile(
      {
        payload: {
          dateOfBirth: formatDate(this.personalDetailsForm.value.dateOfBirth, 'yyyy-MM-dd', 'en-US'),
          sex: this.personalDetailsForm.value.sex,
          jobTitle: this.personalDetailsForm.value.jobTitle
        },
      })
    );
  }
}
