import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import {
  MedicalStaffContractService,
  Patient, PatientAddress,
} from '../../../patient/model/patient.model';
import { PatientFacadeService } from '../../../patient/facade/patient-facade/patient-facade.service';
import {filter, map, tap} from 'rxjs/operators';
import { BeneficiaryContractsFacadeService } from '../../facade/contracts/beneficiary-contracts-facade.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientApiService } from '../../../patient/service/patient-api/patient-api.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-beneficiary-establish-contract',
  templateUrl: './beneficiary-establish-contract.component.html',
  styleUrls: ['./beneficiary-establish-contract.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: 0,
        }),
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(0px)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '600ms cubic-bezier(.75,0,.25,.99)',
          style({ transform: 'translateY(30px)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class BeneficiaryEstablishContractComponent implements OnInit {
  public form = new FormGroup({
    patientId: new FormControl(null, Validators.required),
    addressId: new FormControl({ value: null, disabled: true }, Validators.required),
    medicalStaffServices: new FormArray(
      [this.createItem()],
      Validators.required
    ),
    terms: new FormControl(false, Validators.requiredTrue),
    invoiceTypeOfPerson: new FormControl(false)
  });

  public medicalServicesFormArray: FormArray;

  public patients$: Observable<Patient[]>;

  public selectPatientAddresses$: Observable<PatientAddress[]>;

  public medicalServices$: Observable<MedicalStaffContractService[]>;

  public formContentReady$: Observable<{
    patients: Patient[];
    medicalServices: MedicalStaffContractService[];
  }>;

  private beneficiaryId: string;

  public generalError: string;

  constructor(
    private route: ActivatedRoute,
    private patientsFacade: PatientFacadeService,
    private beneficiaryContractsFacadeService: BeneficiaryContractsFacadeService,
    private patientApiService: PatientApiService,
    private router: Router
  ) {}

  public onChangePatient(ev: CustomEvent) {
    this.form.controls.addressId.disable();
    this.form.controls.addressId.patchValue(null);
    this.patientsFacade.dispatchLoadPatientAddresses(ev.detail.value);
  }

  ngOnInit(): void {
    const medicalStaffId =
      this.route.snapshot.queryParamMap.get('medicalStaffId');

    this.patientsFacade.dispatchLoadPatients();

    this.patients$ = this.patientsFacade.patients$.pipe(
      tap((patients) => {
        if (patients.length) {
          this.beneficiaryId = patients[0].beneficiaryId.toString();
        }
      })
    );

    this.medicalServices$ =
      this.patientApiService.findMedicalServicesByMedicalStaffId(
        medicalStaffId
      );

    this.selectPatientAddresses$ = this.patientsFacade.patientAddresses$.pipe(
      filter(() => !!this.form),
      tap(() => {
        this.form.controls.addressId.enable();
      })
    );

    this.formContentReady$ = combineLatest([
      this.patients$,
      this.medicalServices$,
    ]).pipe(
      map((result) => {
        return { patients: result[0], medicalServices: result[1] };
      })
    );
  }

  public createItem() {
    return new FormGroup({
      medicalStaffServiceId: new FormControl(null, Validators.required),
      noOfRecurrences: new FormControl(null, Validators.required),
    });
  }

  public addItem() {
    this.medicalServicesFormArray = this.form.get(
      'medicalStaffServices'
    ) as FormArray;
    this.medicalServicesFormArray.push(this.createItem());
  }

  public handleSubmit() {
    this.form.disable();
    this.generalError = null;

    const formValue = this.form.getRawValue();
    const interviewId = this.route.snapshot.paramMap.get('interviewId');

    formValue.invoiceTypeOfPerson = formValue.invoiceTypeOfPerson ? 2 : 1;

    this.beneficiaryContractsFacadeService
      .dispatchCreateContract(this.beneficiaryId, interviewId, formValue)
      .subscribe(
        async (response) => {
          await this.router.navigateByUrl('/contracts/beneficiary');
        },
        (error) => {
          this.form.enable();

          if (typeof error.error === 'string') {
            this.generalError = error.error;
          } else {
            const errorKeys = Object.keys(error.error);
            const err = errorKeys.map((key) => error.error[key]);
            this.form.setErrors({ bkError: err });
          }
        }
      );
  }
}
