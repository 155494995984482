import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromReducers from '../reducers';

const getContractsFeatureState = createFeatureSelector<fromReducers.ContractsState>('contracts');

export const selectAllContracts = createSelector(
  getContractsFeatureState,
  (state: fromReducers.ContractsState) => state.contracts,
);

export const selectAllContractsLoading = createSelector(
  getContractsFeatureState,
  (state: fromReducers.ContractsState) => state.loading,
);

export const selectAllContractsError = createSelector(
  getContractsFeatureState,
  (state: fromReducers.ContractsState) => state.error,
);
