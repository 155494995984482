<div class="d-flex ion-justify-content-between">
  <div *ngFor="let item of data" class="w-25" [routerLink]="item.link">
    <div>
      <img
        class="round w-50 m-auto d-block"
        style="max-width: 60px"
        [src]="item.avatarUrl"
        [alt]="item.avatarName"
      />
    </div>
    <div class="ion-text-center mt-05">
      {{ item.text }}
    </div>
  </div>
</div>
