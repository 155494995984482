import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

// Http Services
import { AuthApiService } from '../http/auth-api.service';

// Models
import { LoginCredentials, User, AccountType } from '../models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private authService: AuthApiService) {}

  /**
   * Login user using credentials
   *
   * @param credentials LoginCredentials
   */
  login(credentials: LoginCredentials): Observable<any> {
    return this.authService.login(credentials).pipe(
      map((response) => {
        return response;
      })
    );
  }

  /**
   * Register new user
   *
   * @param user User
   */
  register(user: User): Observable<any> {
    return this.authService.register(user);
  }

  /**
   * Validate a new signup
   *
   * @param phoneNumber string
   * @param phonePrefix string
   * @param accountType AccountType
   * @param code string
   */
  validateUser(
    phoneNumber: string,
    phonePrefix: string,
    accountType: AccountType,
    code: string
  ): Observable<any> {
    return this.authService.verifyAccount(
      phoneNumber,
      phonePrefix,
      accountType,
      code
    );
  }

  /**
   * Current User Information
   */
  userInfo(): Observable<any> {
    return this.authService.userInfo();
  }

  /**
   * Logout current user
   */
  logout(): Observable<boolean> {
    return this.authService.logout();
  }
}
