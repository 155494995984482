import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PopupMessageComponent } from './popup-message.component';

@Injectable({
  providedIn: 'root',
})
export class PopupMessageService {

  private modal: HTMLIonModalElement;

  constructor(public modalController: ModalController) {}

  async presentModal(message) {
    this.modal = await this.modalController.create({
      component: PopupMessageComponent,
      cssClass: 'popupModal',
      componentProps: {
        message,
      },
    });

    return await this.modal.present();
  }

  async closeModal() {
    if (this.modal) {
      this.modal.dismiss().then(() => { this.modal = null; });
    }
  }
}
