<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Contract</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="full-form">
    <div class="full-form-data">
      <ion-list class="list-menu" lines="full">
        <ion-item>
          <ion-label>
            <div class="font-bold">
              Pacient
            </div>
            <div class="font-18 ">
              {{contract.patient.firstName}}
              {{contract.patient.lastName}}
            </div>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-label>
            <div class="font-bold">
              Beneficiar
            </div>
            <div class="font-18 ">
              {{contract.medicalStaff.firstName}}
              {{contract.medicalStaff.lastName}}
            </div>
          </ion-label>
        </ion-item>

        <ion-item *ngFor="let service of contract.services">
          <ion-label>
            <div class="font-bold">
              Serviciu medical
            </div>
            <div class="font-18 ">
              <ion-text class="ion-font-size-14">
                {{service.medicalServiceName | titlecase}}
                -
                {{service.occurrences}} {{service.occurrences > 1 ? 'vizite' : 'vizita'}}
              </ion-text>
            </div>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <div class="d-flex d-flex-row ion-justify-content-between">
              <div>TOTAL</div>
              <div>STATUS</div>
            </div>

            <div class="d-flex d-flex-row ion-justify-content-between mt-1">
              <div class="font-20 font-bold">
                {{contract.ui.totalRate}} {{contract.ui.currency}}
              </div>

              <div class="w-50">
                <ion-badge [color]="contract.ui.variant" class="w-full">
                  {{contract.ui.statusTranslate}}
                </ion-badge>
              </div>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div class="submit-button">
      <ion-button *ngIf="type === 'medical-staff' && contract.status === 1" (click)="onSubmit()" expand="block"
                  color="primary">
        Semneaza
      </ion-button>

      <ion-button *ngIf="contract.status !== 1 || type === 'beneficiary'" (click)="onClose()" expand="block"
                  color="secondary">
        Inchide
      </ion-button>
    </div>
  </div>
</ion-content>

