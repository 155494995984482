<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Contract</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="full-form" *ngIf="medicalReport$ | async as medicalReport">
    <div class="full-form-data ion-padding">
      <ion-grid>
        <ion-row class="ion-margin-bottom">
          <ion-col>
            <p class="text-description">Data</p>
            <p class="text-description-value">
              {{ medicalReport.date.date | date }}
            </p>
          </ion-col>
          <ion-col>
            <p class="text-description">Status</p>
            <p class="text-description-value">
              <ion-badge [color]="medicalReport.ui.variant" class="w-full">
                {{ medicalReport.ui.message }}
              </ion-badge>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid>
        <ion-row>
          <ion-col>
            <p class="text-description">Descriere</p>
            <p class="text-description-value">
              {{ medicalReport.description }}
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div
      *ngIf="(isBeneficary$ | async) && medicalReport.statusId === 1"
      class="submit-button"
    >
      <ion-button
        (click)="approveMedicalReport(medicalReport.id)"
        expand="block"
        color="primary"
      >
        Aprobă
      </ion-button>
    </div>
  </div>
</ion-content>
