<ion-content>
  <ng-container *ngIf="(scheduleException$ | async) as scheduleExceptions else loading">
    <ng-container *ngIf="scheduleExceptions.length">
      <ng-container *ngFor="let scheduleException of scheduleExceptions">
        <ion-card color="tertiary">
          <ion-card-header>
            <ion-text color="primary" class="ion-color-primary font-18 font-bold ion-padding-start">
              {{scheduleException.description}}
            </ion-text>
          </ion-card-header>

          <ion-card-content>
            <ion-list class="list-menu" lines="full">
              <ion-item>
                <ion-text class="font-18">Durată început</ion-text>
                <ion-text class="font-18 font-bold" slot="end">
                  {{scheduleException.startDate | date : 'MMMM d, y'}}
                </ion-text>
              </ion-item>

              <ion-item lines="none">
                <ion-text class="font-18">Durată sfârșit</ion-text>
                <ion-text class="font-18 font-bold" slot="end">
                  {{scheduleException.endDate | date : 'MMMM d, y'}}
                </ion-text>
              </ion-item>
            </ion-list>

            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-button expand="block" color="primary" (click)="onEdit(scheduleException)">
                    Modifica
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button expand="block" color="danger" (click)="onDelete(scheduleException.id)">
                    Sterge
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ng-container>
    </ng-container>

    <div *ngIf="!scheduleExceptions.length" class="ion-padding center-float-message">
      Momentan nu ai niciun concediu setat.
    </div>

    <ion-fab [vertical]="scheduleExceptions.length ? 'bottom' : 'center'"
             [horizontal]="scheduleExceptions.length ? 'end' : 'center'" slot="fixed">
      <ion-fab-button (click)="onCreate()" class="btn-fab" color="secondary">
        <ion-icon name="add-outline" color="primary"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
</ion-content>

<ng-template #loading>
  <div class="ion-padding">
    <ion-spinner class="center-spinner"></ion-spinner>
  </div>
</ng-template>
